export function getArrayPropsAndNonArrayProps(obj: object) {
  const arrayProps: { [key: string]: object[] } = {};
  const nonArrayProps: { [key: string]: any } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      arrayProps[key] = value;
    } else {
      nonArrayProps[key] = value;
    }
  }

  return { arrayProps, nonArrayProps };
}
