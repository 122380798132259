import {
  BRAND_SHOP_THE_LOOK_CARD_CLICK,
  BRAND_SHOP_THE_LOOK_IMPRESSION,
  BRAND_SHOP_THE_LOOK_SEARCH_IMPRESSION,
  CREATE_SHOPPABLE_POST_CLICK,
  DELETE_SHOPPABLE_POST_CLICK,
  SHARE_SHOPPABLE_POST_CLICK,
  SHOPPABLE_POST_ADD_PRODUCT_TAG_CLICK,
  SHOPPABLE_POST_MY_CART_BUTTON_CLICK,
  SHOPPABLE_POST_PAGE_VIEW,
  SHOPPABLE_POST_PRODUCT_CLICK_THROUGH,
  SHOPPABLE_POST_PRODUCT_INTERACTION,
  SHOPPABLE_POST_QUICK_VIEW,
  SHOPPABLE_POST_REMOVE_PRODUCT_TAG_CLICK,
  SHOPPABLE_VIDEO_ACTION_EVENT,
  SHOPPABLE_VIDEO_UPLOAD
} from 'constants/amethyst';
import { INFLUENCER_STOREFRONT_PAGE } from 'constants/amethystPageTypes';
import { INFLUENCER_CONTENT_TYPE, InfluencerHubTab } from 'constants/amethystEnums';

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/CreateShoppablePostClick.proto
export const evCreateShoppablePostClick = ({ shoppablePostId, shoppablePostName, shoppableContentType, sourcePage, influencerHubTab }) => ({
  [CREATE_SHOPPABLE_POST_CLICK]: {
    shoppablePostId,
    shoppablePostName,
    shoppableContentType,
    sourcePage,
    influencerHubTab: InfluencerHubTab[influencerHubTab]
  }
});

// https:// code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/DeleteShoppablePostClick.proto
export const evDeleteShoppablePostClick = ({ shoppablePostId, shoppableContentType }) => ({
  [DELETE_SHOPPABLE_POST_CLICK]: {
    shoppablePostId,
    shoppableContentType: INFLUENCER_CONTENT_TYPE[shoppableContentType],
    sourcePage: INFLUENCER_STOREFRONT_PAGE
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShoppablePostPageView.proto
export const pvShoppablePostPageView = ({ shoppablePostId, arrivedFromShareLink, shareToken, shoppableContentType }) => ({
  [SHOPPABLE_POST_PAGE_VIEW]: {
    shoppablePostId: shoppablePostId,
    arrivedFromShareLink: arrivedFromShareLink,
    shareToken: shareToken,
    shoppableContentType: INFLUENCER_CONTENT_TYPE[shoppableContentType]
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShareShoppablePostClick.proto
export const evShareShoppablePostClick = ({ shoppablePostId, shareToken, shoppableContentType, sourcePage }) => ({
  [SHARE_SHOPPABLE_POST_CLICK]: {
    shoppablePostId,
    shareToken,
    shoppableContentType: INFLUENCER_CONTENT_TYPE[shoppableContentType],
    sourcePage
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShoppablePostAddProductTagClick.proto
export const evShoppablePostAddProductTagClick = ({ styleId, productId, shoppablePostId, shoppableContentType, websiteDomain }) => ({
  [SHOPPABLE_POST_ADD_PRODUCT_TAG_CLICK]: {
    addedProductIdentifier: {
      styleId,
      productId,
      supplementalData: {
        websiteDomain
      }
    },
    shoppablePostId: shoppablePostId,
    shoppableContentType: INFLUENCER_CONTENT_TYPE[shoppableContentType]
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShoppablePostRemoveProductTagClick.proto
export const evShoppablePostRemoveProductTagClick = ({ styleId, productId, shoppablePostId, shoppableContentType, websiteDomain }) => ({
  [SHOPPABLE_POST_REMOVE_PRODUCT_TAG_CLICK]: {
    removedProductIdentifier: {
      styleId,
      productId,
      supplementalData: {
        websiteDomain
      }
    },
    shoppablePostId: shoppablePostId,
    shoppableContentType: INFLUENCER_CONTENT_TYPE[shoppableContentType]
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShoppableVideoActionEvent.proto
export const evShoppableVideoActionEvent = ({ shoppablePostId, videoAction, videoActionTime }) => ({
  [SHOPPABLE_VIDEO_ACTION_EVENT]: {
    shoppablePostId,
    videoAction,
    videoActionTime
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShoppablePostProductClickthrough.proto
export const evShoppablePostProductClickthrough = ({ styleId, productId, shoppablePostId, shoppableContentType, websiteDomain, sourcePage }) => ({
  [SHOPPABLE_POST_PRODUCT_CLICK_THROUGH]: {
    clickedProduct: {
      styleId,
      productId,
      supplementalData: {
        websiteDomain
      }
    },
    shoppablePostId: shoppablePostId,
    shoppableContentType: INFLUENCER_CONTENT_TYPE[shoppableContentType],
    sourcePage
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShoppableVideoUpload.proto
export const evShoppableVideoUpload = ({ shoppablePostId, videoDuration }) => ({
  [SHOPPABLE_VIDEO_UPLOAD]: {
    shoppablePostId,
    videoDuration
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShoppablePostQuickView.proto
export const evShoppablePostQuickView = ({ shoppablePostId, shoppableContentType, taggedProducts, sourcePage }) => ({
  [SHOPPABLE_POST_QUICK_VIEW]: {
    shoppablePostId,
    shoppableContentType: INFLUENCER_CONTENT_TYPE[shoppableContentType],
    taggedProducts,
    sourcePage
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShoppablePostMyCartBtnClick.proto
export const evShoppablePostMyCartBtnClick = ({ shoppablePostId, shoppableContentType, sourcePage }) => ({
  [SHOPPABLE_POST_MY_CART_BUTTON_CLICK]: {
    shoppablePostId,
    shoppableContentType: INFLUENCER_CONTENT_TYPE[shoppableContentType],
    sourcePage
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShoppablePostProductInteraction.proto
export const evShoppablePostProductInteraction = ({ shoppablePostId, shoppableContentType, sourcePage, interactionType, selectedProduct }) => ({
  [SHOPPABLE_POST_PRODUCT_INTERACTION]: {
    shoppablePostId,
    interactionType,
    selectedProduct,
    shoppableContentType: INFLUENCER_CONTENT_TYPE[shoppableContentType],
    sourcePage
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/BrandShopTheLookSearchImpression.proto
export const evBrandShopTheLookSearchImpression = ({ placementType, position, searchTerm, contents, sourcePage }) => ({
  [BRAND_SHOP_THE_LOOK_SEARCH_IMPRESSION]: {
    placementType,
    position,
    searchTerm,
    contents,
    sourcePage
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/BrandShopTheLookImpression.proto
export const evBrandShopTheLookImpression = ({ placementType, contents, sourcePage }) => ({
  [BRAND_SHOP_THE_LOOK_IMPRESSION]: {
    placementType,
    contents,
    sourcePage
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/BrandShopTheLookCardClick.proto
export const evBrandShopTheLookCardClick = ({ placementType, position, searchTerm, content, sourcePage }) => ({
  [BRAND_SHOP_THE_LOOK_CARD_CLICK]: {
    placementType,
    position,
    searchTerm,
    content,
    sourcePage
  }
});
