import appendQuery from 'append-query';

import { constructMSAImageUrl } from 'helpers/index';
import type { AllJanusSlotNames, FormattedJanusReco, JanusData, JanusLimits, JanusSlot, RecoFromJanus } from 'types/mafia';
import type { ProductWithRelations, ProductWithRelationsFromCalypso, RecoFromCalypso } from 'types/calypso';
import { getSlotMap, isDesktop, isMobileRecosAvailable, isMobileSlotsAvailable } from 'helpers/ClientUtils';
import type { RecosState } from 'reducers/recos';
import { areRecosFlattened } from 'reducers/recos';
import marketplace from 'cfg/marketplace.json';
import type { BadgeData } from 'types/badges';

export const RECOS_CANT_FIND_YOUR_SIZE_KEY = 'cfys-modal-0';

const {
  recos: { slots, mobileSlots },
  pdp: { recos, mobileRecos }
} = marketplace;
export interface RecoSlotDetails {
  limit: string;
  widget: string;
  filters?: Filter[];
  pf_rd_p?: string;
}
interface Filter {
  name: string;
  value: string[];
  recoId?: string;
}

export function makeRecoMSAImage(imageMSAId: string) {
  const opts = { autoCrop: true, width: 272 };
  return constructMSAImageUrl(imageMSAId, opts);
}

// Each filter key must be suffixed with a unique id identifying the reco they belong to.
// This id is either already baked within the filter (f.recoId) or a recommender slot id (slotDetails.pf_rd_p).
const buildRecoKeyFilterPart = (slotDetails: RecoSlotDetails) => (f: Filter) =>
  [f.name, JSON.stringify(f.value)]
    .concat(f.recoId || slotDetails.pf_rd_p || '')
    .filter(Boolean)
    .join('_');

export function buildRecosKey(slotDetails: RecoSlotDetails, recoName = '') {
  return (slotDetails.filters || [])
    .map(buildRecoKeyFilterPart(slotDetails))
    .concat((!slotDetails?.filters?.length && recoName) || '')
    .filter(Boolean)
    .join('_'); // Some recos are uniquely identified with a widget name (e.g. 'home-1').
}

function fixBadApiValues(filterValue: string) {
  const filterString = filterValue.replace(/, '/g, ",'");
  const replaced = filterString.replace(/[[\]']/g, '');
  return replaced.split(',');
}

function calculatePercentOff(price: string, basePrice: string) {
  const rawPrice = Number(price.replace('$', ''));
  const rawBasePrice = Number(basePrice.replace('$', ''));
  if (rawPrice && rawBasePrice && rawPrice !== rawBasePrice) {
    return Math.round((1 - rawPrice / rawBasePrice) * 100) + '%';
  }
  return '0%';
}

export function parseRecommenderFilterValue(filterValue: string | string[]): string[] {
  if (Array.isArray(filterValue)) {
    // did the gateway API get improved so we don't have to do this workaround?
    return filterValue;
  } else if (typeof filterValue === 'string' && filterValue.indexOf('[') === 0 && filterValue.indexOf(']') === filterValue.length - 1) {
    return fixBadApiValues(filterValue);
  }
  return [];
}

// janus properties are terrible, let's make them more similar to the cloud catalog / calypso property names
export function normalizeToPatronLikeProduct(
  { brand, c_base_price: originalPrice, price, name, logical_id: logicalId, item_id: itemId, link, imageId }: RecoFromJanus,
  recoName: string
): FormattedJanusReco {
  const imageThumbnail = makeRecoMSAImage(imageId);

  if (recoName && link) {
    link = appendQuery(link, `ref=${recoName}`);
  }
  return {
    brandName: brand,
    originalPrice,
    price,
    percentOff: calculatePercentOff(price, originalPrice),
    productName: name,
    productId: logicalId,
    productUrl: `/product/${logicalId}${recoName ? `?ref=${recoName}` : ''}`,
    styleId: itemId,
    thumbnailImageId: imageId,
    thumbnailImageUrl: imageThumbnail,
    recoName,
    imageId,
    link
  };
}

export function normalizeCalypsoRecos(recos: RecoFromCalypso[]): FormattedJanusReco[] {
  return recos.map(
    ({
      brandName,
      originalPrice,
      price,
      percentOff,
      productName,
      productId,
      productUrl,
      styleId,
      imageId,
      thumbnailImageId,
      thumbnailImageUrl
    }) => ({
      brandName,
      originalPrice,
      price,
      percentOff,
      productName,
      productId,
      productUrl,
      styleId,
      imageId,
      link: productUrl,
      recoName: '',
      thumbnailImageId: thumbnailImageId || '',
      thumbnailImageUrl: thumbnailImageUrl || ''
    })
  );
}

export function buildProductPageRecoKey(productId: string, extraId?: string): string {
  const extra = extraId ? `_${extraId}` : '';
  return `pdp_${productId}${extra}`;
}

export function getRecoCount(recoData: Partial<Record<string, JanusSlot>>) {
  if (typeof recoData !== 'object') {
    return 0;
  }
  return Object.keys(recoData).reduce((accumulator: number, key: string) => (recoData[key]?.recs?.length || 0) + accumulator, 0);
}

export function getRecosSlot(recoData: any, slot: 'slot0' | 'slot1' | 'slot2' | 'slot3' | 'cfys', lastReceivedRecoKey?: string) {
  const recosSlotMap = !isDesktop() && isMobileRecosAvailable(mobileRecos) ? mobileRecos : recos; // default to desktop recos in unsupoorted marketplaces
  const recoSlots = (recosSlotMap[slot] as AllJanusSlotNames[] | undefined) || null;
  if (recoSlots) {
    const mainSlot = recoSlots[0]!;
    const backupSlot = recoSlots[1]!;
    const { [mainSlot]: detail, [backupSlot]: backupDetail } = recoData;
    const mainSlotRecos = detail && (detail.recos || detail.sims);
    const backupSlotRecos = backupDetail && (backupDetail.recos || backupDetail.sims);
    if (mainSlotRecos && mainSlotRecos.length) {
      return detail;
    } else if (backupSlotRecos && backupSlotRecos.length) {
      return backupDetail;
    } else if (lastReceivedRecoKey && recoData[lastReceivedRecoKey]) {
      const lastReceivedFirstChoice = recoData[lastReceivedRecoKey][slot];
      const lastReceivedBackup = recoData[lastReceivedRecoKey][backupSlot];
      if (lastReceivedFirstChoice && lastReceivedFirstChoice.recos.length) {
        return lastReceivedFirstChoice;
      } else if (lastReceivedBackup && lastReceivedBackup.recos.length) {
        return lastReceivedBackup;
      }
    }
  }
  return null;
}

export function buildRequestSlots(isCFYSslot: boolean) {
  const recosSlotMap = getSlotMap(!isDesktop(), mobileSlots, slots);
  const requestSlots = [];

  if (!isCFYSslot) {
    // Default slots
    requestSlots.push(getRecoSlotKey(2)); // Always include detail-2

    // Alway add detail-1
    requestSlots.unshift(getRecoSlotKey(1)); // Set this slot as the first position to not mess with the wacky `limits` pattern
    requestSlots.push(getRecoSlotKey(3));

    if (recosSlotMap.hasOwnProperty(getRecoSlotKey(0))) {
      requestSlots.push(getRecoSlotKey(0)); // push complete the look reco slot last to not screw up the others
    }
  } else {
    // Can't find your size slot is built seperately only when the CFYS modal is oepened
    requestSlots.push(getRecoSlotKey(RECOS_CANT_FIND_YOUR_SIZE_KEY));
  }

  type JanusRequestParams = {
    widgets: string;
    limits: JanusLimits;
  };
  // now we have the slots and limits, so order it in the way that janus wants
  const janusRequestParams: JanusRequestParams = { limits: {}, widgets: '' };
  janusRequestParams.widgets = requestSlots.join(',');
  for (let i = 0; i < requestSlots.length; i++) {
    janusRequestParams.limits[`limit_${i}` as keyof JanusLimits] = recosSlotMap[requestSlots[i] as keyof typeof recosSlotMap];
  }
  return janusRequestParams;
}

// Returns a boolean seeing if recommendations should update
// TODO ts Type this function when `hearts` are typed
export function shouldRecosUpdate(props: any, nextProps: any): boolean {
  if (nextProps.similarProductRecos.isLoading) {
    return false;
  }
  if (props.heartsData?.heartsList !== nextProps.heartsData?.heartsList) {
    return true;
  }
  if (props.similarProductRecos.lastReceivedRecoKey === nextProps.similarProductRecos.lastReceivedRecoKey) {
    return false;
  }
  return true;
}

export function getRecoSlotData(preferredSlot?: JanusData, backupSlot?: JanusData) {
  if (preferredSlot?.recos?.length) {
    return preferredSlot;
  }
  return backupSlot;
}

// Default to desktop is slots on unsupported marketplaces
export const getRecoSlotKey = (index: number | string) => `${!isDesktop() && isMobileSlotsAvailable(mobileSlots) ? 'mobile-' : ''}detail-${index}`;
export const getRecoSlotKeyDesktop = (index: number) => `detail-${index}`;

export const getJanusRecos = (similarProductRecos: RecosState) => {
  const { janus = {}, lastReceivedRecoKey = '' } = similarProductRecos;
  const janusData = janus[lastReceivedRecoKey] || {};
  let janusRecos: JanusData | undefined;
  if (!areRecosFlattened(janusData)) {
    janusRecos = janusData[getRecoSlotKey(1)];
    if (!janusRecos) {
      // desktop recos as back up in case of mobile re-render
      janusRecos = janusData[getRecoSlotKeyDesktop(1)];
    }
  }
  return janusRecos;
};

/**
 * Calypso returns an array of objects each with an array of relatedStyles.
 * However, the primary styleId we want may be either one of the primary styleId keys returned, or one from one of the relatedStyles array.
 * Create a map for all the styleIds returned, each having an arrya of its related styleids
 * for fuller example see test/helpers/mock_data/mock_product_relations.js
 * tldr;
 *
 * [
 *  {
 *     styleId: "1"
 *       ... style 1 product info,
 *      "relatedStyles": [
 *         { "styleId": "2", ... style 2 product info },
 *         { "styleId": "3", ... style 3 product info }
 *      ]
 *   }
 * ];
 *
 * becomes:
 * {
 *   "1": { "relatedStyleIds": ["1", "2", "3"] ... product 1 info },
 *   "2": { "relatedStyleIds": ["1", "2", "3"] ... product 2 info },
 *   "3": { "relatedStyleIds": ["1", "2", "3"] ... product 3 info }
 * }
 */
export const flattenProductRelations = (products: ProductWithRelationsFromCalypso[]) => {
  const productRelations: { [s: string]: ProductWithRelationsFromCalypso } = {};

  products.forEach(product => {
    const { relatedStyles, ...styleInfo } = product;
    productRelations[styleInfo.styleId] = { ...styleInfo, relatedStyles: [] }; // we want records outisde relatedStyles for displaying reviews
    if (relatedStyles) {
      productRelations[styleInfo.styleId] = styleInfo;
      const styleGroup = [styleInfo, ...relatedStyles];
      const styleGroupIds = styleGroup.map(s => s.styleId);
      styleGroup.forEach(relatedStyle => {
        productRelations[relatedStyle.styleId] = { ...relatedStyle, relatedStyleIds: styleGroupIds };
      });
    }
  });
  return productRelations;
};

export const populateProductWithRelatedStyleIds = (
  relatedStyleIds: string[],
  productRelations: { [p: string]: ProductWithRelations }
): ProductWithRelationsFromCalypso[] => {
  const initialRelatedStyles: ProductWithRelations[] = [];
  return relatedStyleIds.reduce((filtered, styleId) => {
    const relatedProduct = productRelations[styleId];
    if (relatedProduct) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { relatedStyleIds, relatedStyles, ...relatedProductWithoutRelations } = relatedProduct; //remove relations from relatedStyles entries
      filtered.push(relatedProductWithoutRelations);
    }
    return filtered;
  }, initialRelatedStyles);
};

/**
 * Return product info for a single styleId, and for related styles of given styleId
 */
export const getProductWithRelatedStyles = (styleId: string, productRelations: { [s: string]: ProductWithRelations }) => {
  // created these defaults to make the return type play nice until this could be refactored to a better pattern.
  // when a function returns 2 different objects + some other product, TS makes it difficult to use in the future because it cant infer the types.
  //ie: this function should be more like return SomeProductWithRelatedStylesType | undefined;
  const defaultProduct = {} as ProductWithRelations;
  const defaultReturn = {
    relatedStyleIds: [] as string[],
    relatedStyles: [] as ProductWithRelationsFromCalypso[],
    badges: [] as BadgeData[],
    ...defaultProduct
  };

  if (!productRelations) {
    return defaultReturn;
  }

  const product = productRelations[styleId];

  if (product === undefined) {
    return defaultReturn;
  }

  if (product.relatedStyleIds === undefined || product.relatedStyleIds.length === 0) {
    return product;
  }

  product.relatedStyles = populateProductWithRelatedStyleIds(product.relatedStyleIds, productRelations);

  return product;
};
