import { PROVIDE_LABEL_OPTIONS } from 'constants/exchanges';
import { AFTER_PAY, AMAZON_PAY, CREDIT_CARD, PAYPAL } from 'constants/paymentMethodTypes';
import { AFTER_PAY_BUTTON_JS_TYPE, AFTER_PAY_JS_TYPE, AMAZON_PAY_JS_TYPE, PAYPAL_JS_TYPE } from 'constants/externalJavascriptFiles';

export const myAccountLinkMap = {
  MANAGE_MY_ADDRESSES_CLICK: 1,
  MANAGE_MY_PAYMENT_CLICK: 2,
  SEARCH_ORDERS_CLICK: 3,
  RETURN_ITEMS_CLICK: 4,
  MANAGE_EMAIL_PREFERENCES_CLICK: 6,
  MANAGE_ACCOUNT_INFORMATION_CLICK: 7,
  VIEW_VIP_DASHBOARD_CLICK: 8,
  VIEW_VIP_INFORMATION_CLICK: 9,
  RETURN_POLICY_CLICK: 10
};

export const PAYMENT_METHOD_TYPE = {
  [CREDIT_CARD]: 'CREDIT_CARD_PAYMENT_METHOD_TYPE',
  [AMAZON_PAY]: 'AMAZON_PAY_PAYMENT_METHOD_TYPE',
  [AFTER_PAY]: 'AFTER_PAY_PAYMENT_METHOD_TYPE',
  [PAYPAL]: 'PAY_PAL_PAYMENT_METHOD_TYPE'
};

export const PURCHASE_TYPE = {
  [CREDIT_CARD]: CREDIT_CARD,
  [AMAZON_PAY]: AMAZON_PAY,
  [AFTER_PAY]: AFTER_PAY,
  [PAYPAL]: PAYPAL
};

export const EXTERNAL_PAYMENT_JS_TYPE = {
  [AMAZON_PAY_JS_TYPE]: 'AMAZON_PAY_JS',
  [AFTER_PAY_JS_TYPE]: 'AFTER_PAY_JS',
  [AFTER_PAY_BUTTON_JS_TYPE]: 'AFTER_PAY_BUTTON_JS',
  [PAYPAL_JS_TYPE]: 'PAY_PAL_JS'
};

export const SHIPPING_DURATION = {
  '3-5 Business Days': 'DURATION_DAYS_3_5',
  '2-Business Days': 'DURATION_DAYS_2',
  '1-Business Day': 'DURATION_DAYS_1',
  '3-7 Business Days': 'DURATION_DAYS_3_7',
  '5-7 Business Days': 'DURATION_DAYS_5_7',
  '5-10 Business Days': 'DURATION_DAYS_5_10'
};

export const SHIPPING_NAME = {
  'next-wow': 'NEXT_WOW',
  'second': 'SECOND',
  'next-business': 'NEXT_BUSINESS',
  'next': 'NEXT',
  'std-us-non48': 'STD_US_NON_48',
  'std-n-us': 'STD_N_US',
  'std-us-military': 'STD_US_MILITARY',
  'std-us-protect': 'STD_US_PROTECT',
  'std-us': 'STD_US'
};

export const SHIPPING_PROMISE = {
  '3-5 Business Days': 'BUSINESS_DAYS_3_5',
  '2-Business Days': 'BUSINESS_DAYS_2',
  '1-Business Day': 'BUSINESS_DAYS_1',
  '3-7 Business Days': 'BUSINESS_DAYS_3_7',
  '5-7 Business Days': 'BUSINESS_DAYS_5_7',
  '5-10 Business Days': 'BUSINESS_DAYS_5_10'
};

export const PROMISE_CHANGE_TYPE = {
  PCE_TO_PCE: 'PCE_TO_PCE',
  DEX_TO_DEX: 'DEX_TO_DEX',
  DEX_TO_PCE: 'DEX_TO_PCE'
};

export const RETURN_LABEL_TYPE = {
  [PROVIDE_LABEL_OPTIONS.PRINT]: 'PRINTED_RETURN_LABEL',
  [PROVIDE_LABEL_OPTIONS.EMAIL]: 'EMAIL_RETURN_LABEL',
  [PROVIDE_LABEL_OPTIONS.LABELLESS]: 'PRINT_AT_UPS_RETURN_LABEL',
  [PROVIDE_LABEL_OPTIONS.MAIL]: 'MAIL_RETURN_LABEL',
  [PROVIDE_LABEL_OPTIONS.PICKUP]: 'PICKUP_RETURN_LABEL',
  [PROVIDE_LABEL_OPTIONS.UPS_PACKAGELESS]: 'UPS_PACKAGELESS',
  [PROVIDE_LABEL_OPTIONS.WFM_PACKAGELESS]: 'WFM_PACKAGELESS'
};

export const RETURN_INITIATE_SOURCE = {
  ACCOUNT_PAGE_RETURN_ITEMS_BUTTON: 'ACCOUNT_PAGE_RETURN_ITEMS_BUTTON',
  ORDER_PAGE_SELECT_ITEMS_BUTTON: 'ORDER_PAGE_SELECT_ITEMS_BUTTON',
  ORDER_PAGE_ADD_ADDITIONAL_ITEMS_BUTTON: 'ORDER_PAGE_ADD_ADDITIONAL_ITEMS_BUTTON'
};

export const PRODUCT_LINK_TYPE = {
  UNKNOWN_PRODUCT_LINK_TYPE: 0, // Default value, dont use if you can help it
  TEXT_LINK: 1,
  IMAGE_LINK: 2
};

export const ORDER_LINK_TYPE = {
  UNKNOWN_ORDER_LINK: 0,
  ORDER_TEXT_LINK: 1,
  ORDER_IMAGE_LINK: 2
};

export const ORDER_HISTORY_ACTION_TYPE = {
  ORDER_HISTORY_CANCEL: 0,
  ORDER_HISTORY_RETURN: 1,
  ORDER_HISTORY_REVIEW: 2,
  ORDER_HISTORY_EXCHANGE: 3,
  ORDER_HISTORY_PRINT_RETURN_LABEL: 4,
  ORDER_HISTORY_RETURN_ALL: 5,
  ORDER_HISTORY_EXCHANGE_ALL: 6,
  ORDER_HISTORY_CANCEL_RETURN: 7
};

export const COLLECTION_TYPE = {
  UNKNOWN_COLLECTION_TYPE: 0,
  FAVORITES_LIST: 1,
  INFLUENCER_COLLECTION: 2
};

export const INFLUENCER_SIGNUP_METHOD_TYPE = {
  UNKNOWN_METHOD_TYPE: 0,
  CREATE_NEW_ACCOUNT: 1,
  SIGN_IN_WITH_ZAPPOS: 2,
  SIGN_IN_WITH_AMAZON: 3,
  SIGN_IN_WITH_GOOGLE: 4
};

// https://code.amazon.com/packages/AmethystEvents/blobs/98f201fb72c5cd551dc8282d7e36a2f3d12e5964/--/configuration/include/com/zappos/amethyst/website/WebsiteEnums.proto#L47
export const INFLUENCER_SOCIAL_MEDIA = {
  unknown: 'UNKNOWN_INFLUENCER_SOCIAL_MEDIA',
  facebook: 'INFLUENCER_FACEBOOK',
  twitter: 'INFLUENCER_TWITTER',
  instagram: 'INFLUENCER_INSTAGRAM',
  youtube: 'INFLUENCER_YOUTUBE',
  tiktok: 'INFLUENCER_TIKTOK',
  pinterest: 'INFLUENCER_PINTEREST',
  copy: 'INFLUENCER_COPY_LINK',
  native: 'INFLUENCER_NATIVE_SHARE'
};

// As defined here: https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/WebsiteEnums.proto#L689
export const SUBSCRIPTION_CATEGORY = {
  UNKNOWN_SUBSCRIPTION_CATEGORY: 'UNKNOWN_SUBSCRIPTION_CATEGORY',
  MARKETING: 'MARKETING',
  BRAND: 'BRAND',
  STOCK: 'STOCK'
};

export const ZAW_RETURN_SCENARIO = {
  SUBSIDY_CAN_BE_REISSUED: 0,
  SUBSIDY_CANNOT_BE_REISSUED_REASON_EXPIRED: 1,
  SUBSIDY_CANNOT_BE_REISSUED_REASON_CUSTOMER_NOT_ENROLLED: 2,
  SUBSIDY_CANNOT_BE_REISSUED_REASON_MULTI_ITEM_ORDER: 3,
  SUBSIDY_CANNOT_BE_REISSUED_REASON_OTHER: 4
};

export const RETURN_STEP = {
  INITIATE: 0,
  SUBMIT: 1
};

export type ContentType = {
  [key: string]: string;
};

// As defined here: https://code.amazon.com/packages/AmethystEvents/blobs/485da8f41677d8c438a9cd6682e5e9d0d2b06fc1/--/configuration/include/com/zappos/amethyst/website/WebsiteEnums.proto#L729
export const INFLUENCER_CONTENT_TYPE: ContentType = {
  shoppablePhoto: 'SHOPPABLE_PHOTO',
  shoppableVideo: 'SHOPPABLE_VIDEO',
  collection: 'COLLECTION',
  brandShoppablePhoto: 'BRAND_SHOPPABLE_PHOTO'
};

export const InfluencerHubTab = {
  Profile: 'PROFILE',
  ManageContent: 'MANAGE_CONTENT'
};

export const VIDEO_INTERACTION_TYPE = {
  UnknownVideoInteractionType: 'UNKNOWN_VIDEO_INTERACTION_TYPE',
  Pause: 'PAUSE',
  Play: 'PLAY',
  Seeked: 'SEEKED',
  End: 'END'
};

export const RECOMMENDATION_CTA_CLICK = {
  QUICK_VIEW: 'QUICK_VIEW_CLICK',
  ADD_ITEM: 'ADD_ITEM_CLICK',
  PRODUCT_TILE: 'PRODUCT_TILE_CLICK'
};

export enum CHECKOUT_ERROR_FIELD {
  NAME_ON_CARD = 'NAME_ON_CARD',
  CARD_NUMBER = 'CARD_NUMBER',
  EXPIRY_DATE = 'EXPIRY_DATE',
  CVV = 'CVV',
  BILLING_COUNTRY = 'BILLING_COUNTRY',
  BILLING_NAME = 'BILLING_NAME',
  BILLING_ADDRESS = 'BILLING_ADDRESS',
  BILLING_CITY = 'BILLING_CITY',
  BILLING_STATE = 'BILLING_STATE',
  BILLING_ZIP = 'BILLING_ZIP',
  BILLING_PHONE = 'BILLING_PHONE',
  SHIPPING_NAME = 'SHIPPING_NAME',
  SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
  SHIPPING_CITY = 'SHIPPING_CITY',
  SHIPPING_STATE = 'SHIPPING_STATE',
  SHIPPING_ZIP = 'SHIPPING_ZIP',
  SHIPPING_PHONE = 'SHIPPING_PHONE',
  UNKNOWN_CHECKOUT_ERROR_FIELD = 'UNKNOWN_CHECKOUT_ERROR_FIELD'
}

// As defined here: https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/WebsiteEnums.proto#L689-L694
export const PRODUCT_INTERACTION_TYPE = {
  UnknownInteraction: 'UNKNOWN_INTERACTION',
  Hover: 'HOVER',
  Click: 'CLICK',
  Zoom: 'ZOOM'
};

// https://code.amazon.com/packages/AmethystEvents/blobs/ceb291dcc6e9fbf3812eda422ee4de42126563d3/--/configuration/include/com/zappos/amethyst/website/WebsiteEnums.proto#L743
export enum BrandShopTheLookPlacementType {
  UNKNOWN_PLACEMENT = 'UNKNOWN_PLACEMENT',
  CARD = 'CARD',
  BANNER = 'BANNER',
  CAROUSEL = 'CAROUSEL'
}
