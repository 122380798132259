import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import useWindowSize from 'hooks/useWindowSize';

interface ElementSize {
  width: number;
  height: number;
}

const useRefDimensions = (ref: RefObject<HTMLElement>): ElementSize => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { width: windowWidth } = useWindowSize(200);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    debounceDimensions(ref);

    return () => {
      debounceDimensions.cancel();
    };
  }, [ref.current?.clientWidth, windowWidth]); // if the ref size changes or the window size changes update the dimensions

  const debounceDimensions = useCallback(
    debounce(ref => {
      const { current } = ref;
      typeof window !== 'undefined' ? setDimensions({ width: current?.clientWidth, height: current?.clientHeight }) : null;
    }, 200),
    []
  );

  return dimensions;
};

export default useRefDimensions;
