import { middlewareTrack } from 'apis/amethyst';
import { INFLUENCER_COLLECTION_PAGE } from 'constants/amethystPageTypes';
import { COLLECTIONS_PAGE_VIEW, evShareCollectionClick, SHARE_COLLECTION_LIST } from 'events/favorites';
import { COLLECTION_PRODUCT_CLICK_THROUGH } from 'constants/amethyst';

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/InfluencerCollectionSocialIconEvent.proto
export const evInfluencerCollectionSocialIconClick = ({ shareToken, shareIcon }) => ({
  influencerCollectionSocialIconEvent: {
    shareToken,
    shareIcon
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/CollectionsPageView.proto
export const pvInfluencerCollection = ({
  itemCount,
  recommendationImpression,
  productsInCollection,
  isOwner,
  arrivedFromShareLink,
  shareToken,
  collectionId
}) => ({
  collectionsPageView: {
    itemCount,
    recommendationImpression,
    productsInCollection,
    isOwner,
    arrivedFromShareLink,
    shareToken,
    collectionId,
    sourcePage: INFLUENCER_COLLECTION_PAGE
  }
});

export const evCollectionProductClickThrough = ({ styleId, productId, collectionId, websiteDomain, sourcePage }) => ({
  [COLLECTION_PRODUCT_CLICK_THROUGH]: {
    clickedProduct: {
      styleId,
      productId,
      supplementalData: {
        websiteDomain
      }
    },
    collectionId,
    sourcePage
  }
});

export default {
  pageEvent: COLLECTIONS_PAGE_VIEW,
  events: {
    [SHARE_COLLECTION_LIST]: [(_, params) => middlewareTrack(evShareCollectionClick(params))]
  }
};
