import type { AnyAction } from 'redux';

import {
  ADD_MORE_INFLUENCER_CONTENT,
  CHANGE_LIKE_STATUS,
  REQUEST_ALL_INFLUENCER_CONTENT,
  SET_ALL_INFLUENCER_CONTENT,
  SET_BRAND_INFLUENCER_CONTENT_SLOT_DATA,
  SET_CUSTOMER_LIKE_CONTENT,
  SET_INFLUENCER_COLLECTION_CONTENT_EMPTY,
  SET_INFLUENCER_CONTENT_CREATION_FAILED,
  SET_INFLUENCER_CONTENT_CREATION_SUCCESS,
  SET_INFLUENCER_SHOPPABLE_CONTENT_EMPTY,
  START_INFLUENCER_CONTENT_CREATION
} from 'constants/reduxActions';
import type { ContentImage, InfluencerContent, InfluencerContentSlotData } from 'types/influencer';
import { ContentState, InfluencerContentType } from 'types/influencer';

export const getContentImage = (contents: InfluencerContent[], filterValue: string, allowDeclinedContent: boolean = false): any => {
  const filteredContents = filterValue ? contents.filter(content => content.contentType === filterValue) : contents;
  // return pending content if exists
  const pendingContents = filteredContents.filter(content => content.state === ContentState.PENDING);
  if (pendingContents?.length) {
    return pendingContents[0];
  }
  // return declined content if exists
  const declinedContents = filteredContents.filter(content => content.state === ContentState.DECLINED);
  if (allowDeclinedContent && declinedContents?.length) {
    return declinedContents[0];
  }
  // return approved content if exists
  const approvedContents = filteredContents.filter(content => content.state === ContentState.APPROVED);
  if (approvedContents?.length) {
    return approvedContents[0];
  }
  return;
};
export interface customerIsLikedPostContent {
  [key: string]: boolean;
}
export interface InfluencerContentState {
  contents: InfluencerContent[];
  profileImage?: ContentImage;
  coverImage?: ContentImage;
  displayBanner: string;
  isLoaded: boolean;
  isLoading: boolean;
  contentCreationInProgress: string[];
  contentCreationFailed: boolean;
  isCollectionContentEmpty: boolean;
  isShoppableContentEmpty: boolean;
  brandInfluencerContentSlotData: InfluencerContentSlotData;
  customerLikedUnLikedContents: customerIsLikedPostContent;
  nextPageToken?: string;
}
const initialState = {
  contents: [],
  displayBanner: '',
  isLoaded: false,
  isLoading: false,
  contentCreationInProgress: [],
  contentCreationFailed: false,
  isCollectionContentEmpty: false,
  isShoppableContentEmpty: false,
  brandInfluencerContentSlotData: {
    slotPositions: [],
    rowPositions: []
  },
  customerLikedUnLikedContents: {}
};
export default function influencerContent(state: InfluencerContentState = initialState, action: AnyAction): InfluencerContentState {
  switch (action.type) {
    case REQUEST_ALL_INFLUENCER_CONTENT:
      return { ...state, isLoading: true };
    case SET_ALL_INFLUENCER_CONTENT: {
      const { contents, nextPageToken } = action;
      const profileImage = getContentImage(contents, InfluencerContentType.PROFILE_IMAGE);
      const coverImage = getContentImage(contents, InfluencerContentType.COVER_IMAGE);
      const displayBanner = getContentImage(contents, '', true)?.state;
      return {
        ...state,
        contents,
        nextPageToken,
        displayBanner,
        profileImage: profileImage && {
          contentId: profileImage.contentId,
          imageId: profileImage.mediaMetadata[0].mediaId,
          extension: profileImage.mediaMetadata[0].mediaExtension,
          status: profileImage.state
        },
        coverImage: coverImage && {
          contentId: coverImage.contentId,
          imageId: coverImage.mediaMetadata[0].mediaId,
          extension: coverImage.mediaMetadata[0].mediaExtension,
          status: coverImage.state
        },
        isLoaded: true,
        isLoading: false
      };
    }
    case ADD_MORE_INFLUENCER_CONTENT: {
      const { contents, nextPageToken } = action;
      return {
        ...state,
        contents: [...state.contents, ...contents],
        nextPageToken,
        isLoaded: true,
        isLoading: false
      };
    }
    case START_INFLUENCER_CONTENT_CREATION: {
      const { content } = action;
      const contentCreationInProgress = [...state.contentCreationInProgress, content];
      return { ...state, contentCreationInProgress };
    }
    case SET_INFLUENCER_CONTENT_CREATION_SUCCESS: {
      const { content } = action;
      const contentCreationInProgress = state.contentCreationInProgress.filter(item => item !== content);
      return { ...state, contentCreationInProgress };
    }
    case SET_INFLUENCER_CONTENT_CREATION_FAILED: {
      const { content } = action;
      const contentCreationInProgress = state.contentCreationInProgress.filter(item => item !== content);
      return { ...state, contentCreationInProgress, contentCreationFailed: true };
    }
    case SET_INFLUENCER_COLLECTION_CONTENT_EMPTY: {
      const { isCollectionContentEmpty } = action;
      return { ...state, isCollectionContentEmpty };
    }
    case SET_INFLUENCER_SHOPPABLE_CONTENT_EMPTY: {
      const { isShoppableContentEmpty } = action;
      return { ...state, isShoppableContentEmpty };
    }
    case SET_BRAND_INFLUENCER_CONTENT_SLOT_DATA: {
      const { brandInfluencerContentSlotData } = action;
      return { ...state, brandInfluencerContentSlotData };
    }
    case SET_CUSTOMER_LIKE_CONTENT: {
      return { ...state, customerLikedUnLikedContents: action.content };
    }
    case CHANGE_LIKE_STATUS: {
      const { postId, status } = action;
      const updatedLikeStatusData = { ...state.customerLikedUnLikedContents, [postId]: status };
      return {
        ...state,
        customerLikedUnLikedContents: updatedLikeStatusData
      };
    }
    default: {
      return state;
    }
  }
}
