import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@mweb/zappos-ui/Drawer';
import { ProductCardHorizontal, ProductCardHorizontalDetails, ProductCardHorizontalImage } from '@mweb/zappos-ui/ProductCardHorizontal';
import UtilityStrokeCaretRightMediumIcon from '@mweb/zappos-ui/Icons/UtilityStrokeCaretRightMediumIcon';
import UtilityStrokeCheckFilledSmallIcon from '@mweb/zappos-ui/Icons/UtilityStrokeCheckFilledSmallIcon';

import { showCartModal } from 'actions/cart';
import Button from 'components/common/Button';
import HrefLink from 'components/common/HrefLink';
import useEffectOnce from 'hooks/useEffectOnce';
import { selectCartAddedItem } from 'selectors/cart';
import { selectIsMobile } from 'selectors/headerFooter';

interface AddToBagDrawerProps {
  open: boolean;
}

const AddToBagDrawer = ({ open }: AddToBagDrawerProps) => {
  const addedItem = useSelector(selectCartAddedItem);
  const dispatch = useDispatch();
  const isItemGiftCard = addedItem?.egc && addedItem?.glProductGroupType === 'gl_gift_card';
  const isMobile = useSelector(selectIsMobile);
  const productUrl = addedItem ? `/p/asin/${addedItem.asin}` : '';

  const closeDrawer = useCallback(() => {
    dispatch(showCartModal(false));
  }, []);

  // Desktop only: scroll to top when modal opens
  useEffectOnce(() => {
    if (isMobile || typeof window === 'undefined') {
      return;
    }

    window.scrollTo(0, 0);
  });

  return (
    <Drawer
      // Element can be focused when clicked directly. Override global :focus { z-index: 1 } applied in global.scss
      // Right offset calculation is based on 1440px width of the header and guarantees alignent
      contentClassName="z-[999] focus:z-[999] min-[801px]:top-[calc(var(--header-height)+12px)] min-[801px]:right-[max(24px,calc((100vw-1440px)/2))] min-[801px]:max-w-[430px]"
      onOpenChange={closeDrawer}
      open={open}
      overlayClassName="max-[800px]:z-[998] min-[801px]:z-[9]"
      position={isMobile ? 'bottom' : 'free'}
      title={
        <span className="flex items-center gap-x-1">
          <UtilityStrokeCheckFilledSmallIcon className="text-icon-success" size={16} /> Nice Pick ✨
        </span>
      }
    >
      {addedItem && (
        <>
          <ProductCardHorizontal className="mb-6">
            <ProductCardHorizontalImage
              alt={addedItem.productName}
              href={productUrl}
              ImageComponent="img"
              LinkComponent={HrefLink}
              src={addedItem.imageUrl}
            />
            <ProductCardHorizontalDetails
              brandName={addedItem.brandName}
              color={!isItemGiftCard ? addedItem.color : undefined} // Type needs to be made optional to exclude GC colors
              inseam={addedItem.sizing.languageTagged.inseam}
              itemQuantity={addedItem.quantity}
              productName={addedItem.productName.replace(addedItem.brandName, '')}
              productUrl={productUrl}
              size={!isItemGiftCard ? addedItem.sizing.languageTagged.displaySize : undefined}
              width={!isItemGiftCard ? addedItem.sizing.languageTagged.shoeWidth : undefined}
            />
          </ProductCardHorizontal>
        </>
      )}

      <Button className="mb-3 w-full" href="/cart" height="small" variant="filled">
        View Bag &amp; Checkout
      </Button>

      <Button className="mb-1 w-full justify-center" onClick={closeDrawer} tailIcon={<UtilityStrokeCaretRightMediumIcon size={24} />} variant="text">
        Continue Shopping
      </Button>
    </Drawer>
  );
};

export default AddToBagDrawer;
