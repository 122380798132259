// Search
export const SEARCH_PAGE_VIEW = 'searchPageView';
export const SEARCH_PAGE_CLICKTHROUGH = 'searchPageClickthrough';
export const SEARCH_SORT_CLICK = 'sortSearchClick';
export const SEARCH_FACET_CLICK = 'facetClick';
export const TOGGLE_DESKTOP_FACETS = 'toggleDesktopFacets';
export const TOP_BANNER_AD_CLICK = 'advertisementClick';
export const TOP_BANNER_AD_IMPRESSION = 'advertisementImpressionWrapper';
export const SEARCH_PILL_GROUP_TOGGLE = 'facetAttributeDropdownClick';
export const SEARCH_SHOW_MORE_TOGGLE = 'facetAttributeShowMoreClick';
export const SEARCH_SWATCH_INTERACTION = 'swatchInteraction';
export const SEARCH_SWATCH_IMPRESSION_WRAPPER = 'swatchImpressionWrapper';
export const SEARCH_PRODUCT_INTERACTION = 'searchProductInteraction';
export const SEARCH_AUTO_COMPLETE_IMPRESSION = 'searchAutoCompleteImpression';
export const SEARCH_AUTO_COMPLETE_INTERACTION = 'searchAutoCompleteInteraction';
export const NAVIGATION_FACET_SEARCH = 'navigationFacetSearch';
export const SEARCH_ZERO_STATE_IMPRESSION = 'searchZeroStateImpression';
export const SEARCH_ZERO_STATE_INTERACTION = 'searchZeroStateInteraction';
export const SEARCH_ZERO_STATE_TRUSTED_SITE_INTERACTION = 'searchZeroStateTrustedSiteInteraction';
export const SEARCH_CLEAR_FILTERS = 'searchClearFilters';
export const SEARCH_FILTER_INTERACTION = 'searchFilterInteraction';

// Header
export const TOP_LEVEL_NAV_CLICK = 'topLevelNavigationClick';
export const EXPLICIT_SEARCH = 'explicitSearch';
export const NAVIGATION_CLICK = 'navigationClick';
export const STORE_SELECTOR_EVENT = 'storeSelectorEvent';

// Landing
export const HOME_PAGE_VIEW = 'homePageView';
export const LANDING_PAGE_VIEW = 'landingPageView';
export const BROADCAST_EMAIL_SIGNUP = 'broadcastEmailSignup';
export const BRAND_EMAIL_SIGNUP = 'brandEmailSignUp';

// PDP
export const PRODUCT_PAGE_VIEW = 'productPageView';
export const SIZING_IMPRESSION = 'sizingImpression';
export const PRODUCT_ACCORDION_EVENT = 'productAccordionEvent';
export const PRODUCT_BADGES_EVENT = 'productBadgesEvent';
export const PRODUCT_SOCIAL_ICON_EVENT = 'productSocialIconEvent';
export const PRODUCT_SHARE_ICON_TOOLTIP_EVENT = 'productShareIconTooltipEvent';
export const PRODUCT_GENDER_EVENT = 'productGenderEvent';
export const PRODUCT_COUNTRY_UNIT_EVENT = 'productCountryUnitEvent';
export const PRODUCT_EMPTY_SIZE_OPTIONS_EVENT = 'productEmptySizeOptionsEvent';
export const PRODUCT_IMAGE_IMPRESSION = 'productImageImpression';
export const PRODUCT_IMAGE_THUMBNAIL_EVENT = 'productImageThumbnailEvent';
export const PRODUCT_FEATURED_ZOOM_EVENT = 'productFeaturedZoomEvent';
export const PRODUCT_VIEW_SIZE_CHART = 'productViewSizeChart';
export const PRODUCT_ZOOM_IN_EVENT = 'productZoomInEvent';
export const PRODUCT_ZOOM_OUT_EVENT = 'productZoomOutEvent';
export const OUT_OF_STOCK_MODAL_VIEW = 'outOfStockModalView';
export const RECO_DRAWER_BUTTON_CLICK = 'recoDrawerButtonClick';
export const PRODUCT_NO_STYLE_SIZES = 'productNoStyleSizes';
export const BADGE_CATEGORY_CLICK = 'badgeCategoryClick';
export const ACCORDION_IMPRESSION = 'accordionImpression';
export const PRODUCT_IMAGE_SWIPE_EVENT = 'productImageSwipeEvent';
export const PRODUCT_LOWEST_RECENT_PRICE = 'productLowestRecentPrice';

// Cart
export const CART_ADD = 'addToCart';
export const CART_PAGE_VIEW = 'cartPageView';
export const PROCEED_TO_CHECKOUT = 'proceedToCheckout';

// Account
export const ADD_TO_COLLECTIONS = 'addToCollections';
export const SUBSCRIPTIONS_PAGE_CHANGE = 'subscriptionsPageChange';
export const SUBSCRIPTIONS_PAGE_VIEW = 'subscriptionsPageView';
export const UNSUBSCRIBE_FROM_ALL_CLICK = 'unsubscribeFromAllClick';
export const SAVE_CHANGES_CLICK = 'saveChangesClick';

// Checkout
export const PAYMENT_SECTION = 'PAYMENT_SECTION';
export const SHIPPING_SECTTION = 'SHIPPING_SECTION';
export const BILLING_ADDRESS_SECTION = 'BILLING_ADDRESS_SECTION';

// Influencer
export const INFLUENCER_ENROLLMENT_EVENTS = 'influencer_enrollment_events';
export const INFLUENCER_SIGNUP_EVENTS = 'influencer_signup_events';
export const INFLUENCER_CHECKOUT_PAGE_EVENTS = 'influencer_checkout_page_events';
export const INFLUENCER_CREATE_SHARE_LINK_EVENTS = 'influencer_create_share_link_events';
export const INFLUENCER_LANDING_EVENTS = 'influencer_landing_events';
export const INFLUENCER_ADD_SOCIAL_PROFILE_CLICK = 'influencer_add_social_profile_click';

// Media Source
export const UNKNOWN_MEDIA_SOURCE_TYPE = 'UNKNOWN_MEDIA_SOURCE_TYPE';

// Pixel
export const PIXEL_EVENT = 'pixel_event';

// Storefront
export const STOREFRONT_PAGE_VIEW = 'storefront_page_view';
export const SHARE_STOREFRONT_CLICK = 'share_storefront_click';
export const SHARE_SHOPPABLE_POST_CLICK = 'share_shoppable_post_click';

// Content
export const INFLUENCER_CONTENT_PRODUCT_HOVER = 'influencer_content_product_hover';

// Collections
export const COLLECTION_PRODUCT_CLICK_THROUGH = 'collection_product_click_through';

// Shoppable Post
export const SHOPPABLE_POST_PAGE_VIEW = 'shoppable_post_page_view';
export const SHOPPABLE_POST_ADD_PRODUCT_TAG_CLICK = 'shoppable_post_add_product_tag_click';
export const SHOPPABLE_POST_REMOVE_PRODUCT_TAG_CLICK = 'shoppable_post_remove_product_tag_click';
export const SHOPPABLE_POST_PRODUCT_CLICK_THROUGH = 'shoppable_post_product_click_through';
export const SHOPPABLE_VIDEO_ACTION_EVENT = 'shoppable_video_action_event';
export const CREATE_SHOPPABLE_POST_CLICK = 'create_shoppable_post_click';
export const DELETE_SHOPPABLE_POST_CLICK = 'delete_shoppable_post_click';
export const SHOPPABLE_VIDEO_UPLOAD = 'shoppable_video_upload';
export const SHOPPABLE_POST_QUICK_VIEW = 'shoppable_post_quick_view';
export const SHOPPABLE_POST_MY_CART_BUTTON_CLICK = 'shoppable_post_my_cart_btn_click';
export const SHOPPABLE_POST_PRODUCT_INTERACTION = 'shoppable_post_product_interaction';

// Brand Shop the Look
export const BRAND_SHOP_THE_LOOK_SEARCH_IMPRESSION = 'brand_shop_the_look_search_impression';
export const BRAND_SHOP_THE_LOOK_IMPRESSION = 'brand_shop_the_look_impression';
export const BRAND_SHOP_THE_LOOK_CARD_CLICK = 'brand_shop_the_look_card_click';

// Style Feed
export const STYLE_FEED_PAGE_VIEW = 'style_feed_page_view';
export const STYLE_FEED_CARD_CLICK = 'style_feed_card_click';

// Balder
export const EXCHANGE_INITIATE_PAGE = 'EXCHANGE_INITIATE_PAGE';
export const ORDER_DETAIL_PAGE = 'ORDER_DETAIL_PAGE';

// Outfit Recos
export const OUTFIT_RECO_CARD_VIEW = 'outfit_reco_card_view';
export const OUTFIT_RECO_CARD_CLICK = 'outfit_reco_card_click';
export const OUTFIT_FEEDBACK = 'outfit_feedback';
export const OUTFIT_RECO_MODAL_CAROUSEL_CLICK = 'outfit_reco_modal_carousel_click';
export const OUTFIT_RECO_MODAL_VIEW_DETAILS_CLICK = 'outfit_reco_modal_view_details_click';

// Web Vital
export const WEB_VITAL = 'web_vital';

// Prodcut Comparison Table
export const PRODUCT_COMPARISON_TABLE_VIEW = 'product_comparison_table_view';
export const PRODUCT_COMPARISON_TABLE_PRODUCT_INTERACTION = 'product_comparison_table_product_interaction';

// Launch Calendar
export const LAUNCH_CALENDAR_PAGE_VIEW = 'launch_calendar_page_view';
export const LAUNCH_CALENDAR_SECTION_CLICK = 'launch_calendar_section_click';
export const LAUNCH_CALENDAR_CARD_CLICK = 'launch_calendar_card_click';
export const LAUNCH_CALENDAR_SUBSCRIPTION_CLICK = 'launch_calendar_subscription_click';

// Search By Photo
export const VISUAL_SEARCH_CAMERA_CLICK = 'visual_search_camera_click';
export const VISUAL_SEARCH_UPLOAD_IMAGE = 'visual_search_upload_image';
export const VISUAL_SEARCH_SUBMIT_IMAGE_SUCCESS = 'visual_search_submit_image_success';
export const VISUAL_SEARCH_RESULTS_BOUNDING_BOX_CLICK = 'visual_search_results_bounding_box_click';
export const VISUAL_SEARCH_CANCEL_IMAGE_UPLOAD = 'visual_search_cancel_image_upload';
export const VISUAL_SEARCH_PAGE_VIEW = 'visual_search_page_view';

export const BANNER_ADS_CLICK = 'banner_ads_click';
