// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeCameraMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M5.28125 11.8984H6.92188M29.1797 9.61719V25.6641C29.1797 26.2163 28.732 26.6641 28.1797 26.6641H3.82031C3.26803 26.6641 2.82031 26.2163 2.82031 25.6641V9.61719C2.82031 9.0649 3.26803 8.61719 3.82031 8.61719H8.81947C9.19824 8.61719 9.5445 8.40319 9.71389 8.0644L10.8017 5.88872C10.9711 5.54994 11.3174 5.33594 11.6962 5.33594H20.3038C20.6826 5.33594 21.0289 5.54994 21.1983 5.88872L22.2861 8.0644C22.4555 8.40319 22.8018 8.61719 23.1805 8.61719H28.1797C28.732 8.61719 29.1797 9.0649 29.1797 9.61719ZM22.5625 16.8203C22.5625 20.4447 19.6244 23.3828 16 23.3828C12.3756 23.3828 9.4375 20.4447 9.4375 16.8203C9.4375 13.196 12.3756 10.2578 16 10.2578C19.6244 10.2578 22.5625 13.196 22.5625 16.8203ZM16 20.1016C14.1907 20.1016 12.7188 18.6296 12.7188 16.8203C12.7188 15.011 14.1907 13.5391 16 13.5391C17.8093 13.5391 19.2813 15.011 19.2813 16.8203C19.2813 18.6296 17.8093 20.1016 16 20.1016Z"
      stroke="currentcolor"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Icon>
);

export default UtilityStrokeCameraMediumIcon;
