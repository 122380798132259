/*
 * Feature Flags
 */

export const FEATURE_BANNER_ADS = 'discovery.sponsored.ads.ab.banner.ads';
export const FEATURE_RECOMMENDATIONS_SEARCH = 'marty.recommendations.search';
export const FEATURE_CONTENT_SQUARE_KILLSWITCH = 'marty.content.square';
export const FEATURE_PERCENT_DISCOUNT_SORT = 'search.ab.percent.discount.sort';
export const FEATURE_LEFT_IN_STOCK = 'search.ab.left.in.stock';
export const FEATURE_GOODS_LABEL_ENDPOINT = 'marty.goods.label.endpoint';
export const FEATURE_MAFIA_AKAMAI = 'marty.mafia.akamai';
export const FEATURE_MARTY_CLIENT_RENDER = 'marty.client.side.render';
export const FEATURE_PRODUCT_CARD_GENDER_DISPLAY = 'product.card.gender.display';
export const FEATURE_CHECKOUT_KRATOS = 'marty.checkout.kratos';
export const FEATURE_PDP_ACCORDION_ORDER = 'pdp.accordion.order';

export const FEATURE_ENABLED_VARIANT_NAME_TREATMENT = 'treatment';
