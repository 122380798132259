import type { Cookies } from 'types/cookies';
import {
  MAFIA_AGENT_AUTH_REQ,
  MAFIA_AT_MAIN,
  MAFIA_AUTH_REQ,
  MAFIA_EMAIL_TOKEN,
  MAFIA_RECOGNIZED_TOKEN,
  MAFIA_SESSION_ID,
  MAFIA_SESSION_REQ,
  MAFIA_SESSION_TOKEN,
  MAFIA_UBID_MAIN
} from 'constants/apis';
import { cookieObjectToString } from 'helpers/Cookie';
import { devLogger } from 'middleware/logger';
import { inIframe } from 'helpers/InIframe';

/**
 * Given credentials, returns true if auth headers should be passed or false if
 * cookie auth should be requested.
 * @param  {Object} [credentials={}] mafia authentication credentials
 * @return {boolean}                 if auth headers should be passed
 */
export const authHeadersInUse = (credentials: Cookies = {}, queryParamCredentials: { [key: string]: string } = {}) =>
  !!(credentials['at-main'] || queryParamCredentials['auth_token']);

/**
 * Given headers and credentials, return a new set of headers which includes
 * the appropriate auth-related headers.
 * @param  {Object} [initHeaders={}] inital set of headers
 * @param  {Object} [credentials={}] mafia auth credentials
 * @param queryParamCredentials
 * @return {Object}                  init headers with auth headers
 */
export function withAuthHeaders(initHeaders = {}, credentials: Cookies = {}, queryParamCredentials: { [key: string]: string } = {}) {
  const headers: { [key: string]: any } = { ...initHeaders };
  headers[MAFIA_SESSION_REQ] = 'true';
  // headers for session-reliant mafia calls
  if (credentials['session-id']) {
    headers[MAFIA_SESSION_ID] = credentials['session-id'];
  }
  if (credentials['session-token']) {
    headers[MAFIA_SESSION_TOKEN] = credentials['session-token'];
  }
  if (credentials['ubid-main']) {
    headers[MAFIA_UBID_MAIN] = credentials['ubid-main'];
  }
  if (credentials['x-main']) {
    headers[MAFIA_RECOGNIZED_TOKEN] = credentials['x-main'];
  }
  if (queryParamCredentials['auth_token']) {
    headers[MAFIA_EMAIL_TOKEN] = queryParamCredentials['auth_token'];
  }
  headers.Cookie = cookieObjectToString(credentials);

  if (!Object.keys(credentials).length) {
    devLogger('WARNING: You must send credential headers with your mafia request!');
  }

  if (authHeadersInUse(credentials)) {
    if (credentials['at-main']) {
      headers[MAFIA_AT_MAIN] = credentials['at-main'];
    }
  } else {
    headers[MAFIA_AUTH_REQ] = true; //pretty sure this should be a string, not a boolean.
  }

  // CSC agents - only supporting client side calls with this
  if (inIframe()) {
    headers[MAFIA_AGENT_AUTH_REQ] = true; //pretty sure this should be a string, not a boolean.
    delete headers[MAFIA_AUTH_REQ];
    delete headers[MAFIA_SESSION_REQ];
  }
  return headers;
}

/**
 * Build a fetch options, given initial config and credentials. This will
 * populate necessary auth headers and fetch opts based on given credentials.
 * @param  {Object} [initOpts={}]    inital fetch opts
 * @param  {Object} [credentials={}] mafia credentials
 * @param  {Object} [queryParamCredentials={}] query param mafia credentials
 * @return {Object}                  fetch options
 */
export function fetchOpts(initOpts: any = {}, credentials: Cookies = {}, queryParamCredentials = {}) {
  const headers = withAuthHeaders(initOpts.headers || {}, credentials, queryParamCredentials);
  const credOpts: {
    credentials?: RequestCredentials;
  } = authHeadersInUse(credentials, queryParamCredentials) ? {} : { credentials: 'include' };
  return { ...initOpts, ...credOpts, headers };
}

export function sendJsonDataWithCreds(url: string, data: any, options: any = {}) {
  const { credentials, queryParamCredentials, method = 'post', fetcher, additionalHeaders = {} } = options;
  return fetcher(
    url,
    fetchOpts(
      {
        method,
        headers: { 'Content-Type': 'application/json', ...additionalHeaders },
        body: JSON.stringify(data)
      },
      credentials,
      queryParamCredentials
    )
  );
}

export function withSession(payload: any, headers: any) {
  return { payload, session: headers };
}
