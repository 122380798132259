import {
  CANCEL_ORDER_HISTORY_SEARCH,
  ERROR_ORDER_HISTORY_SEARCH,
  RECEIVE_ORDER_HISTORY_SEARCH,
  RECEIVE_ORDERS,
  REQUEST_ORDER_HISTORY_SEARCH,
  REQUEST_ORDERS,
  TOGGLE_ORDER_COLLAPSE
} from 'constants/reduxActions';
import { injectUniqueIdsToLineItems } from 'helpers/MyAccountUtils';
import type { Order } from 'types/mafia';
import type { OrdersAction } from 'actions/account/orders';

export interface OrdersState {
  orders: Order[] | null;
  totalPages: number | null;
  isLoading: boolean;
  searchOrdersError: boolean;
  uncollapsedOrderHistoryRowMap: UncollapsedOrderHistoryRowMap;
}
export interface UncollapsedOrderHistoryRowMap {
  [key: string]: boolean;
}

const initialState: OrdersState = {
  orders: null,
  totalPages: null,
  isLoading: false,
  searchOrdersError: false,
  uncollapsedOrderHistoryRowMap: {}
};

export default function orders(state = initialState, action: OrdersAction): OrdersState {
  switch (action.type) {
    case REQUEST_ORDERS:
      return { ...state, isLoading: true };
    case RECEIVE_ORDER_HISTORY_SEARCH:
    case RECEIVE_ORDERS:
      const { orders, totalPages } = action;
      const newOrders = orders.map((order: Order) => ({
        ...order,
        lineItems: injectUniqueIdsToLineItems(order)
      }));
      const updatedCollapsedOrderHistoryRowMap = orders.length ? { [orders[0]!.orderId]: true } : {};
      return {
        ...state,
        totalPages,
        orders: newOrders,
        isLoading: false,
        uncollapsedOrderHistoryRowMap: {
          ...state.uncollapsedOrderHistoryRowMap,
          ...updatedCollapsedOrderHistoryRowMap
        }
      };

    case REQUEST_ORDER_HISTORY_SEARCH:
      return { ...state, isLoading: true, searchOrdersError: false };

    case CANCEL_ORDER_HISTORY_SEARCH:
      return { ...state, searchOrdersError: false };

    case ERROR_ORDER_HISTORY_SEARCH:
      return { ...state, searchOrdersError: true, isLoading: false };

    case TOGGLE_ORDER_COLLAPSE:
      const { orderIdToCollapse } = action;
      const { uncollapsedOrderHistoryRowMap } = state;
      const updatedUncollapsedOrderHistoryRowMap = {
        ...uncollapsedOrderHistoryRowMap
      };
      updatedUncollapsedOrderHistoryRowMap[orderIdToCollapse] = !updatedUncollapsedOrderHistoryRowMap[orderIdToCollapse];
      return {
        ...state,
        uncollapsedOrderHistoryRowMap: updatedUncollapsedOrderHistoryRowMap
      };

    default:
      return state;
  }
}
