import appendQuery from 'append-query';
import { parse, parseUrl, stringify } from 'query-string';

import { sanitizeForEvent, stripSpecialCharsConsolidateWhitespace } from './index';

import { SINGLE_SELECT_FILTERS } from 'constants/appConstants';
import { OOS_REDIRECT_SUFFIX } from 'actions/redirect';
import {
  EXTRACT_URL_SEGMENTS_RE,
  MARTY_URL_PREFIX_RE,
  NONZSO_SEARCH_RE,
  PAGE_NUMBER_IN_URL_RE,
  QUERY_PARAMS_RE_GEN,
  SEO_URL_RE,
  SLASH_SEARCH_RE,
  ZSO_URL_RE
} from 'common/regex';
import { looksLikeSeoUrl, seoTermToHumanTerm, termToSeoPath } from 'helpers/SeoUtils';
import { parsePath } from 'helpers/LocationUtils';
import layoutConfig from 'common/searchLayoutConfig';
import { stripAppRoot } from 'history/AppRootUtils';
import { priceToFloat } from 'helpers/ProductUtils';
import { guid } from 'helpers/guid';
import { replaceAll } from 'helpers/DataFormatUtils';
import { loadFromLocalStorage, saveToLocalStorage } from 'helpers/localStorageUtilities';
import type { SortOptionProps } from 'components/search/Sort';
import { getSelectedSortValue } from 'components/search/Sort';
import type { SavedSizes } from 'types/opal';
import type { CartPixelServerItems } from 'actions/cart';

const SEARCH_URL_WHITELISTED_PARAMS_LIST = ['p', 'page', 's', 'si', 'sort', 'sy', 't', 'term'];
const SEARCH_PARAMS_TO_REMOVE = ['p', 'pf_rd_r', 'pf_rd_p', 'sy', 'si'];

const SHOE_KEY = 'Shoes';
const CLOTHING_KEY = 'Clothing';
const ACCESSORIES_KEY = 'Accessories';
const ADAPTIVE_KEY = 'Adaptive';

// Janus keys
const JANUS_COLOR_KEY = 'color';
const JANUS_ZC1_KEY = 'z_cat_name_1';
const JANUS_ZC2_KEY = 'z_cat_name_2';
const JANUS_GENDER_KEY = 'tx_gender';
const JANUS_BRAND_KEY = 'brand';

// Facets / Filters
const GENDER_FACET = 'txAttrFacet_Gender';
const COLOR_FACET = 'colorFacet';
const BRAND_NAME_FACET = 'brandNameFacet';
const ZC1FACET = 'zc1';
const ZC2FACET = 'zc2';

const SHOES = 'filter/zc1/%22Shoes%22';
const CLOTHING = 'filter/zc1/%22Clothing%22';
const ACCESSORIES = 'filter/zc1/%22Accessories%22';
const TOYS = 'filter/zc1/%22Toys+and+Games%22';
const ADAPTIVE = 'txAttrFacet_Verticals/%22Adaptive%22';
const WOMEN = `${GENDER_FACET}/%22Women%22`;
const MEN = `${GENDER_FACET}/%22Men%22`;
const BOYS = `${GENDER_FACET}/%22Boys%22`;
const GIRLS = `${GENDER_FACET}/%22Girls%22`;

const janusFilterMap = new Map([
  [COLOR_FACET, JANUS_COLOR_KEY],
  [ZC1FACET, JANUS_ZC1_KEY],
  [ZC2FACET, JANUS_ZC2_KEY],
  [GENDER_FACET, JANUS_GENDER_KEY],
  [BRAND_NAME_FACET, JANUS_BRAND_KEY]
]);

export const ALL_REDIRECTED_KEY = 'allRedirected';
export const SEARCH_FILTER_KEY = 'searchFilter';

export const FOO_URL = 'https://www.foo.com';

type SearchFilters = {
  selected: {
    singleSelects: Record<string, string[]>;
    multiSelects: Record<string, string[]>;
  };
  term: string;
  sort: Record<string, unknown>;
  page: number;
  si: unknown;
  executedSearchUrl?: string;
  savedsizes?: SavedSizes;
};

/*
  Returns a String from the filter list that uniquely identifies the

  1. Applied filters
  2. Search term
  3. Sort order
  4. Page
*/

export function buildSearchFilterChecksum(filters: SearchFilters) {
  const allFilters = Object.assign({}, filters.selected.singleSelects, filters.selected.multiSelects);
  return `${JSON.stringify(allFilters)}|${filters.term}|${JSON.stringify(filters.sort)}|${filters.page}`;
}

/**
 * Builds the "payload" field for search-related events.  Handles special characters, converts spaces to dashes, and lowercases the search term.  If there is no search term, it uses 'no-term' instead.
 */
export function buildSearchTermEventPayload(searchText: string) {
  if (searchText) {
    return sanitizeForEvent(searchText).toLowerCase();
  } else {
    return 'no-term';
  }
}

/**
 *  Returns a string with the page (1-indexed), row position (1-indexed), and column position (1-indexed) of the product.
 * @param  {Integer} filterPage              0 indexed page
 * @param  {Integer} productPosition         position in result (1 indexed)
 * @param  {String} [layoutId='three-item'] the layout Id for the rendered page
 * @return {String}                         String that has the computed row+column of the clicked product.
 */
export function formatProductClickEvent(filterPage: number, productPosition: number, layoutId = 'three-item') {
  const fallbackLayoutId = (layoutId in layoutConfig ? layoutId : 'three-item') as keyof typeof layoutConfig;
  const { columnCount } = layoutConfig[fallbackLayoutId];
  const mod = productPosition % columnCount;
  return `Page${filterPage + 1}-Pos${Math.ceil(productPosition / columnCount)}-${mod === 0 ? columnCount : mod}`;
}

export function sanitizeHurlResponse(filter: string) {
  return decodeURIComponent(filter).replace(/"+/g, '').replace(/\+/g, ' ').split(' OR ');
}

export function buildFiltersObject(hurlFiltersUrl: string) {
  const result: Record<string, string[]> = {};
  const values = hurlFiltersUrl.split('/').filter(v => {
    if (v.length > 0 && v !== 'null' && v !== 'filter' && v !== 'search') {
      return v;
    }
    return;
  });

  if (values.length > 0) {
    values.forEach((el, i, arr) => {
      if (i % 2) {
        result[arr[i - 1] as string] = sanitizeHurlResponse(el);
      }
    });
  }
  return result;
}

function sanitizeSort(value: string) {
  return value === 'asc' || value === 'desc' ? value : 'desc';
}

export function decodeSort(sortQueryParam: string) {
  const sort: Record<string, string> = {};
  if (sortQueryParam) {
    const sortValue = sortQueryParam.replace(' ', '').split('/');
    sortValue.forEach((_, i) => {
      const par = sortValue[i] as string;
      const val = sortValue[i + 1] as string;
      if (i % 2 === 0 && par.length > 0) {
        sort[par] = sanitizeSort(val);
      }
    });
  }
  return sort;
}

export function locationLooksLikeNonNullQueryStringSearch({ pathname, query }: { pathname: string; query: Record<string, string> }) {
  return (pathname === '/search' || pathname === '/marty/search') && query?.term && ![null, 'null', ''].includes(query.term);
}

/**
 * Returns true if the pathname and query string look like a simple query
 * string search request.
 * @param  {String} pathname path of the request
 * @param  {String} search   query string of the request
 * @return {boolean}         true or false
 */
export function looksLikeQueryStringSearch(pathname: string, search: string) {
  return (pathname === '/search' || pathname === '/marty/search') && 'term' in parse(search);
}

export function isNullSearchUrl(location: string | { search: string; pathname: string }) {
  if (typeof location === 'string') {
    location = parsePath(location);
  }
  const queryObject = parse(location.search);
  return (
    (location.pathname === '/search' || location.pathname === '/marty/search') &&
    queryObject.term === '' &&
    (queryObject.department === undefined || queryObject.department === '')
  );
}

export function breakdownNonZso(path: string, search = '') {
  const fullPath = path + search;
  /*
  NONZSO_SEARCH_RE
  Regex from https://github01.zappos.net/SearchTeam/Helios/blob/master/src/main/webapp/WEB-INF/urlrewrite.xml#L224
  0: Full url
  1: Search term
  2: facets
  3: Original term
  4: Term lander
  5: page
  6: sort
  7: si
  8: sy
  9: debug
  10: noEncode
  11: nq
  12: pf_rd_r
  13: pf_rd_p
  14: remaining string
  */
  let response: Record<string, unknown> = { queryParams: {} };
  const urlParams = fullPath.match(NONZSO_SEARCH_RE);
  if (looksLikeQueryStringSearch(path, search)) {
    // just a query string term. some testing against desktop search revealed
    // that, with a search url of this format, other params (i.e. p, page, s,
    // sort) are completely ignored.
    const queryObj = parse(search);
    response[ALL_REDIRECTED_KEY] = queryObj[ALL_REDIRECTED_KEY] === 'true';
    response.term = queryObj.term;
    response.filters = {};
    response.page = 0;
    response.sort = {};
    if (queryObj.pf_rd_p && queryObj.pf_rd_r) {
      response = {
        ...response,
        queryParams: {
          pf_rd_r: queryObj.pf_rd_r,
          pf_rd_p: queryObj.pf_rd_p
        }
      };
    }
  } else if (urlParams) {
    const pageNum = parseInt(urlParams[5] as string, 10);
    response.term = urlParams[1] !== 'null' && urlParams[1] !== 'undefined' ? urlParams[1] : '';
    response.filters = urlParams[2] ? buildFiltersObject(urlParams[2]) : '';
    response.page = pageNum ? pageNum : 0;
    if (urlParams[7] && urlParams[8]) {
      response.si = urlParams[7].split('/');
      response.sy = urlParams[8];
    }
    if (urlParams[12] && urlParams[13]) {
      response = {
        ...response,
        queryParams: {
          pf_rd_r: urlParams[12],
          pf_rd_p: urlParams[13]
        }
      };
    }
    if (urlParams[14]) {
      const remainingParams = parse(urlParams[14]);
      response = { ...response, queryParams: remainingParams };
    }
    response.sort = urlParams[6] ? decodeSort(urlParams[6]) : {};
  }

  return response;
}

/**
 * Return true if two search urls appear to have all the same criteria
 * @param  {string} url1 search url
 * @param  {string} url2 search url
 * @return {boolean}
 */
export function lookLikeSameSearchUrls(url1: string, url2: string) {
  return decodeURIComponent(url1) === decodeURIComponent(url2);
}

/**
 * Determines if you should be redirected to a /search/termlander page
 * based on the new page you're passing
 * @param {object} filters
 * @param {integer} page
 * @return {boolean}
 */

export function isJustTerm(filters: SearchFilters, newPage: number) {
  const { selected, sort, si } = filters;
  const hasSort = Object.keys(sort || {}).length > 0;
  return (
    (newPage === 0 || !newPage) &&
    Object.keys(selected.singleSelects).length === 0 &&
    Object.keys(selected.multiSelects).length === 0 &&
    !hasSort &&
    !si
  );
}

/**
  Organizes filters into single-selects vs multi-selects
  @param {object}
  @return {object} Filters: {singleSelects: {}, multiSelects: {}}
**/
export function organizeSelects(filters: Record<string, string[]>) {
  const selected: SearchFilters['selected'] = {
    singleSelects: {},
    multiSelects: {}
  };

  if (filters) {
    Object.keys(filters).forEach(v => {
      if (SINGLE_SELECT_FILTERS[v as keyof typeof SINGLE_SELECT_FILTERS]) {
        selected.singleSelects[v] = filters[v] as string[];
      } else {
        selected.multiSelects[v] = filters[v] as string[];
      }
    });
  }

  return selected;
}

/**
 * Convert a sort string into an object
 * @param  {string} sortString sort string with "-" delimiting values
 * @return {object}            criteria
 */
export const sortStringToObject = (sortString: string) => {
  const keysAndValues = sortString.split('-').filter(item => item !== '');
  const sortObj: Record<string, string | undefined> = {};
  const numIter = keysAndValues.length / 2;
  for (let i = 0; i < numIter; i++) {
    const index = i * 2;
    sortObj[keysAndValues[index] as string] = keysAndValues[index + 1];
  }
  return sortObj;
};

/**
 * Convert an non-zso location to a normalized search location. This normalized
 * search location can then be handled by fetchFromSearch. This is most useful
 * for converting potentially seo-url search locations to locations
 * understandable by fetchFromSearch.
 * @param  {object} location a location object
 * @return {object}          a new location object
 */
export const normalizeSearchLocation = (location: Location) => {
  location = Object.assign({}, location, {
    pathname: location.pathname.replace(MARTY_URL_PREFIX_RE, '/')
  });
  if (location.pathname !== '/search') {
    const matches = location.pathname.match(SEO_URL_RE);
    if (matches && matches.length > 1) {
      return {
        ...location,
        pathname: '/search',
        search: `?term=${seoTermToHumanTerm(matches[1] || '')}`
      };
    }
  }

  return location;
};

/**
 * Appends new search param object to existing url
 * @param {string} pathname url path
 * @param {object} params new parameters
 */
export const createLocationWithParams = (pathname: string, params: string | appendQuery.Query) => {
  const newPath = decodeURIComponent(appendQuery(pathname, params, { removeNull: true }));
  return newPath;
};

/**
 * Returns whether the given path is a pretty search url path or a slash (/search) path.
 * @param  {string}  path relative pathname to check
 * @return {Boolean}      true if the path is a pretty search or a slash search (/search) path.
 */
export function isPrettySearchOrSlashSearchPath(path: string) {
  return looksLikeSeoUrl(path) || SLASH_SEARCH_RE.test(path);
}

/**
 * Utility function for building a URL friendly search terms.
 * Takes a string, including special characters and converts it into a format that the search stack will handle in the /search/${term} format:
 * @param  {String} path  string of search term
 * @return {String} string of encoded search to be consumed by search API
 * Levi's®  ->  Levi%27s%C2%AE
 */
const encodableCharacters = new RegExp(
  [
    '(?:[\0-\x1F"-&+-}\x7F-\uD7FF\uE000-\uFFFF]|',
    '[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|',
    '(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])'
  ].join(''),
  'g'
);
export function termEncoder(path: string) {
  return path
    .replace(encodableCharacters, encodeURIComponent)
    .replace(/ /g, '%20')
    .replace(/[!'()~*]/g, ch => '%' + ch.charCodeAt(0).toString(16).slice(-2).toUpperCase());
}

export function pruneUrl(urlParam: string, whiteList: unknown[]) {
  const { url = '', query: parsedParams = {} } = parseUrl(urlParam || '');
  Object.keys(parsedParams).forEach(key => {
    parsedParams[key] = (whiteList || []).includes(key) ? parsedParams[key] : undefined; // stringify() will prune away keys with undefined values.
  });

  return [url, stringify(parsedParams)].join('?').replace(/\?$/, '');
}

function areUrlAndParametersEquivalent(url1: string, url2: string, whiteList: unknown[]) {
  return pruneUrl(url1, whiteList) === pruneUrl(url2, whiteList);
}

/**
 * Determines if search should be called on the client side inital visit
 **/

export function shouldSearchServiceBeCalled(location: { pathname: string; search: string }, seoName: string, executedSearchUrl: string) {
  const currentUrl = stripAppRoot(location.pathname + location.search);

  // We should only call for initial products if:
  // (a) They haven't been to search before
  const newToSearch = !executedSearchUrl;
  // (b) They did not click the back button after faceting
  const backFromFaceting = SEO_URL_RE.test(location.pathname) && executedSearchUrl !== location.pathname;
  // (c) They are not returning to the same results
  const differentResults = !areUrlAndParametersEquivalent(executedSearchUrl, currentUrl, SEARCH_URL_WHITELISTED_PARAMS_LIST);
  // (d) Or they are not coming back from the product page to a seo url (ie /red-shoes?oosRedirected=true)
  const prodPageToSeoUrl = !seoName || !SEO_URL_RE.test(currentUrl.replace(OOS_REDIRECT_SUFFIX, ''));

  return newToSearch || backFromFaceting || (differentResults && !isNullSearchUrl(currentUrl) && prodPageToSeoUrl);
}

/**
 * compares query params from url vs query obj
 * @param {String} executedSearchUrl
 * @param {Object} query
 */
export function combineQueryParams(url: string = '', queryParams: Record<string, string>) {
  const queryParamArr = Object.keys(queryParams);
  if (queryParamArr.length > 0) {
    const missingParams: Record<string, string | null> = {};
    const executedQuery = url.match(QUERY_PARAMS_RE_GEN());
    if (executedQuery?.[0]) {
      const executedParams = parse(executedQuery[0]);
      for (const paramIndex in queryParamArr) {
        const param = queryParamArr[paramIndex] as string;
        if (!executedParams[param]) {
          /*
            Site merch occassionally gives us dupe querystring params for pf_rd_p & pf_rd_r.
            Check if get an array instead of a string. If it's an array take the first one.
          */
          if (Array.isArray(queryParams[param])) {
            missingParams[param] = queryParams[param]?.[0] || null;
          } else {
            missingParams[param] = queryParams[param] || null;
          }
        }
      }
      return appendQuery(url, missingParams);
    }
    return appendQuery(url, queryParams);
  }
  return url;
}

function updatePathPage(url: string, page: number) {
  if (page === 0) {
    return url.replace(PAGE_NUMBER_IN_URL_RE, '');
  } else {
    return url.includes('/page') ? url.replace(PAGE_NUMBER_IN_URL_RE, `/page/${page}`) : url.replace(EXTRACT_URL_SEGMENTS_RE, `$1/page/${page}$2$3`);
  }
}

export function makePageLink(filters: SearchFilters, currentLocation: string | null, targetPage: number, hasSeoTermPages = false) {
  const { executedSearchUrl } = filters || {};
  if (executedSearchUrl) {
    if (hasSeoTermPages && isJustTerm(filters, targetPage)) {
      if (filters.term) {
        return termToSeoPath(filters.term);
      }
      return '/search?term=';
    }
    if (ZSO_URL_RE.test(executedSearchUrl)) {
      return createLocationWithParams(executedSearchUrl, {
        p: targetPage > 0 ? targetPage.toString() : null
      });
    } else {
      return updatePathPage(executedSearchUrl, targetPage);
    }
  } else if (currentLocation && typeof currentLocation === 'string') {
    return updatePathPage(currentLocation, targetPage);
  }
  return null;
}

/**
 * Converts current filters into an opal expect request
 * excludeFilter will remove a filter from the returned object
 * @param {object} filters
 * @param {string} excludeFilter
 * @return {Object or null}
 */

export function formatSavedFilters(
  { selected: { singleSelects, multiSelects }, savedsizes }: SearchFilters,
  excludeFilter: string | null = null,
  wasSavedCookie: boolean | null = null
) {
  const hasGender = singleSelects?.['txAttrFacet_Gender']?.length === 1;
  const hasZc1 = singleSelects?.['zc1'] && singleSelects.zc1.includes('Shoes');
  if (hasGender && hasZc1) {
    const { txAttrFacet_Gender: genderArr, ...allCategories } = singleSelects;
    const gender = genderArr?.[0];

    const sizes = Object.entries(savedsizes?.filters || {}).reduce(
      (sizes, [key, values]) => {
        const shouldExcludeFilter = !excludeFilter || excludeFilter !== key;
        if ((shouldExcludeFilter || wasSavedCookie) && multiSelects[key]) {
          multiSelects[key]?.forEach(filterValue => {
            if (!sizes.some(v => v.value === filterValue)) {
              sizes.push({ name: key, value: filterValue });
            }
          });
        }

        if ((wasSavedCookie || !multiSelects[key]) && values.length) {
          values.forEach(filterValue => {
            if (!sizes.some(v => v.value === filterValue)) {
              sizes.push({ name: key, value: filterValue });
            }
          });
        }

        return sizes;
      },
      [] as { name: string; value: string }[]
    );

    const zetaCategories = Object.entries(allCategories).reduce(
      (zetaCategories, [key, value]) => {
        zetaCategories[key] = value[0];
        return zetaCategories;
      },
      {} as Record<string, string | undefined>
    );

    return { gender, zetaCategories, sizes };
  }
  return null;
}

/**
 * Compares two arrays if they have the same values
 * @param {array}
 * @param {array}
 * @return {boolean}
 */
export function savedValuesMatch(arr1 = [], arr2 = []) {
  const sorted1 = [...arr1].sort();
  const sorted2 = [...arr2].sort();
  return sorted1.length === sorted2.length && sorted1.every((item, index) => item === sorted2[index]);
}

interface MicrosoftPixelData extends CartPixelServerItems {
  originalPrice?: number;
}

export function formatMicrosoftPixelData({ filters = {}, results = [] }: { filters?: Partial<SearchFilters>; results: MicrosoftPixelData[] }) {
  const { page = 0, sort = {} } = filters;
  const formatPrice = (price: string | number) => {
    if (typeof price === 'string') {
      return priceToFloat(price);
    }
    return price;
  };

  const resultsItems = results.map(item => {
    const newItem: Record<string, unknown> = {
      id: item.styleId || '',
      price: item.price ? formatPrice(item.price) : '',
      brand: escape(item.brandName) || '',
      categories: [],
      available: true
    };

    if (item.originalPrice && item.originalPrice !== item.price) {
      newItem['on_sale'] = true;
      newItem.msrp = item.originalPrice;
    }

    return newItem;
  });

  let formattedFilters: { name: string; operator: string; value?: string }[] = [];
  if (filters.selected) {
    const singleSelects = Object.keys(filters.selected.singleSelects).map(v => ({
      name: v,
      operator: 'in',
      value: filters.selected?.singleSelects?.[v]?.join(', ')
    }));
    const multiSelects = Object.keys(filters.selected.multiSelects).map(v => ({
      name: v,
      operator: 'in',
      value: filters.selected?.multiSelects?.[v]?.join(', ')
    }));
    formattedFilters = [...singleSelects, ...multiSelects];
  }

  let sortVal = ['relevance/desc'];
  if (Object.keys(sort)?.length) {
    sortVal = Object.keys(sort).map(v => `${v}/${sort[v]}`);
  }

  return {
    results: resultsItems,
    results_set: page + 1,
    filters: formattedFilters,
    sort_type: sortVal
  };
}

// We create, store, and set a unique id on the url so we can link the users from
// their session on one store to another.
export const crossSiteSellingUniqueIdentifier = guid();

/**
 * Get search term from executed search url
 * @param {string} url
 * @return {string} searchTerm
 */
export function getSearchTermFromUrl(url: string) {
  if (!url) {
    return '';
  }
  const urlSplits = url.split('/') || [];
  if (urlSplits.length < 2) {
    return '';
  }
  return replaceAll(urlSplits[1] || '', '-', ' ');
}

export function isEmptyZsoUrl(url: string) {
  if (!url) {
    return false;
  }

  return url.includes('/.zso');
}

export const getSearchFacetDropdownData = () => [
  { display: 'All', value: '' },
  { display: SHOE_KEY, value: SHOES, searchValue: 'Shoes' },
  { parent: SHOE_KEY, display: 'Womens', value: `${SHOES}/${WOMEN}`, searchValue: 'Shoes/Women' },
  { parent: SHOE_KEY, display: 'Mens', value: `${SHOES}/${MEN}`, searchValue: 'Shoes/Men' },
  { parent: SHOE_KEY, display: 'Boys', value: `${SHOES}/${BOYS}`, searchValue: 'Shoes/Boys' },
  { parent: SHOE_KEY, display: 'Girls', value: `${SHOES}/${GIRLS}`, searchValue: 'Shoes/Girls' },
  { display: CLOTHING_KEY, value: CLOTHING, searchValue: 'Clothing' },
  { parent: CLOTHING_KEY, display: 'Womens', value: `${CLOTHING}/${WOMEN}`, searchValue: 'Clothing/Women' },
  { parent: CLOTHING_KEY, display: 'Mens', value: `${CLOTHING}/${MEN}`, searchValue: 'Clothing/Men' },
  { parent: CLOTHING_KEY, display: 'Boys', value: `${CLOTHING}/${BOYS}`, searchValue: 'Clothing/Boys' },
  { parent: CLOTHING_KEY, display: 'Girls', value: `${CLOTHING}/${GIRLS}`, searchValue: 'Clothing/Girls' },
  { display: ACCESSORIES_KEY, value: ACCESSORIES, searchValue: 'Accessories' },
  {
    parent: ACCESSORIES_KEY,
    display: 'Bags & Handbags',
    value: `${ACCESSORIES}/filter/zc1/%22Bags%22/zc2/%22Handbags%22`,
    suppressParentDisplay: true,
    searchValue: 'Bags/Handbags'
  },
  {
    parent: ACCESSORIES_KEY,
    display: 'Backpacks',
    value: `${ACCESSORIES}/filter/zc1/%22Bags%22`,
    suppressParentDisplay: true,
    searchValue: 'Bags'
  },
  {
    parent: ACCESSORIES_KEY,
    display: 'Sunglasses & Eyewear',
    value: `${ACCESSORIES}/filter/zc1/%22Eyewear%22`,
    suppressParentDisplay: true,
    searchValue: 'Eyewear'
  },
  {
    parent: ACCESSORIES_KEY,
    display: 'Tech Accessories',
    value: `${ACCESSORIES}/filter/zc1/%22Electronics%22`,
    suppressParentDisplay: true,
    searchValue: 'Electronics'
  },
  { parent: ACCESSORIES_KEY, display: 'Hats', value: `${ACCESSORIES}/zc2/%22Hats%22`, suppressParentDisplay: true, searchValue: 'Accessories/Hats' },
  {
    parent: ACCESSORIES_KEY,
    display: 'Gloves',
    value: `${ACCESSORIES}/zc2/%22Gloves%22`,
    suppressParentDisplay: true,
    searchValue: 'Accessories/Gloves'
  },
  { display: 'Toys & Games', value: TOYS, searchValue: 'Toys and Games' },
  { display: ADAPTIVE_KEY, value: `filter/${ADAPTIVE}`, searchValue: 'Adaptive' },
  { parent: ADAPTIVE_KEY, display: 'Womens', value: `filter/${ADAPTIVE}/filter/${WOMEN}/${ADAPTIVE}`, searchValue: 'Adaptive/Women' },
  { parent: ADAPTIVE_KEY, display: 'Mens', value: `filter/${ADAPTIVE}/filter/${MEN}/${ADAPTIVE}`, searchValue: 'Adaptive/Men' },
  { parent: ADAPTIVE_KEY, display: 'Boys', value: `filter/${ADAPTIVE}/filter/${BOYS}/${ADAPTIVE}`, searchValue: 'Adaptive/Boys' },
  { parent: ADAPTIVE_KEY, display: 'Girls', value: `filter/${ADAPTIVE}/filter/${GIRLS}/${ADAPTIVE}`, searchValue: 'Adaptive/Girls' }
];

// returns the dropdown filter based off of the search response filters
export const findFilter = (filters: Record<string, string>) => {
  const dropdownData = getSearchFacetDropdownData();

  if (!filters || Object.keys(filters).length === 0) {
    return dropdownData[0];
  }

  const { zc1 = '', zc2 = '' } = filters;
  const gender = filters.txAttrFacet_Gender ?? '';
  const verticals = filters.txAttrFacet_Verticals ? filters.txAttrFacet_Verticals[0] : '';
  // Bags/Handbags ( zc1/zc2 )
  // Shoes/Women ( zc1 / txAttrFacet_Gender )
  // Adaptive/Women ( txAttrFacet_Verticals / txAttrFacet_Gender )
  const filterDisplay = `${verticals}${zc1}${zc2 ? `/${zc2}` : ''}${gender ? `/${gender}` : ''}`;

  return dropdownData.find(({ searchValue }) => searchValue === filterDisplay);
};

export const strip = (term: string) => termEncoder(stripSpecialCharsConsolidateWhitespace(term));

// append a 'allRedirected' flag to the redirect url for search to identify to show redirected verbiage
export const searchRedirectedNoResultsUrl = (term: string) => `/search?${ALL_REDIRECTED_KEY}=true&term=${term}`;

export const getSearchFilterSelectedValue = (): { display: unknown; parent: unknown; value: unknown } => loadFromLocalStorage(SEARCH_FILTER_KEY);

export const saveSearchFilterSelectedValue = (dropdownData: unknown) => {
  saveToLocalStorage(SEARCH_FILTER_KEY, dropdownData);
};

export const getAllFilter = () => getSearchFacetDropdownData()[0];

export const resetSearchFilter = () => saveSearchFilterSelectedValue(getAllFilter());

export const getRedirectedVerbiage = (
  { value: currentFilterValue }: { value?: string } = {},
  isResponseFilter = false,
  selectedFilter = getSearchFilterSelectedValue()
) => {
  const { display, parent } = selectedFilter || {};
  if (!isFilterServerChanged(currentFilterValue, isResponseFilter, selectedFilter)) {
    return null;
  }
  return `${parent ? `${parent} ` : ''}${display}`;
};

export const isFilterServerChanged = (currentFilterValue?: string, isResponseFilter = false, selectedFilter = getSearchFilterSelectedValue()) => {
  const { value } = selectedFilter || {};
  if (!value || currentFilterValue === value || !isResponseFilter) {
    return false;
  }
  return true;
};

// Janus Query - This code is a bit of a hack to create a Janus DB query and send it along the Janus recos request.
export const getInlineAdSearchFiltersQueryParams = (filters: Record<string, string[]>) => {
  const query = [];

  for (const [key, value] of Object.entries(filters)) {
    if (janusFilterMap.has(key)) {
      query.push(`${janusFilterMap.get(key)}${encodeURIComponent(getQueryOperator(value))}${encodeURIComponent(getQueryValue(value))}`);
    }
  }

  return query.join(' AND ');
};

const getQueryOperator = (value: string[]) => (value.length > 1 ? ' IN ' : ' = ');

const getQueryValue = (value: string[]) => {
  if (value.length === 1) {
    const val = value[0];
    return `"${val}"`;
  }
  // ["1", "2"], %5B = `[`, %5D = ']'
  return `%5B${value.map(value => `"${value}"`).toString()}%5D`;
};

export const getQueryStringParams = (string: string) => {
  const url = new URL(string, FOO_URL);
  const urlStringWithoutParams = removeUrlParams(url.toString(), SEARCH_PARAMS_TO_REMOVE);
  const newUrl = new URL(urlStringWithoutParams);

  const params = new URLSearchParams(newUrl.search).toString();
  if (params) {
    return params;
  } else {
    return null;
  }
};

export const createClearAllFiltersQuery = (filters: { term: string; sort: SortOptionProps }, sortOptions: SortOptionProps[]) => {
  const { term, sort } = filters;
  const termParam = term?.replace(' ', '+');
  const { value: sortParam } = getSelectedSortValue(sortOptions, sort) || {};

  const url = new URL('/search', FOO_URL);

  if (term) {
    url.pathname = `/${term.replace(' ', '-')}/.zso`;
    url.searchParams.set('t', termParam);
    url.searchParams.set('s', sortParam?.replaceAll('-', '/') || '');
  } else if (!term && sortParam) {
    url.pathname = '/null/.zso';
    url.searchParams.set('s', sortParam.replaceAll('-', '/'));
  }

  return url.toString();
};

export const removeUrlParams = (url: string, paramsArray: string[]) => {
  const newUrl = new URL(url);
  for (const param of paramsArray) {
    newUrl.searchParams.delete(param);
  }

  return newUrl.toString();
};

export const updateSortParamOfUrl = (string: string, selectedSortFilter: string) => {
  const url = new URL(string, FOO_URL);

  if (selectedSortFilter) {
    url.searchParams.set('s', selectedSortFilter.replaceAll('-', '/'));
    return removeUrlParams(url.toString(), SEARCH_PARAMS_TO_REMOVE);
  }

  return url.toString();
};
