import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/common/Button';
import { redirectTo } from 'actions/redirect';
import useMartyContext from 'hooks/useMartyContext';
import {
  MAX_ALLOWED_IMAGE_SIZE_BYTES,
  MAX_ALLOWED_IMAGE_SIZE_IN_MBS,
  MOBILE_UPLOAD_PHOTO_DESCRIPTION,
  SEARCH_BY_PHOTO
} from 'constants/visualSearch';
import { receiveVisualSearchInputImage } from 'actions/visualSearch';
import css from 'components/VisualSearch/SearchByPhoto/searchByPhoto.scss';
import MelodyModal from 'components/common/MelodyModal';
import UtilityStrokeCameraMediumIcon from 'tailwind/components/Icons/UtilityStrokeCameraMediumIcon';
import UtilityStrokeCameraLargeIcon from 'tailwind/components/Icons/UtilityStrokeCameraLargeIcon';
import useWindowSize from 'hooks/useWindowSize';
import { isDesktopViewport } from 'utils/viewportUtil';
import UtilityStrokeErrorFilledSmallIcon from 'tailwind/components/Icons/UtilityStrokeErrorFilledSmallIcon';
import { track } from 'apis/amethyst';
import { evVisualSearchCameraClick, evVisualSearchCancelImageUpload, evVisualSearchUploadImage } from 'events/visualSearch';
import { AboutSearchByPhoto } from 'components/VisualSearch/VisualSearchResults/AboutSearchByPhoto/AboutSearchByPhoto';
import UtilityStrokeInfoOutlineMediumIcon from 'tailwind/components/Icons/UtilityStrokeInfoOutlineMediumIcon';

interface SearchByPhotoProps {
  closeSearchByPhotoModal: () => void;
}

const SearchByPhoto = ({ closeSearchByPhotoModal }: SearchByPhotoProps) => {
  const dispatch = useDispatch();

  const {
    marketplace: {
      reviews: { allowedImageTypes }
    }
  } = useMartyContext();

  const { width: windowWidth = 0 } = useWindowSize();
  const isDesktop = isDesktopViewport(windowWidth);

  const imageUploadButtonRef = useRef<HTMLInputElement | null>(null);
  const dropRef = useRef<HTMLDivElement | null>(null);
  const [inputImage, setInputImage] = useState<File | null>(null);
  const [isFileSizeExceed, setIsFileSizeExceed] = useState<boolean>(false);
  const [isAboutSearchByPhotoOpen, setIsAboutSearchByPhotoOpen] = useState<boolean>(false);

  const uploadFileClick = () => {
    imageUploadButtonRef.current?.click();
    setInputImage(null);
  };

  useEffect(() => {
    track(() => [evVisualSearchCameraClick, {}]);
  }, []);

  const checkSize = (file: File) => {
    const { size } = file;
    const isSizeGreaterThanMaxSize = size > MAX_ALLOWED_IMAGE_SIZE_BYTES;
    setIsFileSizeExceed(isSizeGreaterThanMaxSize);
    track(() => [
      evVisualSearchUploadImage,
      {
        fileSize: size,
        isFileLimitExceed: isSizeGreaterThanMaxSize
      }
    ]);
    return !isSizeGreaterThanMaxSize;
  };

  const onImageFileUploadChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files?.length) {
      const inputFile = files[0];
      if (inputFile && checkSize(inputFile)) {
        setInputImage(inputFile);
      }
    }
  };

  useEffect(() => {
    dropRef.current?.addEventListener('dragover', handleDragOver);
    dropRef.current?.addEventListener('drop', handleDrop);

    return () => {
      dropRef.current?.removeEventListener('dragover', handleDragOver);
      dropRef.current?.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer!;
    if (files && files.length) {
      // todo - show alert for multiple files upload & invalid format
      const fileType = files[0]!.type.split('/')[1]; // 'image/png' => 'png'
      if (files.length > 1 || !allowedImageTypes.includes(fileType!)) {
        return;
      }

      const inputFile = files[0];
      if (inputFile && checkSize(inputFile)) {
        setInputImage(inputFile);
      }
    }
  };

  useEffect(() => {
    if (inputImage) {
      dispatch(receiveVisualSearchInputImage(inputImage));
      dispatch(redirectTo('/searchByImage'));
      closeSearchByPhotoModal();
    }
  }, [inputImage]);

  const imageUploadInput = () => (
    <input
      type="file"
      hidden={true}
      onChange={onImageFileUploadChange}
      onClick={e => {
        (e.target as HTMLInputElement).value = '';
      }} // allow selecting same file again
      accept={allowedImageTypes}
      ref={imageUploadButtonRef}
      multiple={false}
    />
  );

  const modalClose = () => {
    // track cancel image upload operation. By default modal will also be closed on redirection to '/visualSearch'
    !inputImage && track(() => [evVisualSearchCancelImageUpload, {}]);
  };

  const closeAboutSearchByPhotoModal = () => {
    setIsAboutSearchByPhotoOpen(false);
  };

  const ModalHeading = () => (
    <div className={css.modalHeading}>
      {SEARCH_BY_PHOTO}
      <button className={css.infoButton} type="button">
        <UtilityStrokeInfoOutlineMediumIcon size={24} onClick={() => setIsAboutSearchByPhotoOpen(true)} />
      </button>
    </div>
  );

  return (
    <>
      {isAboutSearchByPhotoOpen && <AboutSearchByPhoto closeAboutSearchByPhotoModal={closeAboutSearchByPhotoModal} />}
      <MelodyModal
        isOpen={true}
        className={css.modal}
        overlayClassName={css.modalOverlay}
        onRequestClose={closeSearchByPhotoModal}
        heading={<ModalHeading />}
        contentLabel={SEARCH_BY_PHOTO}
        onAfterClose={modalClose}
      >
        {isDesktop ? (
          <div className={css.container}>
            <div className={css.imageContainer} ref={dropRef}>
              <span className={css.imageUploadContainer}>
                <UtilityStrokeCameraLargeIcon size={64} />
                <span>
                  {/* todo add back once we are good with drag & drop UX */}
                  {/*Drag an image here or*/}
                  {imageUploadInput()}
                  <Button variant="outlined" onClick={uploadFileClick}>
                    Upload A Photo
                  </Button>
                </span>
              </span>
            </div>
          </div>
        ) : (
          <div className={css.mobileContainer}>
            <Button leadIcon={<UtilityStrokeCameraMediumIcon size={24} />} height="small" variant="filled" size="small" onClick={uploadFileClick}>
              {MOBILE_UPLOAD_PHOTO_DESCRIPTION}
            </Button>
            {imageUploadInput()}
            {isFileSizeExceed ? (
              <p className={css.uploadAlert}>
                <UtilityStrokeErrorFilledSmallIcon size={16} />
                {`Upload is too large (max ${MAX_ALLOWED_IMAGE_SIZE_IN_MBS} MB).`}
              </p>
            ) : (
              <p>&nbsp;</p>
            )}
          </div>
        )}
      </MelodyModal>
    </>
  );
};

export default SearchByPhoto;
