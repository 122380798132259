export const ZAPPOS_MERCHANTID = 'AMWRKCC3EWKYM';
export const SIXPM_MERCHANTID = 'A1DAQHZV8E3DTX';
export const VRSNL_MERCHANTID = 'A30HUN4C7FA8PG';

export const merchantIdHostMapping: Partial<Record<string, string>> = {
  [ZAPPOS_MERCHANTID]: 'zappos',
  [SIXPM_MERCHANTID]: '6pm',
  [VRSNL_MERCHANTID]: 'vrsnl.zappos'
};

export const CROSS_SITE_DOMAIN_MAP = {
  vrsnl: 'VRSNL'
};

export const crossSiteMerchantIdDomainMapping: Partial<Record<string, string>> = {
  [VRSNL_MERCHANTID]: CROSS_SITE_DOMAIN_MAP.vrsnl
};
