import type { ProductWithRelationsFromCalypso } from 'types/calypso';
import type { BoundingBoxApiResponse, BoundingBoxData } from 'components/VisualSearch/VisualSearchResults/visualSearchResults.types';

/*
  adding product's own styleId at the first place, to
  - make default styleId available for the related styles
  */
export const updatedProductsWithOwnRelatedStyle = (products: ProductWithRelationsFromCalypso[]) =>
  products.map(product => {
    if (product.relatedStyles) {
      const deepCopy = JSON.parse(JSON.stringify(product));
      delete deepCopy.relatedStyles;
      const productCopy = { ...product };
      productCopy.relatedStyles = [deepCopy, ...product.relatedStyles];
      return productCopy;
    }
    return product;
  });

/*
  olympus api - /v2/Search/null/si/, getting products using styleIDs
  - api sends product details in random order. This function reorders the products the way styleIds were received.
  - api sends unique products
  - api may not have related styles
  - api may send {styleId: x, relatedStyles:{y,z}} on sending styleIds {x, y}
  */
export const reorderProductsByStyleIds = (products: ProductWithRelationsFromCalypso[], styleIds: string[]) => {
  const filteredProducts: ProductWithRelationsFromCalypso[] = [];
  // we want to add the styleId which is first in received styleIds for all products
  // so, for each product -> check for all styleIds, if styleId matches any of the related styles, just move it to front
  products.forEach(product => {
    styleIds.some(inputStyleId => {
      let flag = false;
      if (product.relatedStyles) {
        product.relatedStyles.some(style => {
          if (style.styleId === inputStyleId && product.relatedStyles) {
            const deepCopy: ProductWithRelationsFromCalypso = JSON.parse(JSON.stringify(style));
            // removing the existing related style before moving it to front
            const productCopy = product.relatedStyles?.filter(style => style.styleId !== inputStyleId);
            // refreshing the default details with the desired style
            // adding style to front
            product = { ...deepCopy, relatedStyles: [deepCopy, ...productCopy] };
            filteredProducts.push(product);
            flag = true;
            // stop .some() loop on matching styleId
            return true;
          }
          return false;
        });
      } else {
        filteredProducts.push(product);
        return true;
      }
      // stop .some() loop on matching the current product
      return flag;
    });
  });

  // sort the products according to styleIds received
  return [...filteredProducts].sort((a, b) => {
    const styleIdA = a.relatedStyles?.[0]?.styleId || a.styleId;
    const styleIdB = b.relatedStyles?.[0]?.styleId || b.styleId;

    return styleIds.indexOf(styleIdA) - styleIds.indexOf(styleIdB);
  });
};

export const convertApiResponseToBoundingBoxDataMap = (response: BoundingBoxApiResponse): Map<string, BoundingBoxData> => {
  const boundingBoxDataMap = new Map<string, BoundingBoxData>();

  response.result
    .filter(result => result.properties && result.properties.boundingBox)
    .forEach(result => {
      const boundingBoxData = {
        boundingBox: {
          bbTx: result.properties.boundingBox.bbTx,
          bbTy: result.properties.boundingBox.bbTy,
          bbTrx: result.properties.boundingBox.bbTrx,
          bbTry: result.properties.boundingBox.bbTry
        },
        boundingBoxId: result.properties.boundingBoxId,
        score: result.properties.score,
        productType: result.properties.productAttributes.productType
      };

      boundingBoxDataMap.set(result.properties.boundingBoxId, boundingBoxData);
    });

  return boundingBoxDataMap;
};

export const normalizeBoundingBoxes = (
  boundingBoxes: Map<string, BoundingBoxData>,
  imageWidth: number | null,
  imageHeight: number | null
): BoundingBoxData[] => {
  if (!imageHeight || !imageWidth) return [];

  const normalizedBoundingBoxes: BoundingBoxData[] = [];
  // Move the bounding box with the highest score to the first position
  let highestScoreIndex = -1;
  let highestScore = -Infinity;

  for (const [boundingBoxId, boundingBoxData] of boundingBoxes) {
    const { bbTx, bbTy, bbTrx, bbTry } = boundingBoxData.boundingBox;

    const bbTx1 = (100 * bbTx) / imageWidth;
    const bbTy1 = (100 * bbTy) / imageHeight;
    const bbTrx1 = (100 * bbTrx) / imageWidth;
    const bbTry1 = (100 * bbTry) / imageHeight;

    if (
      bbTx1 > 100 ||
      bbTy1 > 100 ||
      bbTrx1 > 100 ||
      bbTry1 > 100 ||
      bbTx1 < 0 ||
      bbTy1 < 0 ||
      bbTrx1 < 0 ||
      bbTry1 < 0 ||
      bbTry1 < bbTy1 ||
      bbTrx1 < bbTx1
    ) {
      break;
    }

    const { score } = boundingBoxData;
    if (score > highestScore) {
      highestScore = score;
      highestScoreIndex = normalizedBoundingBoxes.length;
    }

    const normalizedBoundingBox = {
      boundingBox: {
        bbTx: bbTx1,
        bbTy: bbTy1,
        bbTrx: bbTrx1,
        bbTry: bbTry1
      },
      boundingBoxId,
      score,
      productType: boundingBoxData.productType
    };
    normalizedBoundingBoxes.push(normalizedBoundingBox);
  }

  if (highestScoreIndex !== -1) {
    const [highestScoreBox] = normalizedBoundingBoxes.splice(highestScoreIndex, 1);
    if (highestScoreBox) {
      normalizedBoundingBoxes.unshift(highestScoreBox);
    }
  }

  return normalizedBoundingBoxes;
};

export const polygonFromBoundingBox = (normalizedBB: BoundingBoxData[], index: number) => {
  const bbData = normalizedBB[index];
  const boundingBox = bbData?.boundingBox;

  if (!boundingBox) {
    return '0% 0% 0% 0%';
  }

  const { bbTx, bbTy, bbTrx, bbTry } = boundingBox;
  // top right bottom left
  return `${bbTy}% ${100 - bbTrx}% ${100 - bbTry}% ${bbTx}%`;
};
