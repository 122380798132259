import queryString from 'query-string';
import { replace } from 'connected-react-router';

import timedFetch from 'middleware/timedFetch';
import { VRSNL_MERCHANTID } from 'constants/crossSiteMerchantIdMapping';
import cloudCatalogUsingMerchantId from 'helpers/cloudCatalogUsingMerchantId';
import {
  ADD_OOS_ITEM,
  ADD_TO_HEART_ID_LIST,
  CLEAR_HEART_LIST,
  CLEAR_HEARTS,
  DELETE_IMAGE,
  DELETE_INFLUENCER_COLLECTION,
  NEW_COLLECTION_PRODUCT,
  RECEIVE_ALL_INFLUENCER_COLLECTIONS,
  RECEIVE_ALL_LISTS,
  RECEIVE_COLLECTION_PRODUCT_DETAIL,
  RECEIVE_HEART_COUNTS,
  RECEIVE_HEARTS,
  RECEIVE_HEARTS_IDS,
  RECEIVE_LIST_HEARTS,
  RECEIVE_LIST_HEARTS_PUBLISHED,
  RECEIVE_LIST_INFO,
  RECEIVE_SPECIFIC_ITEMID_LISTS,
  REMOVE_FROM_HEART_ID_LIST,
  REMOVE_HEARTS,
  REMOVE_NEW_COLLECTION_PRODUCT,
  REQUEST_ALL_INFLUENCER_COLLECTIONS,
  REQUEST_ALL_INFLUENCER_COLLECTIONS_FAILED,
  SET_DOC_META_COLLECTION,
  SET_NEW_COLLECTION_NAME,
  SHARE_COLLECTION,
  TOGGLE_COLLECTION_PUBLISHED_STATUS,
  TOGGLE_HEARTING_LOGIN_MODAL,
  TOGGLE_INFLUENCER_COLLECTION_VISIBILITY,
  UPDATE_LIST
} from 'constants/reduxActions';
import { CLOUDCAT_MAX_HEART_IDS_PER_CALL } from 'constants/appConstants';
import { ERROR_CUSTOMER_NOT_RECOGNIZED, FAVORITES_PATH_PREFIX, HEART_DEFAULT_LIST_ID, HEART_DEFAULT_LIST_TYPE } from 'constants/apis';
import { likeCounts, productBundle } from 'apis/cloudcatalog';
import { track } from 'apis/amethyst';
import { PRODUCT_ASIN } from 'common/regex';
import { setInfluencerCollectionContentEmpty } from 'actions/influencer/influencerContent';
import { changeQuantity, showCartModal } from 'actions/cart';
import {
  addSubItemId,
  DELETE_COLLECTION_LIST,
  evAddToCollections,
  evCreateCollectionClick,
  evRemoveFromCollections,
  SHARE_COLLECTION_LIST
} from 'events/favorites';
import { INFLUENCER_COLLECTION_IMAGE_LAYOUT, INFLUENCER_HUB_PAGE } from 'constants/influencerPages';
import { COLLECTIONS_LIST_PAGE } from 'constants/amethystPageTypes';
import { COLLECTION_TYPE } from 'constants/amethystEnums';
import { getAmethystPageType, trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { authenticationErrorCatchHandlerFactory, err } from 'actions/errors';
import { translateCartError } from 'apis/mafia';
import {
  addToList,
  createList,
  deleteImage,
  deleteList,
  getAllInfluencerCollections,
  getAllLists,
  getHeartList,
  getInfluencerPublishedCollections,
  getItemSubsetOnLists,
  getListInfo,
  getListOfIds,
  makePrivate,
  makePublic,
  publishInfluencerCollection,
  rearrangeItems,
  removeFromList,
  shareList,
  unpublishInfluencerCollection,
  updateList,
  uploadImage
} from 'apis/account';
import { prependAppRoot } from 'history/AppRootUtils';
import {
  ERROR_NOT_AUTHENTICATED,
  fetchApiNotAuthenticatedMiddleware,
  fetchErrorMiddleware,
  fetchErrorMiddlewareAllowedErrors,
  fetchErrorMiddlewareMaybeJson,
  fetchErrorMiddlewareMultiResponses
} from 'middleware/fetchErrorMiddleware';
import { setSessionCookies } from 'actions/session';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import marketplace from 'cfg/marketplace.json';
import { trackError } from 'helpers/ErrorUtils';
import { selectAccountConfig } from 'selectors/environment';
import { productNotFound } from 'actions/productdetail/productNotFound';
const { hasHearting } = marketplace;

export function receiveHeartIdList(heartsStyleIds) {
  return {
    type: RECEIVE_HEARTS_IDS,
    heartsStyleIds
  };
}

export function removeHeartFromList(styleId) {
  return {
    type: REMOVE_FROM_HEART_ID_LIST,
    styleId
  };
}

export function addHeartToList(styleId) {
  return {
    type: ADD_TO_HEART_ID_LIST,
    styleId
  };
}

export function receiveHeartList(heartList, concat) {
  const { items, nextPageToken = null } = heartList;

  return {
    type: RECEIVE_HEARTS,
    hearts: items,
    nextPageToken,
    concat
  };
}

export function clearHearts() {
  return {
    type: CLEAR_HEARTS
  };
}

export function clearHeartList() {
  return {
    type: CLEAR_HEART_LIST
  };
}

export function receiveCollectionList(heartList, listId, concat) {
  const { items, nextPageToken = null } = heartList;

  return {
    type: RECEIVE_LIST_HEARTS,
    listId,
    hearts: items,
    nextPageToken,
    concat
  };
}

export function receiveCollectionProductDetail(productDetail, colorId) {
  return {
    type: RECEIVE_COLLECTION_PRODUCT_DETAIL,
    product: { detail: productDetail },
    colorId
  };
}

export function addOosItem({ itemId }) {
  return {
    type: ADD_OOS_ITEM,
    itemId
  };
}

export function receiveHeartCounts(heartsList) {
  return {
    type: RECEIVE_HEART_COUNTS,
    heartsList
  };
}

export function receiveListInfo(list) {
  return {
    type: RECEIVE_LIST_INFO,
    list
  };
}

export function receiveAllLists(listsObject, concat) {
  const { lists, nextPageToken } = listsObject;
  return {
    type: RECEIVE_ALL_LISTS,
    concat,
    lists,
    collectionNextPageToken: nextPageToken
  };
}

export function receiveSpecificItemIdLists(itemIdLists) {
  return {
    type: RECEIVE_SPECIFIC_ITEMID_LISTS,
    itemIdLists
  };
}

export function changeInfluencerCollectionVisibility(listId, toPublic) {
  return {
    type: TOGGLE_INFLUENCER_COLLECTION_VISIBILITY,
    listId,
    toPublic
  };
}

export function updateListData(list) {
  return {
    type: UPDATE_LIST,
    list
  };
}

export function removeImage(listId) {
  return {
    type: DELETE_IMAGE,
    listId
  };
}

export function removeHearts(itemIdsToRemove) {
  return dispatch => {
    dispatch({
      type: REMOVE_HEARTS,
      itemIdsToRemove
    });
  };
}

export function toggleCollectionPublishedStatus(listId, setPublishedStatus) {
  return {
    type: TOGGLE_COLLECTION_PUBLISHED_STATUS,
    listId,
    setPublishedStatus
  };
}

export function receivePublishedCollectionList(heartList, concat, shareToken) {
  const { items, nextPageToken = null } = heartList;

  return {
    type: RECEIVE_LIST_HEARTS_PUBLISHED,
    hearts: items,
    nextPageToken,
    concat,
    shareToken
  };
}

export function receiveAllInfluencerCollections(allCollections, overrideCollections) {
  const { lists } = allCollections;
  return {
    type: RECEIVE_ALL_INFLUENCER_COLLECTIONS,
    lists,
    overrideCollections
  };
}

export function requestAllInfluencerCollections() {
  return {
    type: REQUEST_ALL_INFLUENCER_COLLECTIONS
  };
}

export function requestAllInfluencerCollectionsFailed() {
  return {
    type: REQUEST_ALL_INFLUENCER_COLLECTIONS_FAILED
  };
}

export function setNewCollectionName(listName) {
  return {
    type: SET_NEW_COLLECTION_NAME,
    listName
  };
}

export function removeCollection(listTypeId) {
  return {
    type: DELETE_INFLUENCER_COLLECTION,
    listId: listTypeId
  };
}

export function shareCollection(listId) {
  return {
    type: SHARE_COLLECTION,
    listId
  };
}

export function addProductToNewCollection(product) {
  return {
    type: NEW_COLLECTION_PRODUCT,
    product
  };
}

export function removeProductFromNewCollection() {
  return {
    type: REMOVE_NEW_COLLECTION_PRODUCT
  };
}

export function modifyList(list, call = updateList) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    const { listId } = list;
    return call(accountConfig, list)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddlewareMaybeJson)
      .then(resp => {
        dispatch(updateListData(list));
        return resp;
      })
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(`${FAVORITES_PATH_PREFIX}/${listId}`, location)));
  };
}

export function rearrangeList(listId, list, call = rearrangeItems) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      router: { location }
    } = getState();
    const accountConfig = selectAccountConfig(state);

    return call(accountConfig, { listId, list })
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(resp => {
        if (resp.status !== 200) {
          // do nothing if reordering failed
          return;
        }
        dispatch(fetchHeartList({ listId }));
      })
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(`${FAVORITES_PATH_PREFIX}/${listId}`, location)));
  };
}

export function uploadImageData(imageData, call = uploadImage) {
  return (dispatch, getState) => {
    const accountConfig = selectAccountConfig(getState());

    return call(accountConfig, imageData)
      .then(fetchErrorMiddlewareMaybeJson)
      .catch(e => trackError('NON-FATAL', 'Could not upload image details.', e));
  };
}

export function deleteCollectionImage({ listId, imageLocation } = {}, call = deleteImage) {
  return (dispatch, getState) => {
    const accountConfig = selectAccountConfig(getState());

    return call(accountConfig, { listId, type: imageLocation })
      .then(fetchErrorMiddlewareMaybeJson)
      .then(() => {
        dispatch(removeImage(listId));
      })
      .catch(e => trackError('NON-FATAL', 'Could not delete image details.', e));
  };
}

export function toggleInfluencerCollectionVisibility({ listId, toPublic } = {}, turnPublic = makePublic, turnPrivate = makePrivate) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    const call = toPublic ? turnPublic : turnPrivate;

    return call(accountConfig, listId)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        dispatch(changeInfluencerCollectionVisibility(listId, toPublic));
        return resp;
      })
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(`${FAVORITES_PATH_PREFIX}/${listId}`, location)));
  };
}

export function fetchHeartList(
  { nextPageToken = null, concat = false, suppressAuthCatch = false, shareToken = undefined, listId = 'h.' } = {},
  heartList = getHeartList,
  authFactory = authenticationErrorCatchHandlerFactory
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);
    const { 'x-main': xMain } = cookies;

    if (!shareToken) {
      if (!cookies['x-main'] && !suppressAuthCatch) {
        return authFactory(dispatch, prependAppRoot(FAVORITES_PATH_PREFIX, location));
      } else if (!xMain) {
        return;
      }
    }

    return heartList(accountConfig, { shareToken, listId, nextPageToken }, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        dispatch(receiveHeartList(resp, concat));
        return resp;
      })
      .catch(!suppressAuthCatch && authFactory(dispatch, prependAppRoot(FAVORITES_PATH_PREFIX, location)));
  };
}

export function setMetaDataForList(collectionId, collectionName, collectionSubCopy, imageId, imageExtension, isInfluencerCollection) {
  return {
    type: SET_DOC_META_COLLECTION,
    metaPayload: {
      collectionId,
      collectionName,
      collectionSubCopy,
      imageId,
      imageExtension,
      isInfluencerCollection
    }
  };
}
export function fetchCollectionInfo() {
  return (dispatch, getState) => {
    const {
      hearts: {
        list: {
          listId,
          shareToken: collectionId,
          name: collectionName,
          metadata: { subCopy: collectionSubCopy, headerLayout: headerLayout, images: image, public: isPublic }
        },
        hearts
      }
    } = getState();
    const productImageIds = hearts.map(heart => heart.imageId);
    let collectionImageId = null;
    let imageExtension = null;
    const collectionDescription = isPublic ? collectionSubCopy : '';
    const isInfluencerCollection = listId !== HEART_DEFAULT_LIST_ID && isPublic;
    if (image.length > 0 && headerLayout === INFLUENCER_COLLECTION_IMAGE_LAYOUT && isPublic) {
      // If collection has header Image
      collectionImageId = image[0].imageId;
      imageExtension = image[0].imageExt;
    } else if (productImageIds.length > 0) {
      // If collection does not have header Image then get it's first product image in the collection
      collectionImageId = productImageIds[0];
    }
    return dispatch(
      setMetaDataForList(
        collectionId,
        collectionName?.trim(),
        collectionDescription?.trim(),
        collectionImageId,
        imageExtension,
        isInfluencerCollection
      )
    );
  };
}

export function fetchCollectionList(
  { listId, nextPageToken = null, concat = false, suppressAuthCatch = false } = {},
  heartList = getHeartList,
  authFactory = authenticationErrorCatchHandlerFactory
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      hearts: { collections },
      cookies,
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    // return if already fetched and present in collections
    if (collections?.some(collection => collection.listId === listId)) {
      return;
    }

    return heartList(accountConfig, { listId, nextPageToken }, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        dispatch(receiveCollectionList(resp, listId, concat));
        return resp;
      })
      .catch(!suppressAuthCatch && authFactory(dispatch, prependAppRoot(FAVORITES_PATH_PREFIX, location)));
  };
}

export function fetchCollectionProductDetail({ asin, colorId } = {}, merchantId = undefined) {
  return function (dispatch, getState) {
    if (asin) {
      if (!PRODUCT_ASIN.test(asin.toUpperCase())) {
        dispatch(productNotFound());
        return Promise.reject(err.PRODUCT_DETAILS);
      }
    }

    const state = getState();
    let {
      environmentConfig: {
        api: { cloudcatalog: cloudcatalogInfo }
      }
    } = state;
    if (merchantId && merchantId === VRSNL_MERCHANTID) {
      cloudcatalogInfo = cloudCatalogUsingMerchantId(merchantId);
    }

    const productRequest = productBundle(cloudcatalogInfo, { asin }, timedFetch('cloudCatalogProduct'))
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchErrorMiddleware);

    const dispatchErrorMessageOrNotFound = () => Promise.reject(err.PRODUCT_DETAILS);

    return productRequest
      .then(productResponse => {
        if (!productResponse.product || productResponse.product.length !== 1) {
          dispatch(productNotFound());
          // Also throw an error because this means the response returned without the data we need
          throw err.PRODUCT_DETAILS;
        }

        const product = productResponse.product[0];

        const { styles } = product;

        if (asin) {
          const style = styles.find(style => style.stocks.find(stock => stock.asin === asin));
          if (style) {
            ({ colorId } = style);
          }
        }

        dispatch(receiveCollectionProductDetail(product, colorId));

        return product;
      })
      .catch(dispatchErrorMessageOrNotFound);
  };
}

export function toggleHeartingLoginModal(open, id) {
  return {
    type: TOGGLE_HEARTING_LOGIN_MODAL,
    open,
    id
  };
}

export function addHeartToCart(id, merchantId, asin) {
  const IDTYPE = PRODUCT_ASIN.test(id) ? 'asin' : 'stockId';

  return dispatch => {
    dispatch(
      changeQuantity({ items: [{ [IDTYPE]: id, quantity: 1, quantityAddition: true, merchantId, trackAsin: asin }] }, { firePixel: true })
    ).then(response => {
      const error = translateCartError(response);
      if (error) {
        trackEvent('TE_FAVORITES_ADDTOCART_FAILURE');
        alert(error);
      } else {
        trackLegacyEvent('CartAddItem', null, `asin:${id}`);
        trackEvent('TE_FAVORITES_ADDTOCART', id);
        dispatch(showCartModal(true, id));
      }
    });
  };
}

export function heartProduct(
  {
    itemId = '',
    subItemId = '',
    listId = HEART_DEFAULT_LIST_ID,
    listType = HEART_DEFAULT_LIST_TYPE,
    merchantId = '',
    colorId = '',
    productId = '',
    price = 0,
    missingDimension = '',
    sourcePage = ''
  },
  callback,
  { add = addToList } = {}
) {
  // TODO ts remove undefined and make the interface Partial once this file is typed
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies: { 'x-main': xMain },
      pageView: { pageType }
    } = state;
    const accountConfig = selectAccountConfig(state);

    if (!xMain) {
      return dispatch(toggleHeartingLoginModal(true, itemId));
    }

    !!itemId && dispatch(addHeartToList(itemId)); // heart right away in the UI to prevent delay
    const itemIdOrSubItemId = subItemId ? { subItemId } : { itemId }; // Request can not contain both itemId and subItemId
    return add(accountConfig, { ...itemIdOrSubItemId, listId, merchantId })
      .then(res => {
        // If successful we get 200 w/ empty body
        if (res.status !== 200) {
          throw Error(res.statusText);
        } else {
          if (callback) {
            callback();
          }

          const amePayload = addSubItemId(
            {
              styleId: parseInt(itemId, 10),
              collectionId: listId,
              collectionType: listType,
              productId: parseInt(productId, 10),
              colorId: parseInt(colorId, 10),
              price,
              sourcePage: sourcePage ? sourcePage : getAmethystPageType(pageType),
              incompleteAddToCollections: !!missingDimension,
              missingDimension
            },
            subItemId
          );

          track(() => [evAddToCollections, amePayload]);
        }
      })
      .catch(() => {
        dispatch(removeHeartFromList(itemId)); // unheart if call fails
        // x-main probably just invalid, let's have them login and try again
        return dispatch(toggleHeartingLoginModal(true, itemId));
      });
  };
}

export function unHeartProduct(
  {
    itemId = '',
    subItemId = '',
    listId = HEART_DEFAULT_LIST_ID,
    listType = HEART_DEFAULT_LIST_TYPE,
    merchantId = '',
    colorId = '',
    productId = '',
    price = 0,
    sourcePage = ''
  },
  callback,
  { remove = removeFromList } = {}
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      pageView: { pageType }
    } = state;
    const accountConfig = selectAccountConfig(state);
    !!itemId && dispatch(removeHeartFromList(itemId)); // unheart right away in the UI to prevent delay

    return remove(accountConfig, { itemId, subItemId, listId, merchantId })
      .then(res => {
        // If successful we get 200 w/ empty body
        if (res.status !== 200) {
          throw Error(res.statusText);
        } else {
          if (callback) {
            callback();
          }

          const amePayload = addSubItemId(
            {
              styleId: itemId,
              collectionId: listId,
              collectionType: listType,
              colorId,
              productId,
              sourcePage: sourcePage ? sourcePage : getAmethystPageType(pageType),
              price
            },
            subItemId
          );

          track(() => [evRemoveFromCollections, amePayload]);
        }
      })
      .catch(() => {
        dispatch(addHeartToList(itemId)); // re-heart if call fails
        // x-main probably just invalid, let's have them login and try again
        return dispatch(toggleHeartingLoginModal(true, itemId));
      });
  };
}

export function getHearts(getHeartsList = getListOfIds, enabled = hasHearting) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: {
        location: { pathname, query }
      }
    } = state;
    const accountConfig = selectAccountConfig(state);
    const { heartOnLoad: itemId, heartOnLoadSub: subItemId } = query;

    if (!enabled || !cookies['x-main']) {
      return null;
    }
    return getHeartsList(accountConfig)
      .then(fetchErrorMiddleware)
      .then(res => {
        if (res && res.ids) {
          dispatch(receiveHeartIdList(res.ids));
        }

        if (itemId || subItemId) {
          const newQueryObject = {
            ...query,
            t: query.t ? decodeURIComponent(query.t) : undefined, // Avoid double encoding
            heartOnLoad: undefined,
            heartOnLoadSub: undefined
          };
          const newQueryString = queryString.stringify(newQueryObject);
          dispatch(replace(`${pathname}?${newQueryString}`));
          return dispatch(heartProduct({ itemId, subItemId }));
        }
      });
  };
}

export function getHeartCounts(productsList = [], getCall = likeCounts, enabled = hasHearting) {
  return (dispatch, getState) => {
    if (!enabled) {
      return null;
    }
    const {
      environmentConfig: {
        api: { cloudcatalog: cloudcatalogInfo }
      }
    } = getState();

    const styleIds = productsList.reduce((acc, { styleId }) => {
      !acc.includes(styleId) && acc.push(styleId);
      return acc;
    }, []);

    if (styleIds.length) {
      const multiResponseMode = styleIds.length > CLOUDCAT_MAX_HEART_IDS_PER_CALL;
      return getCall(cloudcatalogInfo, styleIds, multiResponseMode)
        .then(multiResponseMode ? fetchErrorMiddlewareMultiResponses : fetchErrorMiddleware)
        .then(response => {
          if (multiResponseMode) {
            let newResponse = {};
            response.map(r => (newResponse = { ...newResponse, ...r }));
            response = newResponse;
          }
          dispatch(receiveHeartCounts(response));
        })
        .catch(e => {
          trackError('NON-FATAL', 'Could not retrieve heart counts', e);
        });
    }
  };
}

export function createInfluencerCollection({ listName }) {
  return dispatch =>
    dispatch(
      createHeartList({
        listName: listName,
        listType: COLLECTION_TYPE.INFLUENCER_COLLECTION
      })
    ).then(response => {
      dispatch(
        toggleInfluencerCollectionVisibility({
          listId: response.listId,
          toPublic: true
        })
      );
      dispatch(setNewCollectionName(listName));
      return response;
    });
}

export function getAmethystSourcePage(pageType) {
  let sourcePage = getAmethystPageType(pageType);
  if (sourcePage === 'FAVORITES_PAGE') {
    sourcePage = COLLECTIONS_LIST_PAGE;
  }
  return sourcePage;
}
export function createHeartList({ listTypeId, listName, listType }, doCreateList = createList) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      pageView: { pageType },
      influencer: { postCreationSource }
    } = state;
    const sourcePage = getAmethystSourcePage(pageType);
    const accountConfig = selectAccountConfig(state);

    return doCreateList(accountConfig, { listTypeId, listName })
      .then(resp => {
        let eventData = {
          collectionName: listName,
          collectionId: listTypeId,
          collectionType: listType,
          sourcePage
        };
        if (sourcePage === 'INFLUENCER_PAGE') {
          eventData = {
            collectionName: listName,
            collectionId: listTypeId,
            collectionType: listType,
            sourcePage,
            influencerHubTab: postCreationSource
          };
        }
        track(() => [evCreateCollectionClick, eventData]);
        return resp;
      })
      .then(fetchErrorMiddleware);
  };
}

export function deleteHeartList(listTypeId, isInfluencerCollection = false, doDeleteList = deleteList) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      pageView: { pageType }
    } = state;
    const accountConfig = selectAccountConfig(state);

    const sourcePage = getAmethystSourcePage(pageType);
    return doDeleteList(accountConfig, listTypeId)
      .then(resp => {
        dispatch({
          type: DELETE_COLLECTION_LIST,
          collectionId: listTypeId,
          sourcePage
        });
        if (isInfluencerCollection) {
          dispatch(removeCollection(listTypeId));
        }
        return resp;
      })
      .then(fetchErrorMiddlewareMaybeJson);
  };
}

export function fetchListInfo({ listId, shareToken }, listInfo = getListInfo) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    return listInfo(accountConfig, { listId, shareToken }, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(receiveListInfo(response));
        return response;
      })
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(`${FAVORITES_PATH_PREFIX}/${listId}`, location)));
  };
}

export function sharingList({ listId }, doShareList = shareList) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    return doShareList(accountConfig, listId)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(dispatch(shareCollection(listId)))
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(`${FAVORITES_PATH_PREFIX}/${listId}`, location)));
  };
}

export function shareListEvent({ listId, shareToken }) {
  return (dispatch, getState) => {
    const {
      pageView: { pageType }
    } = getState();
    const sourcePage = getAmethystPageType(pageType);
    dispatch({
      type: SHARE_COLLECTION_LIST,
      collectionId: listId,
      shareToken,
      sourcePage
    });
  };
}

export function getListsForItemId({ itemId }, getLists = getItemSubsetOnLists) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const accountConfig = selectAccountConfig(state);

    return getLists(accountConfig, { itemId }, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(receiveSpecificItemIdLists(response));
        return response;
      });
  };
}

export function getItemsForListId({ listId }, getItems = getListOfIds) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const accountConfig = selectAccountConfig(state);

    if (!cookies['x-main']) {
      return null;
    }

    return getItems(accountConfig, listId, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        if (resp?.ids) {
          dispatch(receiveHeartIdList(resp.ids));
        }
        return resp;
      });
  };
}

export function getLists({ shareToken, listId, nextPageToken = null, concat = false } = {}, getLists = getAllLists) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const accountConfig = selectAccountConfig(state);

    if (!shareToken && !cookies['x-main']) {
      return dispatch(toggleHeartingLoginModal(true));
    }

    return getLists(accountConfig, { shareToken, listId, nextPageToken }, cookies)
      .then(fetchErrorMiddlewareAllowedErrors([403]))
      .then(response => {
        if (response.message === ERROR_CUSTOMER_NOT_RECOGNIZED) {
          dispatch(toggleHeartingLoginModal(true));
        } else {
          dispatch(receiveAllLists(response, concat));
        }
      })
      .catch(e => trackError('NON-FATAL', 'Could not load hearts getLists.', e));
  };
}

export function publishCollection(listId, publishCollectionsWithId = publishInfluencerCollection) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const accountConfig = selectAccountConfig(state);

    return publishCollectionsWithId(accountConfig, { listId }, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(response => {
        const { listId, shareToken } = response;
        dispatch(toggleCollectionPublishedStatus(listId, true));
        dispatch(shareListEvent({ listId, shareToken }));
        return response;
      })
      .catch(e => trackError('NON-FATAL', 'Could not Publish this Collection', e));
  };
}

export function unpublishCollection(listId, unpublishCollectionsWithId = unpublishInfluencerCollection) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const accountConfig = selectAccountConfig(state);

    return unpublishCollectionsWithId(accountConfig, { listId }, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(toggleCollectionPublishedStatus(listId, false));
        return response;
      })
      .catch(e => trackError('NON-FATAL', 'Could not Un-Publish this Collection', e));
  };
}

export function getPublishedCollections(
  profileHandle,
  nextPageToken = null,
  concat = false,
  getInfPublishedCollections = getInfluencerPublishedCollections
) {
  return (dispatch, getState) => {
    dispatch(requestAllInfluencerCollections());

    const accountConfig = selectAccountConfig(getState());

    return getInfPublishedCollections(accountConfig, { profileHandle, nextPageToken })
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(setInfluencerCollectionContentEmpty(!response.lists?.length));
        dispatch(receiveAllLists(response, concat));
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not load Published Collections', e);
        dispatch(requestAllInfluencerCollectionsFailed());
      });
  };
}

export function fetchHeartListForCustomerView(
  { nextPageToken = null, concat = false, shareToken = undefined, listId = 'h.' } = {},
  heartList = getHeartList
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      hearts: { collections },
      cookies
    } = state;
    const accountConfig = selectAccountConfig(state);

    // return if already fetched and present in collections
    if (collections?.some(collection => collection.shareToken === shareToken)) {
      return;
    }

    return heartList(accountConfig, { shareToken, listId, nextPageToken }, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        dispatch(receivePublishedCollectionList(resp, concat, shareToken));
        return resp;
      })
      .catch(e => trackError('NON-FATAL', 'Could not fetch collection details', e));
  };
}

export function fetchAllCollections({ overrideCollections = false } = {}, getAllCollections = getAllInfluencerCollections) {
  return (dispatch, getState) => {
    dispatch(requestAllInfluencerCollections());

    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    return getAllCollections(accountConfig, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(receiveAllInfluencerCollections(response, overrideCollections));
        dispatch(setInfluencerCollectionContentEmpty(!response.lists?.length));
      })
      .catch(e => {
        if (e.id === ERROR_NOT_AUTHENTICATED) {
          return authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(INFLUENCER_HUB_PAGE, location))(e);
        } else {
          trackError('NON-FATAL', 'Could not load All Influencer Collections', e);
          dispatch(requestAllInfluencerCollectionsFailed());
        }
      });
  };
}

export function fetchAllCollectionData(nextPageToken = null, concat = false) {
  return (dispatch, getState) =>
    dispatch(getLists({ nextPageToken, concat })).then(() => {
      const {
        hearts: { lists, collections }
      } = getState();
      lists?.forEach(({ listId }) => {
        if (!collections.some(collection => collection.listId === listId)) {
          fetchAllCollectionList(listId, dispatch);
        }
      });
    });
}

const fetchAllCollectionList = async (listId, dispatch) => {
  let items = [];
  let moreItems, nextPageToken;
  do {
    ({ items: moreItems, nextPageToken } = await dispatch(fetchCollectionListData({ listId, nextPageToken })));
    items = items.concat(moreItems);
  } while (nextPageToken);
  dispatch(receiveCollectionList({ items, nextPageToken }, listId, false));
};

export function fetchCollectionListData(
  { listId, nextPageToken = null, suppressAuthCatch = false } = {},
  heartList = getHeartList,
  authFactory = authenticationErrorCatchHandlerFactory
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const accountConfig = selectAccountConfig(state);

    return heartList(accountConfig, { listId, nextPageToken }, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .catch(!suppressAuthCatch && authFactory(dispatch, prependAppRoot(FAVORITES_PATH_PREFIX, location)));
  };
}

export function fetchProductStyleDetails(styleId, merchantId, fetchProductDetails = productBundle, entireProduct = false, includeSizing = false) {
  return (dispatch, getState) => {
    let cloudcatalog;
    if (merchantId === VRSNL_MERCHANTID) {
      cloudcatalog = cloudCatalogUsingMerchantId(VRSNL_MERCHANTID);
    } else {
      ({
        environmentConfig: {
          api: { cloudcatalog }
        }
      } = getState());
    }
    return fetchProductDetails(cloudcatalog, { styleId, entireProduct, includeBrand: false, includeSizing })
      .then(fetchErrorMiddleware)
      .then(response => {
        const { brandName, productName, productId, styles } = response.product[0];
        const style = styles.find(styleDetail => styleDetail.styleId === styleId);
        const defaultStyle = { color: 'N/A', images: [], price: 'N/A', stocks: [] };
        const { color, images, price, colorId, stocks } = style || defaultStyle;
        const { imageId } = images.find(image => image.type === 'MAIN') || { imageId: null };
        const quantity = stocks.reduce((previousValue, currentValue) => previousValue + Number(currentValue.onHand), 0);
        const productStyleDetails = {
          styleId,
          brandName,
          productId,
          productName,
          colorId,
          color,
          price,
          imageId,
          itemId: styleId,
          quantity,
          merchantId
        };
        dispatch(addProductToNewCollection(productStyleDetails));
      });
  };
}
