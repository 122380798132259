import ExecutionEnvironment from 'exenv';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import ab from 'react-redux-hydra';
import type { IConfig, IToggle } from '@unleash/proxy-client-react';
import { FlagProvider, UnleashClient, useFlag as useFlagHook, useVariant as useVariantHook } from '@unleash/proxy-client-react';
import { InMemoryStorageProvider } from 'unleash-proxy-client';

import { selectCookie } from 'selectors/cookies';
import type { AppState } from 'types/app';
import { track } from 'apis/amethyst';
import { evABTestEnrollment } from 'events/ab';
import useEffectOnce from 'hooks/useEffectOnce';
import { trackError } from 'helpers/ErrorUtils';

export type UnleashBootstrapToggles = IToggle[];
export type UnleashConfig = IConfig;

export const useFlag = useFlagHook;
export const useVariant = useVariantHook;

type Props = {
  children: React.ReactNode;
  config: UnleashConfig;
};

type UnleashEventContext = {
  sessionId: number;
  appName: string;
  environment: string;
};

interface UnleashImpressionEvent {
  eventId: string;
  context: UnleashEventContext;
  enabled: boolean;
  featureName: string;
}

// kill switch style toggle.
interface UnleashIsEnabledEvent extends UnleashImpressionEvent {
  eventType: 'isEnabled';
}

// ab test.
interface GetVariantEvent extends UnleashImpressionEvent {
  eventType: 'getVariant';
  variant: string;
}

function isVariantEvent(event: UnleashIsEnabledEvent | GetVariantEvent): event is GetVariantEvent {
  return event.eventType === 'getVariant';
}

let unleashSingleton: UnleashClient;
function createUnleashClient(config: UnleashConfig, bootstrap: UnleashBootstrapToggles, amazonSessionId: string) {
  const sessionId = ab.zfcSessionId();
  const unleashClientConf = { ...config, bootstrap, context: { sessionId, amazonSessionId } };
  if (ExecutionEnvironment.canUseDOM) {
    // if its already init just return it;
    if (unleashSingleton) {
      return unleashSingleton;
    }
    unleashSingleton = new UnleashClient(unleashClientConf);
    // Only "start" the client when running client side.
    // The server side render pass will use the bootstrapped toggles
    // We don't need to record impression events in the server side render pass since by definition the client render pass will have the same impressions
    unleashSingleton.on('impression', (event: UnleashIsEnabledEvent | GetVariantEvent) => {
      // only track enabled ab test "variant" events.
      if (event.enabled && isVariantEvent(event)) {
        const abTest = {
          test: event.featureName,
          phase: 0,
          group: Number.parseInt(event.variant),
          variation: event.variant
        };
        track(() => [evABTestEnrollment, abTest]);
      }
    });
    return unleashSingleton;
  } else {
    /**
     * Server side this is a throwaway instance of UnleashClient.
     * It gets used once for the render for the current request and is never used again
     * So we only want it to use the bootstrapped toggles provided by redux in makeApp()
     * by way of the nodejs sdk `unleash-client`.
     * The nodejs instance of UnleashClient is always running and will have up to date values
     */
    const serverSideUnleash = new UnleashClient({
      ...unleashClientConf,
      metricsInterval: 0,
      refreshInterval: 0,
      disableRefresh: true,
      disableMetrics: true,
      storageProvider: new InMemoryStorageProvider()
    });
    return serverSideUnleash;
  }
}

const UnleashFlagProvider = ({ children, config }: Props) => {
  const bootstrap = useSelector(({ unleash }) => unleash.toggles);
  const amazonSessionId = useSelector((state: AppState) => selectCookie(state, 'session-id'));
  // this feels like the most natural way to create something once within the context of a given component
  // even if it doesn't feel quite right.
  const unleashClientRef = useRef(createUnleashClient(config, bootstrap, amazonSessionId));
  useEffectOnce(() => {
    unleashClientRef.current.start().catch(e => {
      trackError('ERROR', 'Error starting unleash client side', e);
    });
  });
  return (
    <FlagProvider unleashClient={unleashClientRef.current} startClient={false}>
      {children}
    </FlagProvider>
  );
};

export default UnleashFlagProvider;
