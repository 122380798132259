import { useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import type CarouselProps from 'components/common/Carousel/Carousel.types';
import CarouselControls from 'tailwind/components/CarouselControls/CarouselControls';
import CarouselHeader from 'components/common/CarouselHeader/CarouselHeader';
import useMartyContext from 'hooks/useMartyContext';
import css from 'components/common/Carousel/Carousel.scss';
import { useInAssignment } from 'hooks/useHydra';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';
import { cn } from 'helpers/classnames';

export const CAROUSEL_TINY_EXPERIMENTAL_WIDTH = '45%';

const Carousel = (props: CarouselProps) => {
  const { slides = [], options = { containScroll: 'trimSnaps', slidesToScroll: 'auto' }, slideWidths, header } = props;
  const { mobile: slideWidthMobile, tablet: slideWidthTablet, desktop: slideWidthDesktop, tinyScreen: slideWidthTinyScreen } = slideWidths || {};
  const { testId } = useMartyContext();
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const hydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);

  // This is needed for PDP, We make sure the carousel re-initializes when its slides are updated
  useEffect(() => {
    if (emblaApi) {
      emblaApi.reInit();
      emblaApi.scrollTo(0);
    }
  }, [JSON.stringify(slides.map(slide => slide.key))]);

  return (
    <div
      style={
        {
          '--slide-width-tinyscreen': slideWidthTinyScreen,
          '--slide-width-mobile': slideWidthMobile,
          '--slide-width-tablet': slideWidthTablet,
          '--slide-width-desktop': slideWidthDesktop
        } as React.CSSProperties
      }
      className={cn(css.wrapper, hydraCefi && 'gap-y-4')}
      data-test-id={testId('Carousel')}
    >
      <CarouselHeader {...header} />
      <div className={css.viewport} ref={emblaRef}>
        <div className={css.container}>
          {slides
            .filter(slide => slide !== null && slide !== undefined)
            .map((slide: React.ReactElement) => (
              <div key={`slide-${slide?.key}`} className={css.slide} data-test-id={testId('CarouselSlide')}>
                {slide}
              </div>
            ))}
        </div>
      </div>
      <CarouselControls emblaApi={emblaApi} hydraCefiEnabled={hydraCefi} />
    </div>
  );
};

export default Carousel;
