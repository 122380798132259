export const HYDRA_TEST_VARIABLE = 'htv'; // currently not a real test; used for documentation and unit testing
export const HYDRA_ALTERNATIVE_RECO = 'altreco'; // [Alt]ernative [Reco]mmendation source
export const HYDRA_BROOKS_VIDEO = 'brovid'; // [Bro]oks [Vid]eo on pdp for select products and styles

export const HYDRA_BELOW_ADD_TO_CART_SLOT = 'batc'; // [B]elow [A]dd [T]o [C]art Slot
export const HYDRA_BRAND_PAGE_REDIRECT = 'bprt'; // [B]rand [P]age [R]edirect [T]est
export const HYDRA_SPLIT_SHIPMENTS = 'ssp'; // [S]plit [S]hi[p]ments
export const HYDRA_CHECKOUT_PRE_ADDRESS_PROMO = 'papr'; // [P]re-[A]ddress [PR]omo
export const HYDRA_BEST_GUESS = 'gbfy'; // [G]uess [B]est [F]or [Y]ou
export const HYDRA_VIP_ENROLLMENT_AT_CHECKOUT = 'vec'; // [V]ip [E]nrollment at [C]heckout
export const HYDRA_VIP_EMAIL_SUBSCRIPTIONS = 'ves'; // [V]ip [E]mail [S]ubscriptions
export const HYDRA_SEARCH_PRODUCT_HOVER = 'sph'; // [S]earch [P]roduct [H]over
export const HYDRA_PRODUCT_GRID_IMAGES = 'pgi'; // [P]roduct [G]rid [I]mages
export const HYDRA_HOMEPAGE_PERSONALIZATION_TEST = 'hppt'; // [H]ome[P]age [P]ersonalization [T]est; pronounced as its spelt.
export const HYDRA_RECO_DRAWER = 'recd'; // [REC]o [D]rawer
export const HYDRA_COLOR_LEGEND_PDP = 'cold'; // [C]ol[O]r [L]egen[D]
export const HYDRA_BRAND_INFLUENCER_SHOP_THE_LOOK_SEARCH = 'biss'; // [B]rand [I]nfluencer [S]hop the Look on [S]earch
export const HYDRA_BRAND_INFLUENCER_SHOP_THE_LOOK_PDP = 'bisp'; // [B]rand [I]nfluencer [S]hop the Look on [P]roduct detail page
export const HYDRA_SHOE_COMPARISON = 'shcmp'; // [SH]oe [C]o[MP]arison
export const HYDRA_PHOTO_ANGLES = 'pang'; // [P]hoto [ANG]les
export const HYDRA_SHIPPING_RANGE_DATES = 'srd'; // [S]hipping [R]ange [D]ates
export const HYDRA_HASHTAG = 'htag'; // [H]ash [Tag]
export const HYDRA_CTA_ON_SEARCH = 'cta1'; // [CTA] on search
export const HYDRA_FOLLOW = 'folw'; // [Fol]lo[w] button added
export const HYDRA_HEY_DUDE_BRAND_PAGE = 'hdbp'; // [H]ey [D]ude [B]rand [P]age
export const HYDRA_PRODUCT_MOBILE_INFO_ABOVE = 'pmia'; // [P]roduct [M]obile [I]nfo [A]bove
export const HYDRA_LIKE_POST = 'lib'; // [LI]ke [B]utton
export const HYDRA_HIDE_PAYPAL = 'hpp'; // [H]ide [P]ay[P]al
export const HYDRA_SIZE_CHART_CAROUSEL = 'scar'; // [S]ize [C]hart C[a][r]ousel
export const HYDRA_PRODUCT_SIZING_PREDICTION = 'sped'; // [S]izing [P]rediction [E]xperience [D]iscoverability
export const HYDRA_INFLUENCER_STYLE_FEED = 'isf'; // [I]nfluencer [S]tyle [F]eed
export const HYDRA_DOVE_ADS = 'dove'; // [Dove] Sponsored Ads
export const HYDRA_VISUAL_SEARCH = 'vsrch'; // [V]isual [S]ea[RCH]
export const HYDRA_COMPLEMENTARY_PRODUCT_RECOS = 'core'; // [CO]mplementary product [RE]commendations
export const HYDRA_COMPLEMENTARY_PRODUCT_DESKTOP_RECOS = 'cord'; // [CO]mplementary product [R]ecommendations [D]esktop
export const HYDRA_COMPLEMENTARY_RECOS_NEW = 'corn'; // [CO]mplementary [R]ecommendations [N]ew
export const HYDRA_LAUNCH_CALENDAR = 'lcal'; // [L]aunch [CAL]endar
export const HYDRA_MAFIA_INFERIOR_SUPPRESSION = 'mis'; // [M]afia [I]nferior [S]uppresion - filtering out shipment options
export const HYDRA_DEFAULT_TO_SECOND_DAY = 'dtsd'; // [D]efault [T]o [S]econd [D]ay
export const HYDRA_REMOVE_GENDER_FILTER = 'rgfk'; // [R]emove [G]ender [F]rom [K]ids
export const HYDRA_HEADER_RED_SALE_TEXT = 'hrst'; // [H]eader [R]ed [S]ale [T]ext
export const HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS = 'pcefi'; // [C]ore [E]xperience [F]unctionality [I]mprovement
export const HYDRA_RETURNS_COSTLY_CUSTOMER = 'rcct'; // [R]eturns [C]ostly [C]ustomer [T]est

// Vrsnl
export const HYDRA_VRSNL_PDP = 'vpdp'; // [V]rsnl [P]roduct [D]etail [P]age
