// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeInfoOutlineMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <circle cx="16" cy="16" r="13.5" stroke="currentcolor" />
    <circle cx="15.9998" cy="10.6666" r="1.33333" fill="currentcolor" />
    <path d="M16 16V22" stroke="currentcolor" strokeWidth="1.75" strokeLinecap="round" />
  </Icon>
);

export default UtilityStrokeInfoOutlineMediumIcon;
