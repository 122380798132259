import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getSymphonySearchComponents } from 'apis/mafia';
import { trackError } from 'helpers/ErrorUtils';
import {
  BEGIN_FETCH_SYMPHONY_SEARCH_COMPONENTS,
  FETCH_SYMPHONY_SEARCH_COMPONENTS_ERROR,
  RECEIVE_SYMPHONY_SEARCH_COMPONENTS
} from 'store/ducks/search/types';
import { selectMafiaConfig } from 'selectors/environment';

export function* workFetchSymphonySearchComponents() {
  try {
    const {
      client: { request },
      cookies,
      filters: { term, executedSearchUrl }
    } = yield select();
    const mafiaConfig = yield select(selectMafiaConfig);
    const response = yield call(getSymphonySearchComponents, mafiaConfig, { term: term || 'null', path: executedSearchUrl }, cookies, request);
    const data = yield response.json();
    yield put({
      type: RECEIVE_SYMPHONY_SEARCH_COMPONENTS,
      payload: { data, term }
    });
  } catch (error) {
    yield put({
      type: FETCH_SYMPHONY_SEARCH_COMPONENTS_ERROR,
      payload: { error }
    });
    yield call(trackError, 'NON-FATAL', 'Could not retrieve symphony search components', error);
  }
}

export function* watchFetchSymphonySearchComponents() {
  yield takeLatest(BEGIN_FETCH_SYMPHONY_SEARCH_COMPONENTS, workFetchSymphonySearchComponents);
}

export default [watchFetchSymphonySearchComponents];
