import { SERIALIZE_STATE } from 'constants/reduxActions';
import type { EnvironmentConfigState } from 'types/environment';

type EnvironmentConfigAction = {
  type: typeof SERIALIZE_STATE;
};

export default function reducer(state: Readonly<EnvironmentConfigState> = {} as any, action: EnvironmentConfigAction): EnvironmentConfigState {
  switch (action.type) {
    case SERIALIZE_STATE:
      // Strip out server side secrets
      return { ...state, unleashApiKey: undefined };
    default:
      return state;
  }
}
