import type { HeartListOrCollection } from 'helpers/HeartUtils';
import { INFLUENCER_TRACKING_LOCAL_STORAGE_KEY } from 'constants/appConstants';
import { NOTIFICATION_ATTRIBUTES } from 'constants/shoppablePosts';
import type { ProfileValidationStatus } from 'types/influencer';
import { AlertState, ContentState, InfluencerStatus } from 'types/influencer';
import {
  INFLUENCER_ALL_COLLECTIONS,
  INFLUENCER_COLLECTION_IMAGE_LAYOUT,
  INFLUENCER_DECLINED_COLLECTIONS,
  INFLUENCER_DRAFT_COLLECTIONS,
  INFLUENCER_HIDDEN_COLLECTIONS,
  INFLUENCER_PENDING_REVIEW_COLLECTIONS,
  INFLUENCER_PROFILE_TAB,
  INFLUENCER_PUBLISHED_COLLECTIONS,
  INFLUENCER_SHOPPABLE_POST_CUSTOMER_VIEW
} from 'constants/influencerPages';
import { loadFromLocalStorage, saveToLocalStorage } from 'helpers/localStorageUtilities';
import { getAbsoluteMarketplaceUrl, MERCHANTID_MARKET_NAME_MAPPING } from 'helpers/MarketplaceUtils';
import type { MSAOpts } from 'helpers/index';
import { constructMSAImageUrl } from 'helpers/index';

export const getInfluencerToken = () => {
  const influencerTracking = loadFromLocalStorage(INFLUENCER_TRACKING_LOCAL_STORAGE_KEY);
  const { infToken, timestamp } = influencerTracking || {};

  if (infToken && timestamp && !isNaN(timestamp)) {
    const currentTimestamp = Date.now();
    const diffInHours = (currentTimestamp - timestamp) / 3600000; // (1000 * 60 * 60)

    if (diffInHours <= 24) {
      return infToken;
    }
  }
  return undefined;
};

export const saveInfluencerToken = (infToken: unknown) => {
  saveToLocalStorage(INFLUENCER_TRACKING_LOCAL_STORAGE_KEY, {
    infToken,
    timestamp: Date.now()
  });
};

export const clearInfluencerLocalStorage = () => {
  localStorage.removeItem(INFLUENCER_TRACKING_LOCAL_STORAGE_KEY);
};

export const filterProfiles = (socialMediaProfiles: { validationStatus: ProfileValidationStatus }[], status: ProfileValidationStatus) =>
  socialMediaProfiles.filter(profile => profile.validationStatus === status);

export const fetchIsPendingOrDeclineProfileApproval = (influencer: { status: InfluencerStatus; socialMediaProfiles: object }) => {
  const { status, socialMediaProfiles } = influencer;
  return status === InfluencerStatus.PENDING && Object.keys(socialMediaProfiles).length >= 1;
};

export const reorderProducts = (
  items: { styleId: string }[],
  selectedStyleIds: { styleId: string }[],
  updatedPosition: number,
  setUpdatedPosition: (position: number) => void,
  setUpdatedStyleId: (styleId?: string) => void
) => {
  if (selectedStyleIds.length > 0) {
    if (updatedPosition <= selectedStyleIds.length) {
      let index = 0;
      items?.forEach((item, selectedProductIndex) => {
        if (item && selectedStyleIds[selectedStyleIds.length - 1]?.styleId === item.styleId) {
          index = selectedProductIndex;
        }
      });
      if (index > 0) {
        const item = items[index];
        items.splice(index, 1);
        items.unshift(item as { styleId: string });
      }
    } else if (updatedPosition > selectedStyleIds.length) {
      const index: number[] = [];
      items?.forEach((item, selectedProductIndex) => {
        if (selectedStyleIds.some(obj => obj.styleId === item.styleId)) {
          index.unshift(selectedProductIndex);
        }
      });
      const selectedProducts: any[] = [];
      index.forEach(selectedProductIndex => {
        const item = items[selectedProductIndex];
        items.splice(selectedProductIndex, 1);
        selectedProducts.push(item);
      });
      selectedProducts.forEach(selectedProductObj => {
        items.unshift(selectedProductObj);
      });
    }
    setUpdatedPosition(selectedStyleIds.length);
    setUpdatedStyleId(selectedStyleIds[0]?.styleId);
  }
};

export const reorderInitialSelectedItems = (
  items: { styleId: string }[],
  selectedStyleIds: { styleId: string }[],
  setUpdatedStyleId: (styleId?: string) => void
) => {
  items?.forEach((item, index) => {
    if (item && selectedStyleIds.some(obj => obj.styleId === item.styleId)) {
      const item = items[index];
      items.splice(index, 1);
      items.unshift(item as { styleId: string });
    }
  });
  if (selectedStyleIds.length) {
    setUpdatedStyleId(selectedStyleIds[0]?.styleId);
  }
};

export const computeNotificationAttributes = (shoppablePost: {
  postState: ContentState;
  submittedAt: Date;
  createdAt: Date;
  hidden: boolean;
  rejectionReason?: string;
}) => {
  const { postState, submittedAt, createdAt, hidden, rejectionReason } = shoppablePost;

  let notifAttributes;

  switch (postState) {
    case ContentState.DRAFT:
      notifAttributes = NOTIFICATION_ATTRIBUTES[AlertState.DRAFT];
      break;
    case ContentState.PENDING:
      notifAttributes = NOTIFICATION_ATTRIBUTES[AlertState.PENDING];
      const submittedAtDate = getCollectionDate(submittedAt);
      notifAttributes.bottomText = `Change Submit Date: ${submittedAtDate}`;
      break;
    case ContentState.APPROVED:
      if (hidden) {
        notifAttributes = NOTIFICATION_ATTRIBUTES[AlertState.HIDDEN];
        const createdAtDate = getCollectionDate(createdAt);
        notifAttributes.bottomText = `Content Creation Date: ${createdAtDate}`;
      } else {
        notifAttributes = NOTIFICATION_ATTRIBUTES[AlertState.APPROVED];
      }
      break;
    case ContentState.DECLINED:
      notifAttributes = NOTIFICATION_ATTRIBUTES[AlertState.DECLINED];
      notifAttributes.upperText = `The content change has been declined for - '<b>${rejectionReason}</b>'. Please change the photo/video and try again. Your post has been reversed to the previous version on your profile.`;
      break;
    default:
      return {};
  }
  return notifAttributes;
};

export const getCollectionDate = (timeStamp: string | Date) =>
  new Date(timeStamp).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

export const getProductBaseUrl = (merchantId: keyof typeof MERCHANTID_MARKET_NAME_MAPPING, host: string) => {
  let marketPlaceName = MERCHANTID_MARKET_NAME_MAPPING[merchantId];
  if (marketPlaceName === 'versional') {
    marketPlaceName = 'vrsnl.zappos';
  }
  return getAbsoluteMarketplaceUrl(marketPlaceName, host);
};

export const getFilteredCollections = (displayContext: string, collections: HeartListOrCollection[], filterCollections: string) => {
  const filterCountMap: Record<string, { count: number; title: string }> = {};
  let filteredCollections;
  filterCountMap[INFLUENCER_ALL_COLLECTIONS] = { count: collections?.length, title: 'See All Content' };

  const publishedCollections = collections?.filter(collection => {
    const {
      itemCount,
      metadata: { published }
    } = collection;
    return itemCount > 0 && published;
  });
  filterCountMap[INFLUENCER_PUBLISHED_COLLECTIONS] = { count: publishedCollections?.length, title: 'Published' };

  const hiddenCollections = collections?.filter(collection => {
    const {
      itemCount,
      metadata: { published, headerLayout, images }
    } = collection;
    return itemCount > 0 && !published && (headerLayout === INFLUENCER_COLLECTION_IMAGE_LAYOUT ? !images.length || images[0]?.approved : true);
  });
  filterCountMap[INFLUENCER_HIDDEN_COLLECTIONS] = { count: hiddenCollections?.length, title: 'Hidden' };

  const draftCollections = collections?.filter(collection => {
    const {
      itemCount,
      metadata: { headerLayout, images }
    } = collection;
    return itemCount <= 0 && (headerLayout === INFLUENCER_COLLECTION_IMAGE_LAYOUT ? !images.length || images[0]?.approved : true);
  });
  filterCountMap[INFLUENCER_DRAFT_COLLECTIONS] = { count: draftCollections?.length, title: 'Draft' };

  const pendingReviewCollections = collections?.filter(collection => {
    const {
      metadata: { headerLayout, images }
    } = collection;
    return headerLayout === INFLUENCER_COLLECTION_IMAGE_LAYOUT && images.length && !images[0]?.approved && !images[0]?.feedback;
  });
  filterCountMap[INFLUENCER_PENDING_REVIEW_COLLECTIONS] = { count: pendingReviewCollections?.length, title: 'Pending Review' };

  const declinedCollections = collections?.filter(collection => {
    const {
      metadata: { headerLayout, images }
    } = collection;
    return headerLayout === INFLUENCER_COLLECTION_IMAGE_LAYOUT && images.length && !images[0]?.approved && images[0]?.feedback;
  });
  filterCountMap[INFLUENCER_DECLINED_COLLECTIONS] = { count: declinedCollections?.length, title: 'Declined' };

  if (displayContext === INFLUENCER_PROFILE_TAB) {
    filteredCollections = publishedCollections;
  } else {
    switch (filterCollections) {
      case INFLUENCER_ALL_COLLECTIONS:
        filteredCollections = collections;
        break;
      case INFLUENCER_PUBLISHED_COLLECTIONS:
        filteredCollections = publishedCollections;
        break;
      case INFLUENCER_HIDDEN_COLLECTIONS:
        filteredCollections = hiddenCollections;
        break;
      case INFLUENCER_PENDING_REVIEW_COLLECTIONS:
        filteredCollections = pendingReviewCollections;
        break;
      case INFLUENCER_DECLINED_COLLECTIONS:
        filteredCollections = declinedCollections;
        break;
      case INFLUENCER_DRAFT_COLLECTIONS:
        filteredCollections = draftCollections;
        break;
      default:
        filteredCollections = collections;
    }
  }
  return [filteredCollections, filterCountMap];
};

export function determineInfluencerCollectionStatus(collection: HeartListOrCollection) {
  const {
    itemCount,
    metadata: { published, headerLayout, images }
  } = collection;
  if (headerLayout === INFLUENCER_COLLECTION_IMAGE_LAYOUT && images.length && !images[0]?.approved && !images[0]?.feedback) {
    return ContentState.PENDING;
  } else if (headerLayout === INFLUENCER_COLLECTION_IMAGE_LAYOUT && images.length && !images[0]?.approved && images[0]?.feedback) {
    return ContentState.DECLINED;
  } else if (itemCount <= 0 && (headerLayout === INFLUENCER_COLLECTION_IMAGE_LAYOUT ? !images.length || images[0]?.approved : true)) {
    return ContentState.DRAFT;
  } else if (itemCount > 0 && !published && (headerLayout === INFLUENCER_COLLECTION_IMAGE_LAYOUT ? !images.length || images[0]?.approved : true)) {
    return ContentState.HIDDEN;
  } else {
    return '';
  }
}

export const removeListHtml = (text: string) =>
  text
    .replace(new RegExp('<ol>', 'g'), '')
    .replace(new RegExp('</ol>', 'g'), '')
    .replace(new RegExp('<ul>', 'g'), '')
    .replace(new RegExp('</ul>', 'g'), '')
    .replace(new RegExp('<li>', 'g'), '')
    .replace(new RegExp('</li>', 'g'), '');

export const getProductHoverPosition = (tagCoordinateX: number, tagCoordinateY: number) => {
  const modalPosition: { right?: number; left?: number; bottom?: string; top?: string } = {};
  if (tagCoordinateX >= 50) {
    modalPosition.right = 0;
  } else {
    modalPosition.left = 0;
  }
  if (tagCoordinateY >= 50) {
    modalPosition.bottom = 100 + '%';
  } else {
    modalPosition.top = 100 + '%';
  }
  return modalPosition;
};

export const getMediaUrl = (mediaId?: string, mediaExtension?: string, mediaOpts: MSAOpts = {}) => {
  if (!mediaId) {
    return '';
  }
  const physicalId = mediaId.split('_')[0];
  return constructMSAImageUrl(physicalId, { ...mediaOpts, extension: mediaExtension });
};

export const generateShoppablePostObj = (title: string, postId: string) => {
  const SHOPPABLE_POST_URL = `${window?.location.origin}${INFLUENCER_SHOPPABLE_POST_CUSTOMER_VIEW}${postId}`;
  return {
    link: SHOPPABLE_POST_URL,
    name: title,
    style: '',
    image: ''
  };
};

export const getS3KeyForVideoUpload = (getObjectPreSignedUrl: string) => {
  const regEx = /s3.amazonaws.com\/(.*)\?/g;
  return regEx.exec(getObjectPreSignedUrl)?.[1];
};

export const generateContentDate = (time: string) => {
  const contentDate = new Date(+time);

  const formattedDay = contentDate.toLocaleDateString('en-US', { day: '2-digit' });
  const formattedMonth = contentDate.toLocaleDateString('en-US', { month: 'short' });
  const formattedYear = contentDate.toLocaleDateString('en-US', { year: 'numeric' });

  return `${formattedDay}, ${formattedMonth}, ${formattedYear}`;
};
