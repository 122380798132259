import { deepEqual } from 'fast-equals';

import ProductUtils from 'helpers/ProductUtils';
import { selectAccountConfig, selectMafiaConfig } from 'selectors/environment';

export const getAkitaKey = ({ environmentConfig: { akitaKey } }) => akitaKey;

export const getAccountApiAndCredentials = state => {
  const { cookies } = state;
  const accountConfig = selectAccountConfig(state);

  return { accountConfig, credentials: cookies };
};

export const getIsAlsoBilling = ({ address: { isAlsoBilling } }) => isAlsoBilling;

export const getMafiaAndCredentials = state => {
  const { cookies } = state;
  const mafiaConfig = selectMafiaConfig(state);

  return { mafiaConfig, credentials: cookies };
};

export const getFeedback = ({ feedback }) => feedback;

export const getWasAddressValid = state => {
  const {
    address: { formItem }
  } = state;
  return deepEqual(formItem, {});
};

export const getAddressFormItem = ({ address: { formItem } }) => formItem;

export const getAddressFormItemIsBilling = ({
  address: {
    formItem: { isBilling }
  }
}) => isBilling;

export const getPurchaseAddresses = ({ address: { savedAddresses } }) => savedAddresses;

export const getPurchasePayments = ({ sharedPayment: { savedPayments } }) => savedPayments;

export const getLastSavedCard = ({ sharedPayment: { lastSavedCard } }) => lastSavedCard;

export const getAddressesIsLoaded = ({ address: { isLoaded } }) => isLoaded;

export const getNumberPurchaseProducts = ({
  checkoutData: {
    purchase: { productList }
  }
}) => productList.length;

export const getCheckoutProducts = ({
  checkoutData: {
    purchase: { productList }
  }
}) => productList;

export const getIsPlacingOrder = ({ checkoutData: { isPlacingOrder } }) => isPlacingOrder;

export const getShouldFireOrderConfirmationPixel = ({ pixelServer: { shouldFireOnOrderConfirmation } }) => shouldFireOnOrderConfirmation;

export const getPurchaseShippingAddress = ({
  checkoutData: {
    purchase: { shippingAddress }
  }
}) => shippingAddress;

export const getPurchaseShippingAddressId = ({
  checkoutData: {
    purchase: { shippingAddressId }
  }
}) => shippingAddressId;

export const getPurchaseType = ({ checkoutData: { purchaseType } }) => purchaseType;

export const getFormattedConstraintViolations = ({ checkoutData: { constraintViolations } }) => constraintViolations;

export const getCheckoutLinks = ({ checkoutData: { links } }) => links;

export const getPurchaseId = ({
  checkoutData: {
    purchase: { purchaseId }
  }
}) => purchaseId;

export const getAmazonCheckoutSessionId = ({
  checkoutData: {
    purchase: { amazonPay = {} }
  }
}) => amazonPay?.amazonCheckoutSessionId;

export const getUsePromoBalances = ({ checkoutData: { usePromoBalance } }) => usePromoBalance;

export const getUseDiscountBalance = ({ checkoutData: { useDiscountBalance } }) => useDiscountBalance;

export const getCartType = ({ checkoutData: { cartType } }) => cartType;

export const getPurchaseCard = ({ checkoutData: { purchaseCreditCard } }) => purchaseCreditCard;

export const getVersionNumber = ({
  checkoutData: {
    purchase: { versionNumber }
  }
}) => versionNumber;

export const getPurchaseData = ({ checkoutData }) => checkoutData;

export const getUseAsDefaults = ({ checkoutData: { useAsDefaults } }) => useAsDefaults;

export const getPageType = ({ pageView: { pageType } }) => pageType;

export const getProductBrandIdProductIdPriceForAkita = ({
  product: {
    detail: { productId, brandId, styles, defaultProductType, defaultCategory },
    colorId
  }
}) => ({
  orders: [
    {
      items: [
        {
          brand_id: brandId,
          product_id: productId,
          product_type: defaultProductType,
          product_category: defaultCategory,
          price: ProductUtils.priceToFloat(ProductUtils.getStyleByColor(styles, colorId).price)
        }
      ]
    }
  ]
});

export const getEnrollAtCheckout = ({ shipOption: { isVipEnrollmentSelected } }) => isVipEnrollmentSelected;
