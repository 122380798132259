import ExecutionEnvironment from 'exenv';
import type { Location as HistoryLocation } from 'history';

import type EventName from 'utils/contentSquare/eventNames';

export const CUSTOM_VAR_KEY = 'setCustomVariable';
export const DYNAMIC_VAR_KEY = 'trackDynamicVariable';
export const PAGE_VIEW = 'trackPageview';
export const PAGE_EVENT = 'trackPageEvent';
export const SET_QUERY = 'setQuery';
export const USER_IDENTIFIER = '@user-identifier@';
export const CREATE_TRANSACTION = 'ec:transaction:create';
export const ADD_TRANSACTION_ITEMS = 'ec:transaction:items:add';
export const SEND_TRANSACTION = 'ec:transaction:send';
export const ADD_TO_CART = 'ec:cart:add';

type Scope = 'visit' | 'page' | 'nextPageOnly' | undefined;

export enum VariableKeys {
  DIRECTED_ID_KEY = 'directed_id',
  SESSION_ID_KEY = 'session_id'
}

export enum CustomVarKey {
  LOGGED_IN = 'Logged In',
  PAGE_TYPE = 'Page Type',
  LOYALTY_STATUS = 'Loyalty Status',
  NO_OF_ITEMS_IN_CART = '# of Items in Cart'
}

// Supports only indexes 1 - 20
export enum CustomVarIndex {
  LOGGED_IN = 1,
  PAGE_TYPE = 2,
  LOYALTY_STATUS = 3,
  NO_OF_ITEMS_IN_CART = 4
}

// https://docs.contentsquare.com/en/web/sending-custom-vars/#:~:text=index%20is%20a%20integer%20%3E%200%20and%20%3C%3D%2020
interface CustomVariable {
  key: CustomVarKey;
  value: string | boolean | number | undefined | null;
  index: CustomVarIndex;
  /** default is visit and page when not supplied */
  scope?: Scope;
}

interface DynamicVariable {
  key: string;
  value: string | number;
}

interface Transaction {
  /**  max 40 characters */
  id: string;
  revenue: string | number;
  currency: 'USD';
}

interface TransactionItem {
  /** max 40 characters */
  id: string;
  /** max 20 characters */
  sku: string;
  price: string | number;
  /** 1 - 32766 */
  quantity: number;
  /** max 50 characters */
  name: string;
  /** max 20 characters */
  category?: string;
}

interface AddToCart {
  sku: string;
  merchant?: string;
}

export const getUxa = () => {
  if (!ExecutionEnvironment.canUseDOM) {
    return [];
  }
  return (window._uxa = window._uxa || []);
};

export const trackDynamicVariable = ({ key, value }: DynamicVariable) => {
  getUxa().push([DYNAMIC_VAR_KEY, { key, value }]);
};

export const trackCustomVariable = ({ index, key, value, scope }: CustomVariable) => {
  getUxa().push([CUSTOM_VAR_KEY, index, key, String(value), scope]);
};

export const trackPageEvent = (eventName: EventName | `@user-identifier@${string}`) => {
  getUxa().push([PAGE_EVENT, eventName]);
};

export const trackPageview = (location: Location | HistoryLocation) => {
  getUxa().push([PAGE_VIEW, buildPath(location)]);
};

export const trackAddToCart = (data: AddToCart) => {
  getUxa().push([ADD_TO_CART, data]);
};

export const setQuery = (query: string) => {
  getUxa().push([SET_QUERY, query]);
};

export const createTransaction = (transaction: Transaction) => {
  getUxa().push([CREATE_TRANSACTION, transaction]);
};

export const addTransactionItem = (items: TransactionItem) => {
  getUxa().push([ADD_TRANSACTION_ITEMS, items]);
};

export const sendTransaction = () => {
  getUxa().push([SEND_TRANSACTION]);
};

export const buildPath = (location: Location | HistoryLocation) => location.pathname + location.hash.replace('#', '?__');
