import { middlewareTrack } from 'apis/amethyst';
import { INFLUENCER_CONTENT_PRODUCT_HOVER, SHARE_STOREFRONT_CLICK, STOREFRONT_PAGE_VIEW } from 'constants/amethyst';
import { INFLUENCER_PAGE, INFLUENCER_STOREFRONT_PAGE } from 'constants/amethystPageTypes';
import {
  COLLECTIONS_PAGE_VIEW,
  CREATE_COLLECTION_LIST,
  DELETE_COLLECTION_LIST,
  evCreateCollectionClick,
  evDeleteCollectionClick,
  evShareCollectionClick,
  SHARE_COLLECTION_LIST
} from 'events/favorites';
import { INFLUENCER_CONTENT_TYPE } from 'constants/amethystEnums';

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/AllCollectionsPageView.proto
export const pvInfluencerCollections = ({ collectionCount, recommendationImpression, isOwner }) => ({
  allCollectionsPageView: {
    collectionCount,
    recommendationImpression,
    isOwner,
    sourcePage: INFLUENCER_PAGE
  }
});

export default {
  pageEvent: COLLECTIONS_PAGE_VIEW,
  events: {
    [SHARE_COLLECTION_LIST]: [(_, params) => middlewareTrack(evShareCollectionClick(params))],
    [CREATE_COLLECTION_LIST]: [(_, params) => middlewareTrack(evCreateCollectionClick(params))],
    [DELETE_COLLECTION_LIST]: [(_, params) => middlewareTrack(evDeleteCollectionClick(params))]
  }
};

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/StorefrontPageView.proto
export const pvStorefrontPageView = ({ influencerId, arrivedFromShareLink, profileHandle }) => ({
  [STOREFRONT_PAGE_VIEW]: {
    influencerId,
    arrivedFromShareLink,
    profileHandle,
    sourcePage: INFLUENCER_STOREFRONT_PAGE
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ShareStorefrontClick.proto
export const evShareStoreforntClick = ({ influencerId, profileHandle }) => ({
  [SHARE_STOREFRONT_CLICK]: {
    influencerId,
    profileHandle,
    source_page: INFLUENCER_STOREFRONT_PAGE
  }
});

export const evContentProductHover = ({ styleId, productId, contentId, contentType, websiteDomain, sourcePage }) => ({
  [INFLUENCER_CONTENT_PRODUCT_HOVER]: {
    hoveredProduct: {
      styleId,
      productId,
      supplementalData: {
        websiteDomain
      }
    },
    contentId,
    contentType: INFLUENCER_CONTENT_TYPE[contentType],
    sourcePage
  }
});
