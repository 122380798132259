// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { LargeIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeMailLargeIcon = (props: LargeIconProps) => (
  <Icon {...props} viewBox="0 0 64 64">
    <path
      d="M9.36719 19.1977C9.36719 20.0861 9.8241 20.9155 10.5856 21.4084C14.3015 23.8154 25.4984 31.0661 29.7086 33.7932C30.6926 34.4305 31.974 34.4305 32.958 33.7932C37.1681 31.0661 48.365 23.8154 52.0809 21.4084C52.8425 20.9155 53.2993 20.0853 53.2993 19.1969M9.36719 19.1977V19.1955C9.36719 17.6251 10.6779 16.3519 12.2946 16.3519H50.372C51.9886 16.3519 53.2993 17.6251 53.2993 19.1955V19.1969M9.36719 19.1977L9.36729 44.8032C9.36729 45.5578 9.67555 46.2812 10.2254 46.8147C10.7746 47.3488 11.5192 47.6483 12.2961 47.6483H50.3706C51.1474 47.6483 51.8921 47.3488 52.4413 46.8147C52.9911 46.2812 53.2994 45.5578 53.2994 44.8032L53.2993 19.1969"
      stroke="currentcolor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default UtilityStrokeMailLargeIcon;
