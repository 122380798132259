import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';

import {
  RECEIVE_VISUAL_SEARCH_BOUNDING_BOXES,
  RECEIVE_VISUAL_SEARCH_CANCELLATION,
  RECEIVE_VISUAL_SEARCH_FAILURE,
  RECEIVE_VISUAL_SEARCH_INPUT_IMAGE,
  RECEIVE_VISUAL_SEARCH_STYLE_IDS,
  RECEIVE_VISUAL_SEARCH_SUCCESS,
  REQUEST_VISUAL_SEARCH,
  RESET_VISUAL_SEARCH_BOUNDING_BOXES,
  RESET_VISUAL_SEARCH_INPUT_IMAGE,
  RESET_VISUAL_SEARCH_RESULTS
} from 'constants/reduxActions';
import type { AppState } from 'types/app';
import type { ProductWithRelationsFromCalypso } from 'types/calypso';
import { fetchApiNotAuthenticatedMiddleware, fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { fetchProductDetailsByStyleIds } from 'apis/calypso';
import { trackError } from 'helpers/ErrorUtils';
import { getBoundingBoxes, getStyleIdsForBB, getVsPresignedUrl } from 'apis/mafia';
import { selectVisualSearchMafiaConfig } from 'selectors/environment';
import timedFetch from 'middleware/timedFetch';
import { guid } from 'helpers/guid';
import { logError } from 'middleware/logger';
import {
  reorderProductsByStyleIds,
  updatedProductsWithOwnRelatedStyle
} from 'components/VisualSearch/VisualSearchResults/Products/VisualSearchProductResults/VisualSearchResults.utility';
import { ABORT_ERROR_TEXT, IMAGE_MATCH_SEARCH_TIMEOUT_LIMIT_IN_SECONDS } from 'constants/visualSearch';
import type { BoundingBoxApiResponse, BoundingBoxData } from 'components/VisualSearch/VisualSearchResults/visualSearchResults.types';
import { track } from 'apis/amethyst';
import { evVisualSearchSubmitImageSuccess } from 'events/visualSearch';

export function requestVisualSearch() {
  return {
    type: REQUEST_VISUAL_SEARCH
  } as const;
}

export function receiveVisualSearchStyleIds(styleIds: string[]) {
  return {
    type: RECEIVE_VISUAL_SEARCH_STYLE_IDS,
    styleIds
  } as const;
}

export function receiveVisualSearchSuccess(products: ProductWithRelationsFromCalypso[] = []) {
  return {
    type: RECEIVE_VISUAL_SEARCH_SUCCESS,
    products
  } as const;
}

export function receiveVisualSearchBoundingBoxes(apiResponse: BoundingBoxApiResponse, queryId: string, imageURI: string) {
  return {
    type: RECEIVE_VISUAL_SEARCH_BOUNDING_BOXES,
    apiResponse,
    queryId,
    imageURI
  } as const;
}

export function resetVisualSearchBoundingBoxes() {
  return {
    type: RESET_VISUAL_SEARCH_BOUNDING_BOXES
  } as const;
}

export function receiveVisualSearchFailure() {
  return {
    type: RECEIVE_VISUAL_SEARCH_FAILURE
  } as const;
}

export function resetVisualSearchResults() {
  return {
    type: RESET_VISUAL_SEARCH_RESULTS
  } as const;
}

export function receiveVisualSearchInputImage(inputImage: File) {
  return {
    type: RECEIVE_VISUAL_SEARCH_INPUT_IMAGE,
    inputImage
  } as const;
}

export function resetVisualSearchInputImage() {
  return {
    type: RESET_VISUAL_SEARCH_INPUT_IMAGE
  } as const;
}

// when user re-uploads a same / different photo
export function receiveVisualSearchCancellation() {
  return {
    type: RECEIVE_VISUAL_SEARCH_CANCELLATION
  } as const;
}

export function uploadVisualSearchInputImage(
  file: File,
  signal: AbortSignal,
  fetcher = timedFetch('PutVisualSearchInputImage')
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const queryId = guid(true);

    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectVisualSearchMafiaConfig(state);

    dispatch(resetVisualSearchBoundingBoxes());
    dispatch(resetVisualSearchResults());
    dispatch(requestVisualSearch());
    return getVsPresignedUrl(mafiaConfig, queryId, cookies, signal)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(response =>
        fetcher(
          response.presignedURL,
          {
            method: 'PUT',
            body: file
          },
          signal
        ).then(() => {
          track(() => [
            evVisualSearchSubmitImageSuccess,
            {
              imageId: queryId
            }
          ]);
          dispatch(getBoundingBoxesForImage(queryId, response.keyName, signal));
        })
      )
      .catch((e: Error) => {
        trackError('NON-FATAL', 'Not able to get VS product results', e);
      });
  };
}

export function getBoundingBoxesForImage(
  queryId: string,
  imageURI: string,
  signal: AbortSignal,
  getBoundingBoxesForImage = getBoundingBoxes
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectVisualSearchMafiaConfig(state);

    const reqData = { queryId: queryId, imageURI: imageURI };

    return getBoundingBoxesForImage(mafiaConfig, reqData, cookies, signal)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(receiveVisualSearchBoundingBoxes(response, queryId, imageURI));
        // adding 'Fetched' status to show troubleshooting if we received 0 Bounding Boxes
        dispatch(receiveVisualSearchSuccess());
      })
      .catch(e => {
        if (e.name === ABORT_ERROR_TEXT) {
          dispatch(receiveVisualSearchCancellation());
        } else {
          dispatch(receiveVisualSearchFailure());
        }
        logError('Error fetching Bounding Boxes from an Image.', e);
      });
  };
}

export function getStyleIdsForBoundingBox(
  queryId: string,
  imageURI: string,
  boundingBoxData: BoundingBoxData | undefined,
  signal: AbortSignal,
  getStyleIdsForBoundingBox = getStyleIdsForBB
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectVisualSearchMafiaConfig(state);

    dispatch(resetVisualSearchResults());
    dispatch(requestVisualSearch());

    const boundingBoxData1 = {
      boundingBox: boundingBoxData?.boundingBox,
      boundingBoxId: boundingBoxData?.boundingBoxId,
      productAttributes: {
        productType: boundingBoxData?.productType
      }
    };

    const reqData = { queryId, imageURI, boundingBoxData: boundingBoxData1 };

    const timeoutPromise: Promise<{ timeout: boolean }> = new Promise(resolve => {
      setTimeout(() => {
        resolve({ timeout: true });
      }, IMAGE_MATCH_SEARCH_TIMEOUT_LIMIT_IN_SECONDS * 1000);
    });

    return Promise.race([
      getStyleIdsForBoundingBox(mafiaConfig, reqData, cookies, signal)
        .then(fetchApiNotAuthenticatedMiddleware)
        .then(fetchErrorMiddleware)
        .then(response => {
          dispatch(receiveVisualSearchStyleIds(response.styleIdList));
          // send limited styleIds - currently decided limit as 40
          dispatch(getProductsForStyleIds(response.styleIdList.slice(0, 40), signal));
        })
        .catch(e => {
          if (e.name === ABORT_ERROR_TEXT) {
            dispatch(receiveVisualSearchCancellation());
          } else {
            dispatch(receiveVisualSearchFailure());
          }
          logError('Error fetching Products from StyleIds.', e);
        }),
      timeoutPromise
    ]).then((result: void | { timeout: boolean }) => {
      if (result?.timeout) {
        dispatch(receiveVisualSearchFailure());
      }
    });
  };
}

export function getProductsForStyleIds(styleIds: string[], signal: AbortSignal): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return async (dispatch, getState) => {
    const {
      environmentConfig: {
        api: {
          calypso: { url, siteId, subsiteId }
        }
      }
    } = getState();
    const getProductDetails = async () => {
      const response: { results: ProductWithRelationsFromCalypso[] } = await fetchErrorMiddleware(
        (await fetchProductDetailsByStyleIds({ url, siteId, subsiteId }, styleIds, signal)) as Response
      );
      const updatedProducts = updatedProductsWithOwnRelatedStyle(response.results);
      dispatch(receiveVisualSearchSuccess(reorderProductsByStyleIds(updatedProducts, styleIds)));
    };

    return getProductDetails().catch(e => {
      if (e.name === ABORT_ERROR_TEXT) {
        dispatch(receiveVisualSearchCancellation());
      } else {
        dispatch(receiveVisualSearchFailure());
      }
      trackError('NON-FATAL', 'Could not get Product details from StyleIds', e);
    });
  };
}

export type VisualSearchAction =
  | ReturnType<typeof requestVisualSearch>
  | ReturnType<typeof receiveVisualSearchSuccess>
  | ReturnType<typeof receiveVisualSearchFailure>
  | ReturnType<typeof resetVisualSearchResults>
  | ReturnType<typeof receiveVisualSearchInputImage>
  | ReturnType<typeof resetVisualSearchInputImage>
  | ReturnType<typeof receiveVisualSearchBoundingBoxes>
  | ReturnType<typeof resetVisualSearchBoundingBoxes>
  | ReturnType<typeof receiveVisualSearchCancellation>
  | ReturnType<typeof receiveVisualSearchStyleIds>;
