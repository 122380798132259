import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import usePrevious from 'hooks/usePrevious';
import { fetchProductRelations } from 'apis/calypso';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { flattenProductRelations } from 'helpers/RecoUtils';
import type { ProductWithRelationsFromCalypso } from 'types/calypso';
import { trackError } from 'helpers/ErrorUtils';
import type { AppState } from 'types/app';

const arrayEquals = (a: string[], b: string[]) =>
  Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val?.toLowerCase() === (b[index] || '').toLowerCase());

const useFetchProductRelation = (styleIds: string[]) => {
  const prevStyleIds = usePrevious(styleIds) || [];
  const calypso = useSelector((state: AppState) => state.environmentConfig.api.calypso);

  const [productRelations, setProductRelations] = useState<{ [s: string]: ProductWithRelationsFromCalypso }>();

  useEffect(() => {
    if (!arrayEquals(styleIds, prevStyleIds)) {
      const getRelations = async () => {
        const response: { results: ProductWithRelationsFromCalypso[] } = await fetchErrorMiddleware(
          (await fetchProductRelations(calypso, styleIds)) as Response
        );
        setProductRelations(flattenProductRelations(response.results));
      };
      if (styleIds) {
        getRelations().catch(e => {
          trackError('NON-FATAL', 'Could not get Product details', e);
        });
      }
    }
  }, [prevStyleIds, styleIds]);

  return [productRelations];
};

export default useFetchProductRelation;
