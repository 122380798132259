import { RECEIVE_RELATED_PRODUCTS } from 'constants/reduxActions';
import { toUSD } from 'helpers/NumberFormats';
import type { RelatedProductResponse, RelatedProductSizeGroupEntry } from 'types/cloudCatalog';
import type { RelatedProductAction } from 'actions/relatedProducts';

export interface ProcessedSizeGroupEntry {
  basePrice: string;
  imageId: RelatedProductSizeGroupEntry['imageId'];
  salePrice: string;
  seoUrl: RelatedProductSizeGroupEntry['seoUrl'];
  sizeGroupName: RelatedProductSizeGroupEntry['sizeGroupName'];
  styleId: RelatedProductSizeGroupEntry['styleId'];
}

export interface NoSizeGroups {
  hasSizeGroups: false;
}

export interface SomeSizeGroups {
  hasSizeGroups: true;
  productNameWithoutSizeGroup: string;
  groupListsByStyleId: Record<string, ProcessedSizeGroupEntry[]>;
}

export type SizeGroupsState = NoSizeGroups | SomeSizeGroups;

function processSizeGroup(sizeGroup: RelatedProductSizeGroupEntry): ProcessedSizeGroupEntry {
  return {
    basePrice: toUSD(sizeGroup.basePrice),
    imageId: sizeGroup.imageId,
    salePrice: toUSD(sizeGroup.salePrice),
    seoUrl: sizeGroup.seoUrl,
    sizeGroupName: sizeGroup.sizeGroupName,
    styleId: sizeGroup.styleId
  };
}

function processStyle(styles: Record<string, RelatedProductSizeGroupEntry[]>, styleId: string): ProcessedSizeGroupEntry[] {
  return styles[styleId]!.map(processSizeGroup);
}

function processStyles(styles: Record<string, RelatedProductSizeGroupEntry[]>): Record<string, ProcessedSizeGroupEntry[]> {
  const styleIds = Object.keys(styles);
  const reduceFn = (ret: Record<string, ProcessedSizeGroupEntry[]>, styleId: string) => {
    ret[styleId] = processStyle(styles, styleId);
    return ret;
  };
  return styleIds.reduce(reduceFn, {});
}

function processData(data: RelatedProductResponse): SizeGroupsState {
  const { sizeGroup } = data;

  if (!sizeGroup) {
    return { hasSizeGroups: false };
  }

  const { productNameWithoutSizeGroup, sizeGroupStyleMap } = sizeGroup;
  return {
    hasSizeGroups: true,
    productNameWithoutSizeGroup,
    groupListsByStyleId: processStyles(sizeGroupStyleMap)
  };
}

export default function sizeGroups(state = { hasSizeGroups: false } as const, action: RelatedProductAction): SizeGroupsState {
  return action.type === RECEIVE_RELATED_PRODUCTS ? processData(action.data) : state;
}
