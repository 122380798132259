import AddToBagDrawer from 'components/cart/AddToBagDrawer';
import CartModal from 'components/cart/CartModal';
import useKratosCheckoutEnabled from 'hooks/useKratosCheckoutEnabled';

export interface CartModalOrDrawerProps {
  isOpen: boolean;
}

function CartModalOrDrawer({ isOpen }: CartModalOrDrawerProps) {
  const isKratosCheckout = useKratosCheckoutEnabled();

  if (!isOpen) {
    return null;
  }

  return isKratosCheckout ? <AddToBagDrawer open /> : <CartModal />;
}

export default CartModalOrDrawer;
