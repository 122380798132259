import type CarouselHeaderProps from 'components/common/CarouselHeader/CarouselHeader.types';
import MartyLink from 'components/common/MartyLink';
import useMartyContext from 'hooks/useMartyContext';
import css from 'components/common/CarouselHeader/CarouselHeader.scss';
import { useInAssignment } from 'hooks/useHydra';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';
import { cn } from 'helpers/classnames';

const CarouselHeader = (props: CarouselHeaderProps) => {
  const { title, link, dataTestId } = props;
  const { testId } = useMartyContext();
  const hydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);

  if (!title && !link) {
    return null;
  }

  return (
    <div className={css.container} data-test-id={testId(dataTestId ?? 'carouselHeader')}>
      {title && (
        <h2
          className={cn(css.title, {
            ['text-[26px] sm:text-4xl']: hydraCefi
          })}
        >
          {title}
        </h2>
      )}
      {link && <MartyLink className={css.link} {...link} />}
    </div>
  );
};

export default CarouselHeader;
