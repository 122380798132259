import { MERCHANTID_MARKET_NAME_MAPPING } from 'helpers/MarketplaceUtils';

// TODO Overriding Catalog for vrsnl product until we don't override the useContext itself
export const cloudCatalogUsingMerchantId = (merchantId: string) => {
  if (MERCHANTID_MARKET_NAME_MAPPING[merchantId as keyof typeof MERCHANTID_MARKET_NAME_MAPPING] === 'versional') {
    return vrsnlCatalog;
  }
  return;
};

const vrsnlCatalog = {
  url: 'https://api.cloudcatalog.vrsnl.com',
  siteId: 20,
  subsiteId: 56
};

export default cloudCatalogUsingMerchantId;
