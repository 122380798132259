import { stringify } from 'query-string';

import timedFetch from 'middleware/timedFetch';
import { setSessionRequestedHeaders } from 'actions/session';
import { cookieObjectToString } from 'helpers/Cookie';
import type { CloudCatalogBrand, ProductBundleOptions, ProductBundleResponse, RelatedProductResponse } from 'types/cloudCatalog';
import type { Cookies } from 'types/cookies';
import { CLOUDCAT_MAX_HEART_IDS_PER_CALL } from 'constants/appConstants';

export interface CloudCatalogOptions {
  url: string;
  v2url: string;
  siteId: number;
  subsiteId: number;
}

const BASE_INCLUDES = ['preferredSubsite', 'hardLaunchDate', 'taxonomyAttributes'];
const IN_STOCK_INCLUDES = BASE_INCLUDES.concat(['drop', 'finalSale']);

interface headerObj {
  [key: string]: string;
}

interface fetchOptsObj {
  method: string;
  credentials: string;
  headers: headerObj;
  signal?: AbortSignal;
}

export const makeFetchOptions = function (cookies: Cookies, abortController?: AbortController) {
  const config: fetchOptsObj = {
    method: 'GET',
    credentials: 'include', // only good for clientside
    headers: {
      Cookie: cookieObjectToString(cookies) // only good for serverside
    },
    signal: abortController?.signal
  };

  return setSessionRequestedHeaders(config);
};

/**
 * Get detail for a specific product
 */
export function productBundle(
  { url, siteId, subsiteId }: CloudCatalogOptions,
  {
    asin,
    stockId,
    styleId,
    productId,
    includeTsdImages = false,
    includeOosSizing = false,
    includeOos = false,
    entireProduct = true,
    includeBrand = true,
    includeSizing = true,
    includeStocksWithoutAsins = false
  }: ProductBundleOptions,
  fetcher = timedFetch('cloudCatalogProduct'),
  cookies = {},
  abortController?: AbortController
): Promise<Response<ProductBundleResponse>> {
  const qs = stringify({
    asin,
    autolink: 'brandProductName',
    entireProduct,
    includeBrand,
    includeImages: true,
    includeOos,
    includeOosSizing,
    includeSizing,
    includeTsdImages,
    includeStocksWithoutAsins,
    includes: (includeOos ? BASE_INCLUDES : IN_STOCK_INCLUDES).join(','),
    productId,
    siteId,
    subsiteId,
    stockId,
    styleId
  });

  const fetchOpts = makeFetchOptions(cookies, abortController);
  const productUrl = `${url}/v3/productBundle?${qs}`;
  return fetcher(productUrl, fetchOpts);
}

/**
 * Get a list of all brands
 */
export function brandList(
  { url, siteId }: CloudCatalogOptions,
  fetcher = timedFetch('cloudCatalogBrandList')
): Promise<Response<CloudCatalogBrand[]>> {
  return fetcher(`${url}/v1/brandList?siteId=${siteId}`);
}

/**
 * Get products related to the one with the given product ID
 */
export function relatedProducts(
  { url, siteId }: CloudCatalogOptions,
  { productId = '' },
  fetcher = timedFetch('cloudCatalogRelatedProducts')
): Promise<Response<RelatedProductResponse>> {
  return fetcher(`${url}/v1/relatedProducts?productId=${productId}&siteId=${siteId}`);
}

/**
 * Split array in groups
 * @param {array} arr
 * @param {number} size
 */
export function splitArrayInGroup(arr: any[], size: number) {
  return arr.reduce((p, c, i) => (i % size ? p[p.length - 1]?.push(c) : p.push([c]), p), []);
}

/**
 * Returns count of likes for a product, defaulting to heart list
 * https://api.zcloudcat.com/v1/listItemCounts?siteId=1&type=h&
 */
export function likeCounts(
  { v2url, siteId }: CloudCatalogOptions,
  styleIds: string[],
  multiResponseMode: boolean,
  fetcher = timedFetch('likeCounts')
): Promise<Response<Record<string, number>>[]> {
  if (multiResponseMode) {
    const promises: Promise<Response<Record<string, number>>>[] = [];
    const splitStyles = splitArrayInGroup(styleIds, CLOUDCAT_MAX_HEART_IDS_PER_CALL);

    splitStyles.map((styles: string[]) => promises.push(fetcher(`${v2url}/listItemCounts?itemIds=${styles.join(',')}&siteId=${siteId}`)));

    return Promise.all(promises);
  }

  return fetcher(`${v2url}/listItemCounts?itemIds=${styleIds.join(',')}&siteId=${siteId}`);
}
