import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/no-namespace
import type * as hydraTests from 'constants/hydraTests';
import type { AppState } from 'types/app';
import type { ValuesOf } from 'types/utility';
import { isAssigned, triggerAssignment } from 'actions/ab';
import useEffectOnce from 'hooks/useEffectOnce';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';

type HydraTestValues = ValuesOf<typeof hydraTests>;

export const useHydraOnce = (
  hydraTest: HydraTestValues,
  { triggerCondition = () => true, assignment = 1 }: { triggerCondition?: () => boolean; assignment?: number } = {}
): boolean => {
  useTriggerAssignmentOnce(hydraTest, triggerCondition);
  return useInAssignment(hydraTest, assignment);
};

export const useTriggerAssignmentOnce = (hydraTest: HydraTestValues, triggerCondition = () => true) => {
  const dispatch: AppDispatch = useDispatch();
  useEffectOnce(() => {
    if (triggerCondition()) {
      dispatch(triggerAssignment(hydraTest));
    }
  });
};

/*
  You likely need to wrap your triggerCondition in a useCallback with proper deps array to send hydra assignment request on update.
  Otherwise the useEffect in useTriggerAssignment could fire on every render if triggerCondition is defined as an inline function that
*/
export const useHydraOnUpdate = (
  hydraTest: HydraTestValues,
  { triggerCondition, assignment = 1 }: { triggerCondition: () => boolean; assignment?: number }
): boolean => {
  useTriggerAssignmentOnUpdate(hydraTest, triggerCondition);
  return useInAssignment(hydraTest, assignment);
};

export const useTriggerAssignmentOnUpdate = (hydraTest: HydraTestValues, triggerCondition: () => boolean) => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    triggerCondition() && dispatch(triggerAssignment(hydraTest));
    // dispatch is stable for real redux store, but the mock store from test wrapper is not
    // so omit dispatch from dep array so it doesn't fire on every render in unit tests
  }, [triggerCondition, hydraTest]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useInAssignment = (hydraTest: HydraTestValues, assignment = 1): boolean =>
  useSelector((state: AppState) => isAssigned(hydraTest, assignment, state));

export const useTriggerAssignment = (): ((hydraTest: HydraTestValues, triggerCondition?: () => boolean) => void) => {
  const dispatch: AppDispatch = useDispatch();
  return (hydraTest: HydraTestValues, triggerCondition = () => true) => {
    if (triggerCondition()) {
      dispatch(triggerAssignment(hydraTest));
    }
  };
};
