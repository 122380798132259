import type { WebVitalEvent } from '@mweb/amethyst-client';
import { WEB_VITAL_APPS } from '@mweb/types';
import type { Metric } from 'web-vitals';
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';

import { track } from 'apis/amethyst';
import { WEB_VITAL } from 'constants/amethyst';

const evWebVital = ({ name, value }: WebVitalEvent) => ({
  [WEB_VITAL]: {
    app: WEB_VITAL_APPS.MARTY,
    [name]: value
  }
});

const trackMetric = (metric: Metric) => {
  track(() => [evWebVital, { name: metric.name.toLowerCase(), value: metric.value }]);
};

export default function reportWebVitals() {
  onCLS(trackMetric);
  onFCP(trackMetric);
  onLCP(trackMetric);
  onFID(trackMetric);
  onINP(trackMetric);
  onTTFB(trackMetric);
}
