import type { IToggle } from 'unleash-proxy-client';

import { selectHasKratosCheckoutCookie } from './cookies';

import marketplace from 'cfg/marketplace.json';
import type { AppState } from 'types/app';
import {
  FEATURE_BANNER_ADS,
  FEATURE_CHECKOUT_KRATOS,
  FEATURE_ENABLED_VARIANT_NAME_TREATMENT,
  FEATURE_MAFIA_AKAMAI,
  FEATURE_MARTY_CLIENT_RENDER,
  FEATURE_PDP_ACCORDION_ORDER
} from 'constants/features';

export const selectUnleashToggle = (feature: string, state: AppState): IToggle | undefined =>
  (state.unleash?.toggles || []).find((t: IToggle) => t.name === feature);

export const selectABTestEnabled = (testName: string, state: AppState, treatmentValue = '1') =>
  selectUnleashVariant(testName, state) === treatmentValue;

export const selectIsFeatureMafiaAkamai = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_MAFIA_AKAMAI, state)?.enabled);

export const selectIsFeatureMartyClientRender = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_MARTY_CLIENT_RENDER, state)?.enabled);

export const selectIsFeatureBannerAds = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_BANNER_ADS, state)?.enabled);

export const selectIsPdpAccordionOrder = (state: AppState) =>
  Boolean(selectUnleashToggle(FEATURE_PDP_ACCORDION_ORDER, state)?.variant.name === FEATURE_ENABLED_VARIANT_NAME_TREATMENT);

export const selectIsFeatureKratosCheckout = (state: AppState) =>
  Boolean(
    marketplace.hasKratosCheckout &&
      (selectHasKratosCheckoutCookie(state) ||
        selectUnleashToggle(FEATURE_CHECKOUT_KRATOS, state)?.variant.name === FEATURE_ENABLED_VARIANT_NAME_TREATMENT)
  );

export const selectUnleashVariant = (feature: string, state: AppState) => selectUnleashToggle(feature, state)?.variant.payload?.value;
