import React from 'react';

import { cn } from 'helpers/classnames';

interface Style {
  styleId: string;
}

interface Props {
  cssHeartActive: string;
  style: Style;
  cssHeartContainer?: string;
  extraRecoStyle?: string | null;
  handleHeartClick?: (...args: any[]) => any;
  isUninteractive?: boolean;
  showFavoriteHeart?: boolean;
  productId?: string;
  merchantId?: string;
  isDisplayCount?: boolean;
  testId?: string | null;
  isHearted: boolean;
  count: string | number;
}
const Heart = ({
  cssHeartActive,
  cssHeartContainer,
  extraRecoStyle = null,
  handleHeartClick = f => f,
  isUninteractive = false,
  showFavoriteHeart,
  style,
  productId,
  isDisplayCount = true,
  testId = null,
  isHearted = false,
  count = 0
}: Props) => {
  if (!showFavoriteHeart) {
    return null;
  }

  const ariaLabel = count === 1 ? `${count} person has favorited this.` : `${count} people have favorited this.`;

  if (isUninteractive) {
    return (
      <span data-test-id={testId} className={cn(cssHeartContainer, { [cssHeartActive]: isHearted }, extraRecoStyle)} aria-label={ariaLabel}>
        {count}
      </span>
    );
  }

  return (
    <button
      type="button"
      className={cn(cssHeartContainer, { [cssHeartActive]: isHearted }, extraRecoStyle)}
      aria-pressed={isHearted}
      aria-label="Favorite this item."
      onClick={handleHeartClick(style, productId)}
      data-test-id={testId}
    >
      {isDisplayCount && <span aria-label={ariaLabel}>{count}</span>}
    </button>
  );
};

export default Heart;
