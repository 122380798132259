import merge from 'lodash.merge';
import { ThemeProvider } from 'styled-components';

import type MartyThemeProviderProps from 'styled/containers/MartyThemeProvider/MartyThemeProvider.types';
import sixPmOverrides from 'styled/themes/6pm.json';
import vrsnlOverrides from 'styled/themes/vrsnl.json';
import zapposTheme from 'styled/themes/zappos.json';

const themes = {
  '6pm': merge({}, zapposTheme, sixPmOverrides),
  'vrsnl': merge({}, zapposTheme, vrsnlOverrides),
  'zappos': zapposTheme
} as const;

function MartyThemeProvider({ children, currentTheme }: MartyThemeProviderProps) {
  const theme = themes[currentTheme] ?? themes.zappos;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default MartyThemeProvider;
