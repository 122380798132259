import type { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { isBotUser } from 'helpers/UserAgentUtils';
import { getType } from 'history/historyFactory';
import { logError } from 'middleware/logger';

export interface RumConfig {
  guestRoleArn: string;
  identityPoolId: string;
  appMonitorId: string;
  region: string;
}

interface RumEvent {
  event: string;
  data: object;
}

interface RumPageView {
  pageId: string;
  pageTags: string[];
}
const CUSTOM_RUM_ERROR_EVENT = 'error_page_shown';

let queuedEvents: RumEvent[] = [];
let queuedPageView: RumPageView[] = [];

let awsRum: AwsRum;

export const injectRUM = async (rum: RumConfig, userAgent: string) => {
  const isAutomated = navigator.webdriver;
  const isBot = isBotUser(userAgent);

  /*
    Do not track Real User Metrics if:
    - awsRum: if RUM is already instantiated
    - !rum: there is no RUM config for marketplaces (vrsnl-zappos) or environments beta, preprod) where we aren't tracking RUM
    - isBot: traffic is using a bot user-agent
    - !!isAutomated traffic is not from an automation test suite
  */
  if (awsRum || !rum || isBot || !!isAutomated) {
    return;
  }

  const { appMonitorId, region, guestRoleArn, identityPoolId } = rum;

  try {
    const config: AwsRumConfig = {
      allowCookies: true,
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      guestRoleArn,
      identityPoolId,
      sessionSampleRate: 1,
      telemetries: ['errors'],
      disableAutoPageView: true // we track our page views with recordPageView https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-page-groups.html
    };

    const APPLICATION_ID: string = appMonitorId;
    const APPLICATION_VERSION: string = window.martyBuild;
    const APPLICATION_REGION: string = region;
    const { AwsRum } = await import('aws-rum-web');
    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    // fire off any queued events
    if (queuedEvents.length) {
      queuedEvents.forEach(({ event, data }) => {
        awsRum.recordEvent(event, data);
      });
      queuedEvents = [];
    }

    // fire off any queued page views
    if (queuedPageView.length) {
      queuedPageView.forEach(({ pageId, pageTags }) => {
        awsRum.recordPageView({ pageId, pageTags });
      });

      queuedPageView = [];
    }
  } catch (err) {
    logError(err);
  }
};

export const recordPageView = (pathname: string) => {
  const pageTag = getType(pathname) || 'unknown';

  if (!awsRum) {
    queuedPageView.push({ pageId: pathname, pageTags: [pageTag] });
  } else {
    awsRum.recordPageView({
      pageId: pathname,
      pageTags: [pageTag]
    });
  }
};

export const trackRumEvent = (event: string, data: object) => {
  // if firing a RUM event and awsRum is not available, queue event
  if (!awsRum) {
    queuedEvents.push({ event, data });
  } else {
    awsRum.recordEvent(event, data);
  }
};

export const trackErrorToRum = (error: any) => {
  if (typeof window !== 'undefined' && error) {
    trackRumEvent(CUSTOM_RUM_ERROR_EVENT, {
      page_type: getType(window.location.pathname) || 'unknown',
      status_code: error.statusCode || 'unknown',
      error_type: error.type || 'unknown'
    });
  }
};
