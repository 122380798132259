import type { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';
import ExecutionEnvironment from 'exenv';

import { track } from 'apis/amethyst';
import { evTopLevelNavigationClick } from 'events/headerFooter';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { pluralize } from 'helpers/index';
import useMartyContext from 'hooks/useMartyContext';
import { showCartModal } from 'actions/cart';
import { selectIsRemoteHF } from 'selectors/headerFooter';
import HeaderIconLink from 'components/hf/zappos/HeaderIconLink';
import UtilityStrokeShoppingCartMediumIcon from 'tailwind/components/Icons/UtilityStrokeShoppingCartMediumIcon';
import type { AppState } from 'types/app';
import usePrevious from 'hooks/usePrevious';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';
import useKratosCheckoutEnabled from 'hooks/useKratosCheckoutEnabled';

interface CartIconProps {
  headerCartTestId?: string;
  getCartCount?: () => ThunkAction<any, any, any, any>;
}

const selectCartInfo = createSelector(
  (state: AppState) => state?.cart,
  cart => ({ cartCount: cart?.cartCount })
);

export const CartIcon = ({ headerCartTestId = 'headerCart', getCartCount }: CartIconProps) => {
  const { testId } = useMartyContext();
  const isRemote = useSelector(selectIsRemoteHF);
  const isKratosCheckout = useKratosCheckoutEnabled();
  const { cartCount } = useSelector(selectCartInfo);
  const pathname = useSelector((state: AppState) => state?.router?.location?.pathname);
  const prevPathname = usePrevious(pathname);
  const dispatch = useDispatch<AppDispatch>();

  const hasLocationChanged = prevPathname !== pathname;
  if (getCartCount !== undefined && hasLocationChanged && ExecutionEnvironment.canUseDOM) {
    getCartCount();
  }

  const handleCartClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const canShowCartModal = window.location.pathname !== '/cart'; // suppress cart modal on /cart

    if (canShowCartModal && !isRemote && !isKratosCheckout) {
      e.preventDefault();
      dispatch(showCartModal(true));
    }

    trackEvent('TE_HEADER_CART');
    trackLegacyEvent('Global', 'Header', 'Cart');
    track(() => [
      evTopLevelNavigationClick,
      {
        valueClicked: 'Header Cart'
      }
    ]);
  };

  return (
    <HeaderIconLink
      href="/cart"
      data-test-id={testId(headerCartTestId)}
      onClick={handleCartClick}
      label={`Go to my cart. ${cartCount ? `${cartCount} ${pluralize('item', cartCount)} in cart.` : 'Cart empty.'}`}
    >
      <UtilityStrokeShoppingCartMediumIcon size={32} />
      {Number(cartCount) > 0 && (
        <div
          id="cartCount"
          className="absolute left-4 top-4 flex h-1 w-1 select-none items-center justify-center rounded-full bg-button-active p-3 text-button-reversed"
        >
          {cartCount}
        </div>
      )}
    </HeaderIconLink>
  );
};

export default CartIcon;
