import type { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import type { AccountMenuItem } from 'types/navigation';
import { clearCartLocalStorage } from 'helpers/CartUtils';
import { trackAccountMenuEvent } from 'utils/accountMenu';
import useMartyContext from 'hooks/useMartyContext';
import { selectRewardsInfo, selectSharedRewards } from 'selectors/rewards';
import { selectKillswitches } from 'selectors/killswitch';
import { selectShowInfluencerHub } from 'selectors/influencer';
import UtilityStrokeLogoutMediumIcon from 'tailwind/components/Icons/UtilityStrokeLogoutMediumIcon';

const selectAccountMenuRewardsInfo = createSelector([selectRewardsInfo, selectSharedRewards], (rewardsInfo, sharedRewards) => ({
  hasRewardsInfo: !!rewardsInfo,
  canEnroll: rewardsInfo?.canEnroll,
  consented: rewardsInfo?.consented,
  enrolled: rewardsInfo?.enrolled,
  isVip: rewardsInfo && sharedRewards && (rewardsInfo.isVipOrConsented || sharedRewards.isEnrollingRewards)
}));

export const useAccountMenu = (): AccountMenuItem[] => {
  const {
    marketplace: {
      account: { vipDashboardUrl },
      desktopBaseUrl
    }
  } = useMartyContext();

  const rewardsInfo = useSelector(selectAccountMenuRewardsInfo);
  const { showReturnsOrdersDropdownLink } = useSelector(selectKillswitches);
  const showInfluencerHub = useSelector(selectShowInfluencerHub);

  const { canEnroll, consented, enrolled, hasRewardsInfo, isVip } = rewardsInfo || {};
  const isRewardsMember = (enrolled && consented) || isVip;
  const canEnrollVerbiage = canEnroll && 'Sign up for Zappos VIP';
  const dashBoardLinkText = isRewardsMember ? 'VIP Dashboard' : canEnrollVerbiage;
  const hasRewards = !!dashBoardLinkText || !hasRewardsInfo;
  const rewardsLinkText = dashBoardLinkText || '';

  const accountMenu: AccountMenuItem[] = [];

  if (hasRewards) {
    const rewardsMenuItem: AccountMenuItem = {
      dataTestId: 'headerRewards',
      link: vipDashboardUrl,
      text: rewardsLinkText,
      onClick: () => trackAccountMenuEvent('TE_HEADER_ACCOUNT_MYACCOUNT', 'MyAccount', 'Account Overview')
    };

    accountMenu.push(rewardsMenuItem);
  }

  const accountOverviewMenuItem: AccountMenuItem = {
    dataTestId: 'headerAccountOverview',
    link: '/account',
    text: 'Account Overview',
    onClick: () => trackAccountMenuEvent('TE_HEADER_ACCOUNT_REWARDS', 'Rewards', 'Rewards')
  };

  accountMenu.push(accountOverviewMenuItem);

  if (showInfluencerHub) {
    const influencerMenuItem: AccountMenuItem = {
      dataTestId: 'influencerHub',
      link: '/influencer/hub',
      text: 'Influencer Hub',
      onClick: () => trackAccountMenuEvent('TE_HEADER_ACCOUNT_INFLUENCER', 'InfluencerHub', 'Influencer Hub')
    };

    accountMenu.push(influencerMenuItem);
  }

  const ordersMenuItem: AccountMenuItem = {
    dataTestId: 'headerOrderHistory',
    link: '/orders',
    text: showReturnsOrdersDropdownLink ? 'View Orders/Return' : 'Order History',
    onClick: () => trackAccountMenuEvent('TE_HEADER_ACCOUNT_ORDER_HISTORY', 'OrderHistory', 'Order History')
  };

  accountMenu.push(ordersMenuItem);

  const signOutMenuItem: AccountMenuItem = {
    dataTestId: 'headerSignOut',
    Icon: UtilityStrokeLogoutMediumIcon,
    link: '/logout',
    text: 'Sign Out',
    onClick: (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      clearCartLocalStorage();
      trackAccountMenuEvent('TE_HEADER_ACCOUNT_SIGNOUT', 'SignOut', 'Sign Out');
      window.location.href = `${desktopBaseUrl}/logout`;
    }
  };

  accountMenu.push(signOutMenuItem);

  return accountMenu;
};
