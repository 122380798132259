import React from 'react';

import { cn } from 'helpers/classnames';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import type { QnaItem } from 'components/VisualSearch/VisualSearchResults/visualSearchResults.types';
import css from 'components/VisualSearch/VisualSearchResults/AboutSearchByPhoto/aboutSearchByPhoto.scss';
import MelodyModal from 'components/common/MelodyModal';
import {
  ABOUT_VS_QNA_HOW_TO_USE,
  ABOUT_VS_QNA_NETWORK_ERROR,
  ABOUT_VS_QNA_RESULTS_NOT_AS_EXPECTED,
  ABOUT_VS_QNA_WHAT_IS_SEARCH_BY_PHOTO,
  ABOUT_VS_QNA_WHY_NOT_RECOGNIZE_ALL_PIECES
} from 'constants/visualSearch';

interface Props {
  closeAboutSearchByPhotoModal: () => void;
}

export const AboutSearchByPhoto = ({ closeAboutSearchByPhotoModal }: Props) => {
  const aboutVSQnA = (qna: QnaItem) => {
    const { question, answer } = qna;
    return (
      <div className={css.qna}>
        <span className={css.question}>{question}</span>
        {answer}
      </div>
    );
  };
  return (
    <MelodyModal className={css.modal} heading="About Search By Photo" isOpen={true} onRequestClose={closeAboutSearchByPhotoModal}>
      <div className={cn(css.qnaContainer, css.noBackground)}>
        {aboutVSQnA(ABOUT_VS_QNA_WHAT_IS_SEARCH_BY_PHOTO)}
        {aboutVSQnA(ABOUT_VS_QNA_HOW_TO_USE)}
        {aboutVSQnA(ABOUT_VS_QNA_WHY_NOT_RECOGNIZE_ALL_PIECES)}
        {aboutVSQnA(ABOUT_VS_QNA_RESULTS_NOT_AS_EXPECTED)}
        {aboutVSQnA(ABOUT_VS_QNA_NETWORK_ERROR)}
      </div>
    </MelodyModal>
  );
};

export default withErrorBoundary('AboutSearchByPhoto', AboutSearchByPhoto);
