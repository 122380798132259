// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { LargeIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeCameraLargeIcon = (props: LargeIconProps) => (
  <Icon {...props} viewBox="0 0 64 64">
    <path
      d="M10.5625 23.7969H13.8438M58.3594 18.2344V52.3281C58.3594 52.8804 57.9117 53.3281 57.3594 53.3281H6.64062C6.08834 53.3281 5.64062 52.8804 5.64062 52.3281V18.2344C5.64062 17.6821 6.08834 17.2344 6.64063 17.2344H18.257C18.6357 17.2344 18.982 17.0204 19.1514 16.6816L21.8799 11.2247C22.0493 10.8859 22.3955 10.6719 22.7743 10.6719H41.2257C41.6045 10.6719 41.9508 10.8859 42.1201 11.2247L44.8486 16.6816C45.018 17.0204 45.3643 17.2344 45.743 17.2344H57.3594C57.9117 17.2344 58.3594 17.6821 58.3594 18.2344ZM45.125 33.6406C45.125 40.8893 39.2487 46.7656 32 46.7656C24.7513 46.7656 18.875 40.8893 18.875 33.6406C18.875 26.3919 24.7513 20.5156 32 20.5156C39.2487 20.5156 45.125 26.3919 45.125 33.6406ZM32 40.2031C28.3814 40.2031 25.4375 37.2592 25.4375 33.6406C25.4375 30.0221 28.3814 27.0781 32 27.0781C35.6186 27.0781 38.5625 30.0221 38.5625 33.6406C38.5625 37.2592 35.6186 40.2031 32 40.2031Z"
      stroke="currentcolor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Icon>
);

export default UtilityStrokeCameraLargeIcon;
