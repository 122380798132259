import type { AppState } from 'types/app';
import { emptyArray, emptyObject } from 'selectors/constants';
import { selectIsFeatureMafiaAkamai } from 'selectors/features';
import marketplace from 'cfg/marketplace.json';

export const selectEnvironment = (state: AppState) => state.environmentConfig || emptyArray;

export const selectApiConfig = (state: AppState) => selectEnvironment(state).api || emptyObject;

export const selectCloudCatalogApi = (state: AppState) => selectEnvironment(state).api.cloudcatalog || emptyObject;

export const selectIsMockApi = (state: AppState) => selectEnvironment(state).isMockApi;

export const selectVisualSearchMafiaURL = (state: AppState) => selectEnvironment(state).visualSearch?.mafiaBaseUrl;

export const selectOpalApi = (state: AppState) => selectEnvironment(state).api.opal || {};

export const selectMafiaConfig = (state: AppState) => {
  const isFeatureMafiaAkamai = selectIsFeatureMafiaAkamai(state);
  const mafiaConfig = selectApiConfig(state).mafia || emptyObject;

  // Fallback if state values are not available
  if (!Object.keys(mafiaConfig).length) {
    return { ...mafiaConfig, url: marketplace.api.mafia.url };
  }

  return { ...mafiaConfig, url: isFeatureMafiaAkamai ? mafiaConfig.akamaiUrl : mafiaConfig.url };
};

export const selectVisualSearchMafiaConfig = (state: AppState) => {
  const mafiaConfig = selectApiConfig(state).mafia || emptyObject;
  const mafiaBaseUrl = selectVisualSearchMafiaURL(state) ?? marketplace.api.mafia.url;

  return { ...mafiaConfig, url: mafiaBaseUrl };
};

export const selectAccountConfig = (state: AppState) => {
  const isFeatureMafiaAkamai = selectIsFeatureMafiaAkamai(state);
  const accountConfig = selectApiConfig(state).account || emptyObject;

  // Fallback if state values are not available
  if (!Object.keys(accountConfig).length) {
    return { ...accountConfig, url: isFeatureMafiaAkamai ? marketplace.api.mafia.akamaiUrl : marketplace.api.mafia.url };
  }

  return { ...accountConfig, url: isFeatureMafiaAkamai ? accountConfig.akamaiUrl : accountConfig.url };
};
