import React, { useState } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import { onRemoveFromCart } from 'store/ducks/cart/actions';
import { setFederatedLoginModalVisibility } from 'actions/headerfooter';
import useMartyContext from 'hooks/useMartyContext';
import { cartIsLoading, changeQuantity, showCartModal } from 'actions/cart';
import { toUSD } from 'helpers/NumberFormats';
import ProductUtils from 'helpers/ProductUtils';
import ProductImage from 'components/cart/ProductImage';
import CartQuantityDropdown from 'components/cart/CartQuantityDropdown';
import { SmallLoader } from 'components/Loader';
import ItemDescription from 'components/cart/ItemDescription';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import FinalSale from 'components/common/FinalSale';
import type { CartItem as CartItemType } from 'types/mafia';
import type { AppState } from 'types/app';

import css from 'styles/components/cart/cartItem.scss';

interface OwnProps {
  children?: React.ReactNode;
  className?: string;
  hasFullDetails?: boolean;
  hideFavorite?: boolean;
  allowAdjustQuantity?: boolean;
  isFavorite?: boolean;
  isModal?: boolean;
  isRecommendedFit?: boolean;
  isUnavailable?: boolean;
  item: CartItemType | any; // TODO ts type this to heart item once cloudlist API is typed
  showFixedQuantity?: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

export const CartItem = ({
  cartIsLoading,
  changeQuantity,
  children,
  className,
  hasFullDetails = true,
  isFavorite = false,
  isModal = false,
  isRecommendedFit = false,
  isUnavailable = false,
  item,
  onRemoveFromCart,
  showFixedQuantity = false
}: Props) => {
  const [updating, setUpdating] = useState<void | string>();
  const { testId } = useMartyContext();

  const { asin, egc, finalSale, merchantId, originalPrice, price, quantity, stock: itemStock } = item;

  const stock = isFavorite ? 1 : quantity || 1;
  const availableAmount = itemStock || quantity;

  const onItemUpdate = (asin: string) => {
    cartIsLoading();
    setUpdating(asin);
  };

  const onItemUpdateDone = () => {
    setUpdating();
  };

  const onRemoveItem = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
    const { asin } = currentTarget.dataset;

    if (asin) {
      onItemUpdate(asin);
      const itemData = { asin, quantity: 0 };
      changeQuantity({ items: [itemData] });
      onRemoveFromCart(asin, true);
    }
  };

  const makeCartActions = () => {
    if (!hasFullDetails) {
      return null;
    }

    const { asin } = item;

    if (showFixedQuantity) {
      return <p className={cn(css.actions, css.fixedQuantity)}>Quantity: {showFixedQuantity}</p>;
    }

    return (
      <div className={cn(css.actions, { [css.noQuantity]: egc || isUnavailable })}>
        {<CartQuantityDropdown item={item} onChangeQuantityCb={onItemUpdate} onChangeQuantityCbDone={onItemUpdateDone} />}

        <button type="button" aria-label="Remove Item" onClick={onRemoveItem} data-test-id={testId('removeButton')} data-asin={asin}>
          Remove
        </button>
      </div>
    );
  };

  const makePrice = () => {
    if (isUnavailable) {
      return (
        <em className={css.unavailable} data-test-id={testId('unavailable')}>
          Unavailable
        </em>
      );
    }

    const isDiscounted = ProductUtils.isStyleOnSale({ originalPrice, price });

    return (
      <>
        <em className={cn(css.price, { [css.discount]: isDiscounted })} data-test-id={testId('price')}>
          {toUSD(stock * price)}
        </em>
        {isDiscounted && (
          <span className={css.msrp} data-test-id={testId('msrpPrice')}>
            {toUSD(stock * originalPrice)}
          </span>
        )}
        {stock !== 1 && <span className={css.each}>{toUSD(price)} each</span>}
        {finalSale && <FinalSale />}
      </>
    );
  };

  const testIdName = egc ? 'giftCardItem' : isFavorite ? 'favItem' : 'cartItem';

  const makeLowStockWarning = (className?: string) =>
    !isUnavailable && availableAmount > 0 && availableAmount < 4 && <span className={cn(css.lowStock, className)}>{availableAmount} left</span>;

  return (
    <div data-test-id={testId(testIdName)} className={cn(className, css.container, { [css.compress]: isModal })}>
      {asin === updating && (
        <div className={css.updating}>
          <SmallLoader />
        </div>
      )}

      <div className={css.imageDescriptionContainer}>
        <ProductImage tabIndex={-1} ariaHidden={true} isModal={isModal} item={item} showFixedQuantity={showFixedQuantity} merchantId={merchantId}>
          {makeLowStockWarning()}
        </ProductImage>
        <div className={css.descriptionPriceContainer}>
          <ItemDescription isModal={isModal} item={item} isRecommendedFit={isRecommendedFit} merchantId={merchantId} />
          {makeLowStockWarning('sr-only')}
          <div className={css.priceContainer}>{makePrice()}</div>
        </div>
      </div>

      <div className={css.actionsPriceContainer}>
        <div className={css.priceContainer}>
          {makePrice()}
          {children}
        </div>
        {makeCartActions()}
      </div>
    </div>
  );
};

export function mapStateToProps(state: AppState) {
  const { cart: { isMultiMerchantCart, addedStockId = undefined } = {}, cookies } = state;
  const isCustomer = !!cookies['x-main'];

  return {
    addedStockId,
    isCustomer,
    isMultiMerchantCart
  };
}

const mapDispatchToProps = {
  cartIsLoading,
  changeQuantity,
  setFederatedLoginModalVisibility,
  showCartModal,
  onRemoveFromCart
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ConnectedCartItem = connector(CartItem);
export default withErrorBoundary('CartItem', ConnectedCartItem);
