import { useCallback, useEffect, useState } from 'react';

import UtilityStrokeArrowRightMediumIcon from 'tailwind/components/Icons/UtilityStrokeArrowRightMediumIcon';
import { cn } from 'helpers/classnames';
import type CarouselControlsProps from 'tailwind/components/CarouselControls/CarouselControls.types';
import IconButton from 'tailwind/components/IconButton/IconButton';
import useMartyContext from 'hooks/useMartyContext';

const calculateDotsTransform = (selectedIndex: number) => {
  const indexToStartPagination = 3;
  const dotWidth = 14;
  return `translateX(-${Math.max(0, (selectedIndex - indexToStartPagination) * dotWidth)}px)`;
};

const DotsPaginationWrapper = ({ children, selectedIndex, isActive }: { children: React.ReactNode; selectedIndex: number; isActive: boolean }) => {
  if (!isActive) {
    return children;
  }

  return (
    <div className="transition-transform delay-200 ease-linear" style={{ transform: calculateDotsTransform(selectedIndex) }}>
      {children}
    </div>
  );
};

const CarouselControls = (props: CarouselControlsProps) => {
  const { emblaApi, isAutoHidden = true, variant = 'outlined', size = 'base', hydraCefiEnabled = false } = props;
  const { testId } = useMartyContext();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onInit = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    setScrollSnaps(emblaApi.scrollSnapList());
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }
    onInit();
    onSelect();
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  if (isAutoHidden && scrollSnaps.length === 1) {
    // do not show controls if theres only 1 page
    return null;
  }

  return (
    <div className="flex items-center justify-center" data-test-id={testId('carouselControls')}>
      <IconButton
        type="button"
        label="Previous"
        onClick={scrollPrev}
        disabled={prevBtnDisabled}
        data-test-id={testId('carouselControlsLeftArrow')}
        variant={variant}
        size={size}
        className="mr-[30px] md:mr-[60px]"
      >
        <UtilityStrokeArrowRightMediumIcon
          size={24}
          className={cn('rotate-180', {
            ['text-button-disabled-minimal']: prevBtnDisabled && variant === 'outlined'
          })}
        />
      </IconButton>
      <div
        className={cn('max-w-[100px] md:max-w-full', {
          ['overflow-hidden whitespace-nowrap']: hydraCefiEnabled
        })}
      >
        <DotsPaginationWrapper selectedIndex={selectedIndex} isActive={hydraCefiEnabled}>
          {scrollSnaps.map((_, index) => (
            <button
              type="button"
              key={index}
              aria-label={`Page ${index + 1}`}
              onClick={() => scrollTo(index)}
              className={cn('mx-[4px] my-0 h-[6px] cursor-pointer rounded-full border-none bg-button-disabled-subtle p-0', {
                ['w-[20px] rounded-2xl bg-button-selected']: index === selectedIndex,
                ['w-[6px]']: index !== selectedIndex
              })}
              data-test-id={testId('carouselPageDot')}
            />
          ))}
        </DotsPaginationWrapper>
      </div>
      <IconButton
        type="button"
        label="Next"
        onClick={scrollNext}
        disabled={nextBtnDisabled}
        data-test-id={testId('carouselControlsRightArrow')}
        variant={variant}
        size={size}
        className="ml-[30px] md:ml-[60px]"
      >
        <UtilityStrokeArrowRightMediumIcon size={24} />
      </IconButton>
    </div>
  );
};

export default CarouselControls;
