import type { Mafia } from 'types/mafia';
import type { Cookies } from 'types/cookies';
import timedFetch from 'middleware/timedFetch';
import { fetchOpts, sendJsonDataWithCreds } from 'apis/mafia/common';
import type { InfluencerContentAPIAction } from 'types/influencer';

/** Zappos Influencer APIs starts */
const commonInfluencerServiceHeaders = () => ({
  'X-Marty-InfAppEnv-Id': window.location.hostname
});

export function enrollInfluencer({ url }: Mafia, data: any, credentials: Cookies = {}, fetcher = timedFetch('enrollInfluencer')) {
  return sendJsonDataWithCreds(`${url}/influencer/enroll`, data, {
    credentials,
    fetcher,
    method: 'post',
    additionalHeaders: commonInfluencerServiceHeaders()
  });
}

export function getInfluencerAppConfigurations({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getInfluencerAppConfigurations')) {
  return fetcher(
    `${url}/influencer/apps`,
    fetchOpts(
      {
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function getInfluencerStatus({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getInfluencerStatus')) {
  return fetcher(
    `${url}/influencer/status`,
    fetchOpts(
      {
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function getInfluencerFollowersStatus(
  { url }: Mafia,
  influencerId: string,
  credentials: Cookies = {},
  fetcher = timedFetch('getInfluencerFollowersStatus')
) {
  return fetcher(
    `${url}/influencer/followStatus/${influencerId}`,
    fetchOpts(
      {
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function updateInfluencerFollowingStatus(
  { url }: Mafia,
  data: any,
  influencerId: string,
  credentials: Cookies = {},
  fetcher = timedFetch('updateInfluencerFollowingStatus')
) {
  return sendJsonDataWithCreds(`${url}/influencer/updateFollowStatus`, data, {
    credentials,
    fetcher,
    method: 'post',
    additionalHeaders: commonInfluencerServiceHeaders()
  });
}

export function getInfluencerDetails({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getInfluencerDetails')) {
  return fetcher(
    `${url}/influencer/details`,
    fetchOpts(
      {
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function getInfluencerDetailsByIdentifier(
  { url }: Mafia,
  identifier: string,
  identifierType: string,
  credentials: Cookies = {},
  host: string,
  fetcher = timedFetch('getInfluencerDetailsByIdentifier')
) {
  const headers = host ? { 'X-Marty-InfAppEnv-Id': host } : commonInfluencerServiceHeaders();
  return fetcher(
    `${url}/influencer/details/${identifier}?identifierType=${identifierType}`,
    fetchOpts(
      {
        headers
      },
      credentials
    )
  );
}

export function getInfluencerDetailsByIdentifierOnServer(
  { url }: Mafia,
  identifier: any,
  identifierType: any,
  credentials: Cookies = {},
  host: any,
  fetcher = timedFetch('getInfluencerDetailsByIdentifierOnServer')
) {
  return fetcher(
    `${url}/influencer/details/${identifier}?identifierType=${identifierType}`,
    fetchOpts(
      {
        headers: { 'X-Marty-InfAppEnv-Id': host }
      },
      credentials
    )
  );
}

export function getInfluencerStyles({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getInfluencerStyles')) {
  return fetcher(
    `${url}/influencer/styles`,
    fetchOpts(
      {
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function validateProfileHandle(
  { url }: Mafia,
  profileHandle: string,
  credentials: Cookies = {},
  fetcher = timedFetch('validateProfileHandle')
) {
  return fetcher(
    `${url}/influencer/uniqueHandle/${profileHandle}`,
    fetchOpts(
      {
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function getInfluencerToken({ url }: Mafia, data: any, credentials: Cookies = {}, fetcher = timedFetch('getInfluencerToken')) {
  return sendJsonDataWithCreds(`${url}/influencer/links`, data, {
    credentials,
    fetcher,
    method: 'post',
    additionalHeaders: commonInfluencerServiceHeaders()
  });
}

export function getConfirmPendingStatus({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getConfirmPendingStatus')) {
  return fetcher(
    `${url}/influencer/confirmPendingStatus`,
    fetchOpts(
      {
        method: 'post',
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function getConfirmDeclineStatus({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getConfirmDeclineStatus')) {
  return fetcher(
    `${url}/influencer/confirmDeclineStatus`,
    fetchOpts(
      {
        method: 'post',
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function getInfluencerSummaryReport(
  { url }: Mafia,
  credentials: Cookies = {},
  timePeriod: string,
  fetcher = timedFetch('getInfluencerSummaryReport')
) {
  return fetcher(
    `${url}/influencer/reporting/summary?period=${timePeriod}`,
    fetchOpts(
      {
        method: 'get',
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function getInfluencerProductReport(
  { url }: Mafia,
  credentials: Cookies = {},
  timePeriod: string,
  page: number,
  pageLength: number,
  fetcher = timedFetch('getInfluencerProductReport')
) {
  return fetcher(
    `${url}/influencer/reporting/products?period=${timePeriod}&page=${page}&pageLength=${pageLength}`,
    fetchOpts(
      {
        method: 'get',
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function getInfluencerCollectionReport(
  { url }: Mafia,
  credentials: Cookies = {},
  timePeriod: string,
  page: number,
  pageLength: number,
  fetcher = timedFetch('getInfluencerCollectionReport')
) {
  return fetcher(
    `${url}/influencer/reporting/collections?period=${timePeriod}&page=${page}&pageLength=${pageLength}`,
    fetchOpts(
      {
        method: 'get',
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function addInfluencerSocialProfile(
  { url }: Mafia,
  data: any,
  credentials: Cookies = {},
  fetcher = timedFetch('addInfluencerSocialProfile')
) {
  return sendJsonDataWithCreds(`${url}/influencer/addsocialprofile`, data, {
    credentials,
    fetcher,
    method: 'post',
    additionalHeaders: commonInfluencerServiceHeaders()
  });
}

export function updateInfluencerSocialProfile(
  { url }: Mafia,
  data: any,
  credentials: Cookies = {},
  fetcher = timedFetch('updateInfluencerSocialProfile')
) {
  return sendJsonDataWithCreds(`${url}/influencer/updatesocialprofile`, data, {
    credentials,
    fetcher,
    method: 'post',
    additionalHeaders: commonInfluencerServiceHeaders()
  });
}

export function updateInfluencerDetails({ url }: Mafia, data: any, credentials: Cookies = {}, fetcher = timedFetch('updateInfluencerDetails')) {
  return sendJsonDataWithCreds(`${url}/influencer/details`, data, {
    credentials,
    fetcher,
    method: 'post',
    additionalHeaders: commonInfluencerServiceHeaders()
  });
}

/** Zappos Influencer Content APIs */

export function getPresignedUrl({ url }: Mafia, data: any, credentials: Cookies = {}, fetcher = timedFetch('getPresignedUrl')) {
  return sendJsonDataWithCreds(`${url}/influencer/media/create/presignedurl`, data, {
    credentials,
    fetcher,
    method: 'post',
    additionalHeaders: commonInfluencerServiceHeaders()
  });
}

// TODO: update mock response once backend is ready
export function initiateScrutinizerScanRequest(
  { url }: Mafia,
  data: any,
  credentials: Cookies = {},
  fetcher = timedFetch('initiateScrutinizerScanRequest')
) {
  return sendJsonDataWithCreds(`${url}/influencer/media/scrutinizer/scan/initiate`, data, {
    credentials,
    fetcher,
    method: 'post',
    additionalHeaders: commonInfluencerServiceHeaders()
  });
}

export function fetchScrutinizerScanStatus(
  { url }: Mafia,
  requestId: string,
  credentials: Cookies = {},
  fetcher = timedFetch('fetchScrutinizerScanStatus')
) {
  return fetcher(
    `${url}/influencer/media/scrutinizer/scan/status/${requestId}`,
    fetchOpts(
      {
        method: 'get',
        headers: commonInfluencerServiceHeaders()
      },
      credentials
    )
  );
}

export function uploadToMediaCentral({ url }: Mafia, data: any, credentials: Cookies = {}, fetcher = timedFetch('uploadToMediaCentral')) {
  return sendJsonDataWithCreds(`${url}/influencer/media/upload`, data, {
    credentials,
    fetcher,
    method: 'post',
    additionalHeaders: commonInfluencerServiceHeaders()
  });
}

export function uploadToVMS({ url }: Mafia, data: any, credentials: Cookies = {}, fetcher = timedFetch('uploadToVMS')) {
  return sendJsonDataWithCreds(`${url}/influencercontent/content/upload/video`, data, {
    credentials,
    fetcher,
    method: 'post'
  });
}

export function getVideoFromVMS({ url }: Mafia, mediaId: string, credentials: Cookies = {}, fetcher = timedFetch('getVideoFromVMS')) {
  return fetcher(
    `${url}/influencercontent/content/video/${mediaId}`,
    fetchOpts(
      {
        method: 'get'
      },
      credentials
    )
  );
}

export function putInfluencerMedia(url: string, file: string, credentials: Cookies = {}, fetcher = timedFetch('PutInfluencerMedia')) {
  return fetcher(
    url,
    fetchOpts(
      {
        method: 'put',
        body: file
      },
      credentials
    )
  );
}

export function createOrUpdateShoppablePost(
  { url }: Mafia,
  data: any,
  method: string,
  credentials: Cookies = {},
  fetcher = timedFetch('saveAndSubmitShoppablePost')
) {
  return sendJsonDataWithCreds(`${url}/influencercontent/posts`, data, {
    credentials,
    fetcher,
    method
  });
}

export function getOrDeleteShoppablePost(
  { url }: Mafia,
  postId: string,
  postState: string,
  method: string,
  credentials: Cookies = {},
  fetcher = timedFetch('discardShoppablePost')
) {
  return fetcher(
    `${url}/influencercontent/posts/${postId}/${postState}`,
    fetchOpts(
      {
        method
      },
      credentials
    )
  );
}

export function getAllShoppablePosts(
  { url }: Mafia,
  influencerId: string,
  queryString = '',
  authRequired: boolean,
  credentials: Cookies = {},
  fetcher = timedFetch('getAllShoppablePosts')
) {
  const shoppablePostUrl = authRequired
    ? `${url}/influencercontent/posts/influencer/${influencerId}?${queryString}`
    : `${url}/influencercontent/n/posts/influencer/${influencerId}/approved?${queryString}`;

  return fetcher(
    shoppablePostUrl,
    fetchOpts(
      {
        method: 'get'
      },
      credentials
    )
  );
}

export function performInfluencerContentAction(
  { url }: Mafia,
  contentId: string,
  data: any,
  action: InfluencerContentAPIAction,
  credentials: Cookies = {},
  fetcher = timedFetch('hideInfluencerContent')
) {
  return sendJsonDataWithCreds(`${url}/influencercontent/content/${action}/${contentId}`, data, {
    credentials,
    fetcher,
    method: 'post'
  });
}

export function createAndSubmitInfluencerContent(
  { url }: Mafia,
  data: any,
  credentials: Cookies = {},
  fetcher = timedFetch('createAndSubmitInfluencerContent')
) {
  return sendJsonDataWithCreds(`${url}/influencercontent/content`, data, {
    credentials,
    fetcher,
    method: 'post'
  });
}

export function getAllInfluencerContent(
  { url }: Mafia,
  influencerId: string,
  queryString = '',
  authRequired: boolean,
  credentials: Cookies = {},
  fetcher = timedFetch('getAllInfluencerContent')
) {
  const getAllContentUrl = authRequired
    ? `${url}/influencercontent/content/influencer/${influencerId}?${queryString}`
    : `${url}/influencercontent/n/content/influencer/${influencerId}/approved?${queryString}`;
  return fetcher(
    getAllContentUrl,
    fetchOpts(
      {
        method: 'get'
      },
      credentials
    )
  );
}

export function getPostsLikeStatus({ url }: Mafia, queryString = '', credentials: Cookies = {}, fetcher = timedFetch('getPostsLikeStatus')) {
  const getAllPostIdStatusUrl = `${url}/influencercontent/likeStatus/posts?${queryString}`;
  return fetcher(
    getAllPostIdStatusUrl,
    fetchOpts(
      {
        method: 'get'
      },
      credentials
    )
  );
}

export function updatePostLikeStatus(
  { url }: Mafia,
  postId: string,
  method: string,
  credentials: Cookies = {},
  fetcher = timedFetch('updatePostLikeStatus')
) {
  return fetcher(
    `${url}/influencercontent/likeStatus/${postId}`,
    fetchOpts(
      {
        method
      },
      credentials
    )
  );
}

export function getApprovedShoppablePost({ url }: Mafia, postId: string, credentials: Cookies = {}, fetcher = timedFetch('getShoppablePost')) {
  return fetcher(
    `${url}/influencercontent/n/posts/${postId}/approved`,
    fetchOpts(
      {
        method: 'get'
      },
      credentials
    )
  );
}

export function searchInfluencerContent(
  { url }: Mafia,
  queryString = '',
  credentials: Cookies = {},
  fetcher = timedFetch('searchInfluencerContent')
) {
  return fetcher(`${url}/influencercontent/n/content/search?${queryString}`, fetchOpts({ method: 'get' }, credentials));
}

export function getAllContentWithPagination({ url }: Mafia, queryString = '', credentials: Cookies = {}, fetcher = timedFetch('paginatedResponse')) {
  return fetcher(`${url}/influencercontent/n/content/approved?${queryString}`, fetchOpts({ method: 'get' }, credentials));
}

export async function getInfluencerHashtags({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getInfluencerHashtags')) {
  return fetcher(`${url}/influencercontent/n/tags`, fetchOpts({}, credentials));
}
