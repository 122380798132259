import type { AppState } from 'types/app';

export const selectInfluencer = (state: AppState) => state.influencer;

export const selectIsInfluencer = (state: AppState) => selectInfluencer(state).isInfluencer;

export const selectShowInfluencerHub = (state: AppState) => selectInfluencer(state).showInfluencerHub;

export const selectInfluencerContent = (state: AppState) => state.influencerContent;

export const selectQueryParams = (state: AppState) => state.router.location.search;
