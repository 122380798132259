import { handleGetInfluencerDetailsByIdentifier, uploadInfluencerImage, uploadInfluencerVideo } from 'actions/influencer/influencer';
import { setInfluencerShoppableContentEmpty, updateCustomerPostLikedStatus } from 'actions/influencer/influencerContent';
import { authenticationErrorCatchHandlerFactory, setError } from 'actions/errors';
import { redirectTo } from 'actions/redirect';
import { productBundle } from 'apis/cloudcatalog';
import { getOrdersBySearch, getOrderV3 } from 'apis/mafia';
import { getInfluencerCollectionSearch } from 'apis/account';
import { trackError } from 'helpers/ErrorUtils';
import { guid } from 'helpers/guid';
import { prependAppRoot } from 'history/AppRootUtils';
import timedFetch from 'middleware/timedFetch';
import {
  ERROR_NOT_AUTHENTICATED,
  fetchApiNotAuthenticatedMiddleware,
  fetchErrorMiddleware,
  fetchErrorMiddlewareAllowedErrors
} from 'middleware/fetchErrorMiddleware';
import cloudCatalogUsingMerchantId from 'helpers/cloudCatalogUsingMerchantId';
import { ContentState, InfluencerContentAPIAction, ScrutinizerScanState, VideoState } from 'types/influencer';
import { VALID_ORDER } from 'common/regex';
import { track } from 'apis/amethyst';
import { formatProductData } from 'reducers/detail/productDetail';
import {
  evDeleteShoppablePostClick,
  evShoppablePostAddProductTagClick,
  evShoppablePostRemoveProductTagClick,
  evShoppableVideoUpload
} from 'events/influencerShoppablePost';
import { crossSiteMerchantIdDomainMapping, VRSNL_MERCHANTID } from 'constants/crossSiteMerchantIdMapping';
import { IDENTIFIER_TYPE_CUSTOMER_ID, INFLUENCER_HUB_PAGE, INFLUENCER_SHOPPABLE_POST } from 'constants/influencerPages';
import {
  ADD_PRODUCT_STYLE_DETAILS,
  CLEAR_PRODUCT_STYLE_DETAILS,
  DISCARD_SHOPPABLE_POST_DETAILS,
  SET_ALL_SHOPPABLE_POSTS,
  SET_ALL_SHOPPABLE_POSTS_COUNT,
  SET_API_STATUS,
  SET_AVAILABLE_HASHTAGS,
  SET_IS_PRODUCT_SEARCH_LOADING,
  SET_POST_STATE,
  SET_PRODUCT_SEARCH_DETAILS,
  SET_SCRUTINIZER_SCAN_STATUS,
  SET_SHOPPABLE_POST
} from 'constants/reduxActions';
import {
  APPROVED_SHOPPABLE_POST_QUERY,
  CURRENT_CONTENT_ACTION,
  DELETE_SHOPPABLE_POST,
  EDIT_SHOPPABLE_POST,
  FAILED,
  FETCH_ALL_AVAILABLE_HASHTAGS,
  FETCH_ALL_SHOPPABLE_POSTS,
  FETCH_SHOPPABLE_POST,
  FETCHING,
  IMAGE,
  MY_ORDERS_TAB,
  POST_ACTION_DRAFT,
  SAVE_SHOPPABLE_POST,
  SHOPPABLE_PHOTO,
  SHOPPABLE_POST_CONTENT_ACTION,
  SHOPPABLE_VIDEO,
  SUBMIT_SHOPPABLE_POST,
  SUCCESS,
  UNAPPROVED_STATE,
  UPLOAD_SHOPPABLE_PHOTO,
  UPLOAD_SHOPPABLE_VIDEO,
  VIDEO
} from 'constants/shoppablePosts';
import { INFLUENCER_SHOPPABLE_POSTS_PAGE } from 'constants/amethystPageTypes';
import { getProductRelations } from 'actions/products';
import { selectAccountConfig, selectMafiaConfig } from 'selectors/environment';
import {
  createOrUpdateShoppablePost,
  getAllShoppablePosts,
  getApprovedShoppablePost,
  getInfluencerHashtags,
  getOrDeleteShoppablePost,
  performInfluencerContentAction
} from 'apis/mafia/influencer';

const BAD_REQUEST_ERROR_MAPPER = res => ({ error: true, res });
const NO_CONTENT_MAPPER = res => res.text();

export function setShoppablePost(shoppablePost) {
  return {
    type: SET_SHOPPABLE_POST,
    shoppablePost
  };
}

export function discardShoppablePostDetails() {
  return {
    type: DISCARD_SHOPPABLE_POST_DETAILS
  };
}

export function addProductStyleDetails(productStyleDetails) {
  return {
    type: ADD_PRODUCT_STYLE_DETAILS,
    productStyleDetails
  };
}

export function clearProductStyleDetails() {
  return {
    type: CLEAR_PRODUCT_STYLE_DETAILS
  };
}

export function addProductSearchResults(productSearchResults) {
  return {
    type: SET_PRODUCT_SEARCH_DETAILS,
    productSearchResults
  };
}

export function setPostState(postState) {
  return {
    type: SET_POST_STATE,
    postState
  };
}

export function setIsSearchLoading(isSearchLoading) {
  return {
    type: SET_IS_PRODUCT_SEARCH_LOADING,
    isSearchLoading
  };
}

export function setApiStatus(apiName, apiStatus) {
  return {
    type: SET_API_STATUS,
    apiName,
    apiStatus
  };
}

export function setScrutinizerScanStatus(scrutinizerScanStatus) {
  return {
    type: SET_SCRUTINIZER_SCAN_STATUS,
    scrutinizerScanStatus
  };
}

export function setAllShoppablePosts(posts) {
  return {
    type: SET_ALL_SHOPPABLE_POSTS,
    allShoppablePosts: posts
  };
}

export function setAvailableHashtags(availableHashtags) {
  return {
    type: SET_AVAILABLE_HASHTAGS,
    availableHashtags
  };
}

export function setAllShoppablePostsCount(postId, increase) {
  return {
    type: SET_ALL_SHOPPABLE_POSTS_COUNT,
    postId,
    increase
  };
}

export function startShoppablePostCreation(postTitle) {
  return (dispatch, getState) => {
    const postId = guid();
    const {
      influencer: { influencerId }
    } = getState();
    dispatch(
      setShoppablePost({
        title: postTitle,
        influencerId,
        postState: ContentState.DRAFT,
        postId
      })
    );
    return dispatch(redirectToPostCreationPage(postId, UNAPPROVED_STATE));
  };
}

export function createShoppablePost(shoppablePost, postAction) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const newShoppablePost = { ...shoppablePost };
    newShoppablePost.postAction = postAction;
    return createOrUpdateShoppablePost(mafiaConfig, newShoppablePost, 'post', cookies)
      .then(
        fetchErrorMiddlewareAllowedErrors([200, 404], {
          404: BAD_REQUEST_ERROR_MAPPER,
          200: NO_CONTENT_MAPPER
        })
      )
      .then(response => {
        const { error } = response;
        const apiName = postAction === POST_ACTION_DRAFT ? SAVE_SHOPPABLE_POST : SUBMIT_SHOPPABLE_POST;
        if (error) {
          return dispatch(setApiStatus(apiName, FAILED));
        } else {
          return dispatch(setApiStatus(apiName, SUCCESS));
        }
      })
      .catch(err => {
        trackError('NON-FATAL', 'Failed to save', err);
      });
  };
}

export function fetchProductMetadataForAllProducts(mediaMetadata = [], fetchOpts) {
  return dispatch => {
    mediaMetadata.forEach(media => {
      const { productMetadata } = media;
      const styleIdList = [];
      productMetadata.forEach(product => {
        const { styleId, merchantId } = product;
        styleIdList.push(styleId);
        dispatch(fetchProductStyleDetails(styleId, merchantId, fetchOpts));
      });
      dispatch(getProductRelations(styleIdList));
    });
  };
}

export function fetchShoppablePostInfo(postId, postState, returnTo = INFLUENCER_HUB_PAGE) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const apiName = FETCH_SHOPPABLE_POST;
    dispatch(setApiStatus(apiName, FETCHING));
    return getOrDeleteShoppablePost(mafiaConfig, postId, postState, 'get', cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddlewareAllowedErrors([404], { 404: BAD_REQUEST_ERROR_MAPPER, 403: BAD_REQUEST_ERROR_MAPPER }))
      .then(response => {
        const { error } = response;

        if (error) {
          dispatch(setApiStatus(apiName, FAILED));
        } else {
          const { active = true, mediaMetadata } = response;
          if (!active) {
            dispatch(returnToInfluencerHub());
          } else {
            dispatch(setShoppablePost(response));
            dispatch(fetchProductMetadataForAllProducts(mediaMetadata));
          }
          return dispatch(setApiStatus(apiName, SUCCESS));
        }
      })
      .catch(e => {
        if (e.id !== ERROR_NOT_AUTHENTICATED) {
          trackError('NON-FATAL', 'Not able to fetch shoppable post', e);
        } else if (returnTo) {
          return authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(returnTo, location))(e);
        }
      });
  };
}

export function discardShoppablePostAction(postId, postState, postType, returnTo = INFLUENCER_HUB_PAGE) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getOrDeleteShoppablePost(mafiaConfig, postId, postState, 'delete', cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddlewareAllowedErrors([200, 404], { 404: BAD_REQUEST_ERROR_MAPPER, 200: NO_CONTENT_MAPPER }))
      .then(response => {
        const { error } = response;
        const apiName = DISCARD_SHOPPABLE_POST_DETAILS;
        if (error) {
          dispatch(setApiStatus(apiName, FAILED));
        } else {
          dispatch(setApiStatus(apiName, SUCCESS));
          fireDeleteShoppablePostAction(postId, postType);
          return dispatch(discardShoppablePostDetails());
        }
      })
      .catch(e => {
        if (e.id !== ERROR_NOT_AUTHENTICATED) {
          trackError('NON-FATAL', 'Not able to discard shoppable post', e);
        } else if (returnTo) {
          return authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(returnTo, location))(e);
        }
      });
  };
}

export function updateShoppablePost(shoppablePost, returnTo = INFLUENCER_HUB_PAGE) {
  const { mediaMetadata } = shoppablePost;
  return (dispatch, getState) => {
    if (!mediaMetadata?.length) {
      return dispatch(setShoppablePost(shoppablePost));
    }

    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return createOrUpdateShoppablePost(mafiaConfig, shoppablePost, 'put', cookies)
      .then(fetchErrorMiddlewareAllowedErrors([200, 404], { 404: BAD_REQUEST_ERROR_MAPPER, 200: NO_CONTENT_MAPPER }))
      .then(response => {
        const { error } = response;
        const apiName = EDIT_SHOPPABLE_POST;
        if (error) {
          dispatch(setApiStatus(apiName, FAILED));
        } else {
          dispatch(setApiStatus(apiName, SUCCESS));
          return dispatch(setShoppablePost(shoppablePost));
        }
      })
      .catch(e => {
        if (e.id !== ERROR_NOT_AUTHENTICATED) {
          trackError('NON-FATAL', 'Not able to discard shoppable post', e);
        } else if (returnTo) {
          return authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(returnTo, location))(e);
        }
      });
  };
}

export function addProductTags(productMetadata, replace = false) {
  return (dispatch, getState) => {
    const {
      influencer: { shoppablePost }
    } = getState();
    const newShoppablePost = { ...shoppablePost };
    const { mediaMetadata: mediaMetadataList } = newShoppablePost;
    const { productMetadata: productMetadataList } = mediaMetadataList[0];
    newShoppablePost.mediaMetadata[0].productMetadata =
      productMetadataList && !replace ? productMetadataList.concat(productMetadata) : [...productMetadata];
    return dispatch(updateShoppablePost(newShoppablePost)).then(
      productMetadata.forEach(productData => dispatch(getProductDataAndFireAddTagEvent(productData, newShoppablePost)))
    );
  };
}

export function editProductTag(index, productMetadata) {
  return (dispatch, getState) => {
    const {
      influencer: { shoppablePost }
    } = getState();
    const newShoppablePost = { ...shoppablePost };
    const removedProduct = newShoppablePost.mediaMetadata[0].productMetadata[index];
    newShoppablePost.mediaMetadata[0].productMetadata[index] = productMetadata;
    dispatch(updateShoppablePost(newShoppablePost));
    dispatch(getProductDataAndFireRemoveTagEvent(removedProduct, newShoppablePost));
    dispatch(getProductDataAndFireAddTagEvent(productMetadata, newShoppablePost));
  };
}

export function deleteProductTag(selectedProduct) {
  return (dispatch, getState) => {
    const {
      influencer: { shoppablePost }
    } = getState();
    const newShoppablePost = { ...shoppablePost };
    const { mediaMetadata: mediaMetadataList } = newShoppablePost;
    const { productMetadata: productMetadataList } = mediaMetadataList[0];
    const removedProduct = productMetadataList[selectedProduct];
    productMetadataList.splice(selectedProduct, 1);
    newShoppablePost.mediaMetadata[0].productMetadata = productMetadataList;
    dispatch(updateShoppablePost(newShoppablePost));
    dispatch(getProductDataAndFireRemoveTagEvent(removedProduct, newShoppablePost));
  };
}

function fireDeleteShoppablePostAction(postId, postType) {
  track(() => [
    evDeleteShoppablePostClick,
    {
      shoppablePostId: postId,
      shoppableContentType: postType,
      sourcePage: INFLUENCER_SHOPPABLE_POSTS_PAGE
    }
  ]);
}

export function getProductDataAndFireAddTagEvent(productData, shoppablePost) {
  return (dispatch, getState) => {
    const {
      influencer: { productStyleDetails }
    } = getState();
    const { styleId, merchantId } = productData;
    if (!(styleId in productStyleDetails)) {
      return dispatch(fetchProductStyleDetails(styleId, merchantId)).then(response => {
        fireAddProductTagEvent(styleId, merchantId, response[styleId].productId, shoppablePost);
      });
    } else {
      return fireAddProductTagEvent(styleId, merchantId, productStyleDetails[styleId].productId, shoppablePost);
    }
  };
}

function fireAddProductTagEvent(styleId, merchantId, productId, shoppablePost) {
  // For vrsnl products
  const websiteDomain = merchantId && crossSiteMerchantIdDomainMapping[merchantId];
  track(() => [
    evShoppablePostAddProductTagClick,
    {
      styleId,
      productId,
      shoppablePostId: shoppablePost.postId,
      shoppableContentType: shoppablePost.postType,
      websiteDomain
    }
  ]);
}

export function getProductDataAndFireRemoveTagEvent(productData, shoppablePost) {
  return (dispatch, getState) => {
    const {
      influencer: { productStyleDetails }
    } = getState();
    const { styleId, merchantId } = productData;
    if (!(styleId in productStyleDetails)) {
      dispatch(fetchProductStyleDetails(styleId, merchantId)).then(response => {
        fireRemoveProductTagEvent(styleId, merchantId, response[styleId].productId, shoppablePost);
      });
    } else {
      fireRemoveProductTagEvent(styleId, merchantId, productStyleDetails[styleId].productId, shoppablePost);
    }
  };
}

function fireRemoveProductTagEvent(styleId, merchantId, productId, shoppablePost) {
  const websiteDomain = merchantId && crossSiteMerchantIdDomainMapping[merchantId];
  track(() => [
    evShoppablePostRemoveProductTagClick,
    {
      styleId,
      productId,
      shoppablePostId: shoppablePost.postId,
      shoppableContentType: shoppablePost.postType,
      websiteDomain
    }
  ]);
}

export function uploadShoppablePostPhoto(file, fileExtension) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      influencer: {
        shoppablePost,
        shoppablePost: { postId }
      },
      cookies,
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const newShoppablePost = { ...shoppablePost };
    let { mediaMetadata: mediaMetadataList } = newShoppablePost;
    uploadInfluencerImage(file, fileExtension, postId, SHOPPABLE_PHOTO, 0, cookies, mafiaConfig, location, dispatch).then(response => {
      const { physicalId, scrutinizerFailed } = response;
      const apiName = UPLOAD_SHOPPABLE_PHOTO;
      const mediaId = `${physicalId}_${postId}_${new Date().getTime()}`;
      if (scrutinizerFailed && scrutinizerFailed === true) {
        dispatch(setScrutinizerScanStatus(ScrutinizerScanState.FAILURE));
        return dispatch(setApiStatus(apiName, FAILED));
      }
      dispatch(setScrutinizerScanStatus(ScrutinizerScanState.SUCCESS));
      if (!physicalId) {
        return dispatch(setApiStatus(apiName, FAILED));
      }
      if (mediaMetadataList) {
        mediaMetadataList[0].mediaId = mediaId;
        mediaMetadataList[0].mediaExtension = fileExtension;
      } else {
        mediaMetadataList = [
          {
            mediaId,
            mediaExtension: fileExtension,
            mediaType: IMAGE
          }
        ];
      }
      newShoppablePost.postType = SHOPPABLE_PHOTO;
      newShoppablePost.mediaMetadata = mediaMetadataList;
      dispatch(setShoppablePost(newShoppablePost));
      dispatch(setApiStatus(apiName, SUCCESS));
      dispatch(createShoppablePost(newShoppablePost, POST_ACTION_DRAFT));
    });
  };
}

export function uploadShoppablePostVideo(file, fileExtension, videoDuration) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      influencer: {
        shoppablePost,
        shoppablePost: { postId }
      },
      cookies,
      router: { location }
    } = state;
    const newShoppablePost = { ...shoppablePost };
    let { mediaMetadata: mediaMetadataList } = newShoppablePost;
    const apiName = UPLOAD_SHOPPABLE_VIDEO;
    const mafiaConfig = selectMafiaConfig(state);

    uploadInfluencerVideo(file, fileExtension, postId, cookies, mafiaConfig, location, dispatch).then(response => {
      if (!response) {
        return dispatch(setApiStatus(apiName, FAILED));
      }
      const { videoUrl: mediaUrl, videoState, mediaId, scrutinizerFailed } = response;
      if (scrutinizerFailed && scrutinizerFailed === true) {
        dispatch(setScrutinizerScanStatus(ScrutinizerScanState.FAILURE));
        return dispatch(setApiStatus(apiName, FAILED));
      }
      dispatch(setScrutinizerScanStatus(ScrutinizerScanState.SUCCESS));
      if (videoState === VideoState.FAILURE) {
        return dispatch(setApiStatus(apiName, FAILED));
      } else {
        if (mediaMetadataList) {
          mediaMetadataList[0].mediaId = mediaId;
          mediaMetadataList[0].mediaUrl = mediaUrl;
          mediaMetadataList[0].mediaExtension = fileExtension;
        } else {
          mediaMetadataList = [
            {
              mediaId,
              mediaUrl,
              mediaExtension: fileExtension,
              mediaType: VIDEO
            }
          ];
        }
        newShoppablePost.postType = SHOPPABLE_VIDEO;
        newShoppablePost.mediaMetadata = mediaMetadataList;
        dispatch(setShoppablePost(newShoppablePost));
        dispatch(setApiStatus(apiName, SUCCESS));
        dispatch(createShoppablePost(newShoppablePost, POST_ACTION_DRAFT));
        fireVideoUploadEvent(postId, videoDuration);
      }
    });
  };
}

const fireVideoUploadEvent = (shoppablePostId, videoDuration) => {
  track(() => [
    evShoppableVideoUpload,
    {
      shoppablePostId,
      videoDuration
    }
  ]);
};

export function fetchProductStyleDetails(styleId, merchantId, fetchOpts = {}) {
  return (dispatch, getState) => {
    const {
      entireProduct = false,
      includeSizing = false,
      fetchOnlyListedProducts = false,
      includeOosSizing = false,
      includeOos = false
    } = fetchOpts;

    let cloudcatalog;
    if (merchantId === VRSNL_MERCHANTID) {
      cloudcatalog = cloudCatalogUsingMerchantId(VRSNL_MERCHANTID);
    } else {
      ({
        environmentConfig: {
          api: { cloudcatalog }
        }
      } = getState());
    }

    return productBundle(
      cloudcatalog,
      {
        styleId,
        entireProduct,
        includeBrand: false,
        includeSizing,
        includeOosSizing,
        includeOos
      },
      timedFetch('cloudCatalogProduct')
    )
      .then(fetchErrorMiddleware)
      .then(response => {
        const { brandName, productName, styles, productId, oos } = response.product[0];
        const style = styles.find(styleDetail => styleDetail.styleId === styleId);
        const defaultStyle = { color: '', images: [], price: '' };
        const { color, images, price, productUrl } = style ? style : defaultStyle;
        const { imageId } = images.find(image => image.type === 'MAIN') || {
          imageId: null
        };
        const isProductDataAvailable = imageId !== null;
        const productData = includeSizing ? formatProductData({ detail: response.product[0] }) : {};
        const productStyleDetails = {
          [styleId]: {
            brandName,
            productName,
            color,
            price,
            imageId,
            productData,
            merchantId,
            productUrl,
            productId,
            isProductDataAvailable,
            isOOS: oos
          }
        };
        if (fetchOnlyListedProducts) {
          if (isProductDataAvailable) {
            dispatch(addProductStyleDetails(productStyleDetails));
          }
        } else {
          dispatch(addProductStyleDetails(productStyleDetails));
        }
        return productStyleDetails;
      });
  };
}

export function returnToInfluencerHub() {
  return dispatch => {
    dispatch(redirectTo(INFLUENCER_HUB_PAGE));
  };
}

export function redirectToPostCreationPage(postId, postApproval) {
  return dispatch => {
    dispatch(redirectTo(`${INFLUENCER_SHOPPABLE_POST}${postId}/${postApproval}`));
  };
}

function buildSearchResult(activeTab, response, isSearchByOrderId) {
  let result = [];
  if (isSearchByOrderId) {
    result = [response];
  } else {
    const { orders } = response;
    result = orders;
  }
  const productSearchResults = {
    displayTab: activeTab,
    searchResult: result
  };
  return productSearchResults;
}

function fetchOrderDetailsForOrderId(activeTab, searchValue) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    getOrderV3(mafiaConfig, searchValue, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        const result = buildSearchResult(activeTab, resp, true);
        dispatch(addProductSearchResults(result));
        dispatch(setIsSearchLoading(false));
      })
      .catch(e => {
        trackError('NON-FATAL', 'Not able to search product for given order Id', e);
      });
  };
}

function fetchOrderDetailsForKeyword(activeTab, searchValue) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const queryParams = { search: searchValue, pageSize: 50 };
    getOrdersBySearch(mafiaConfig, queryParams, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        const result = buildSearchResult(activeTab, resp, false);
        dispatch(addProductSearchResults(result));
        dispatch(setIsSearchLoading(false));
      })
      .catch(e => {
        trackError('NON-FATAL', 'Not able to search product for search keyword', e);
      });
  };
}

function fetchCollectionSearchResult(activeTab, searchValue) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const accountConfig = selectAccountConfig(state);
    const productSearchResults = {
      displayTab: activeTab,
      searchResult: []
    };

    getInfluencerCollectionSearch(accountConfig, searchValue, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        productSearchResults.searchResult = resp;
        dispatch(addProductSearchResults(productSearchResults));
        dispatch(setIsSearchLoading(false));
      })
      .catch(e => {
        trackError('NON-FATAL', 'Not able to search product for search keyword', e);
      });
  };
}

export function searchProductInActiveTab(activeTab, searchValue) {
  return dispatch => {
    dispatch(setIsSearchLoading(true));
    if (activeTab.includes(MY_ORDERS_TAB)) {
      if (VALID_ORDER.test(searchValue)) {
        dispatch(fetchOrderDetailsForOrderId(activeTab, searchValue));
      } else {
        dispatch(fetchOrderDetailsForKeyword(activeTab, searchValue));
      }
    } else {
      dispatch(fetchCollectionSearchResult(activeTab, searchValue));
    }
  };
}

export function modifyShoppablePostTitle(postTitle) {
  return (dispatch, getState) => {
    const {
      influencer: { shoppablePost = {} }
    } = getState();
    const newShoppablePost = { ...shoppablePost };
    newShoppablePost.title = postTitle;
    return dispatch(updateShoppablePost(newShoppablePost));
  };
}

export function modifyShoppablePostCaption(postCaption) {
  return (dispatch, getState) => {
    const {
      influencer: { shoppablePost = {} }
    } = getState();
    const newShoppablePost = { ...shoppablePost };
    newShoppablePost.description = postCaption || ' ';
    return dispatch(updateShoppablePost(newShoppablePost));
  };
}

export function deleteShoppablePostContent(postId, postState) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      influencer: { allShoppablePosts }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getOrDeleteShoppablePost(mafiaConfig, postId, postState, 'delete', cookies)
      .then(
        fetchErrorMiddlewareAllowedErrors([200, 400], {
          400: BAD_REQUEST_ERROR_MAPPER,
          200: NO_CONTENT_MAPPER
        })
      )
      .then(response => {
        const { error } = response;
        const apiName = DELETE_SHOPPABLE_POST;
        if (error) {
          return dispatch(setApiStatus(apiName, FAILED));
        } else {
          dispatch(setApiStatus(apiName, SUCCESS));
          const shoppablePosts = [...allShoppablePosts];
          shoppablePosts.forEach((post, index) => {
            const toDeletePost =
              post.postId === postId &&
              ((postState === ContentState.APPROVED && post.postState === ContentState.APPROVED) ||
                (postState !== ContentState.APPROVED && post.postState !== ContentState.APPROVED));
            if (toDeletePost) {
              shoppablePosts[index] = { ...post, active: false };
            }
          });
          return dispatch(setAllShoppablePosts(shoppablePosts));
        }
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not delete Shoppable post.', e);
      });
  };
}

export function fetchAllShoppablePosts(queryString = '', auth = true) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      influencer: { influencerId }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const apiName = FETCH_ALL_SHOPPABLE_POSTS;
    dispatch(setApiStatus(apiName, FETCHING));
    return getAllShoppablePosts(mafiaConfig, influencerId, queryString, auth, cookies)
      .then(
        fetchErrorMiddlewareAllowedErrors([400], {
          400: res => ({ error: true, res })
        })
      )
      .then(response => {
        const { error } = response;
        if (error) {
          return dispatch(setApiStatus(apiName, FAILED));
        } else {
          dispatch(setApiStatus(apiName, SUCCESS));
          dispatch(setAllShoppablePosts(response.posts));
          if (!auth || queryString === APPROVED_SHOPPABLE_POST_QUERY) {
            dispatch(setInfluencerShoppableContentEmpty(!response.posts?.filter(post => post.active).length));
          }
        }
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not fetch Shoppable Posts.', e);
      });
  };
}

export function performShoppablePostLikeAction(postId, increase) {
  return dispatch => {
    dispatch(updateCustomerPostLikedStatus(postId, increase));
    return dispatch(setAllShoppablePostsCount(postId, increase));
  };
}
export function performShoppablePostContentAction(postId, contentAction) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      influencer: { allShoppablePosts }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return performInfluencerContentAction(mafiaConfig, postId, {}, contentAction, cookies)
      .then(
        fetchErrorMiddlewareAllowedErrors([200, 400], {
          400: BAD_REQUEST_ERROR_MAPPER,
          200: NO_CONTENT_MAPPER
        })
      )
      .then(response => {
        const { error } = response;
        const apiName = SHOPPABLE_POST_CONTENT_ACTION;
        if (error) {
          return dispatch(setApiStatus(apiName, FAILED));
        } else {
          dispatch(setApiStatus(apiName, SUCCESS));
          const shoppablePosts = [...allShoppablePosts];
          shoppablePosts.forEach((post, index) => {
            if (post.postId === postId) {
              switch (contentAction) {
                case InfluencerContentAPIAction.SHARE:
                  shoppablePosts[index] = { ...post, shared: true };
                  break;
                case InfluencerContentAPIAction.SHOW:
                  shoppablePosts[index] = { ...post, hidden: false };
                  break;
                case InfluencerContentAPIAction.HIDE:
                  shoppablePosts[index] = { ...post, hidden: true };
              }
            }
          });
          return dispatch(setAllShoppablePosts(shoppablePosts));
        }
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not perform content action.', e);
      });
  };
}

export function getShoppablePostDetails(postId, fetchInfluencerDetails = false, fetchOnlyListedProducts = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const apiName = FETCH_SHOPPABLE_POST;
    dispatch(setApiStatus(apiName, FETCHING));
    return getApprovedShoppablePost(mafiaConfig, postId, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        const { error } = response;
        if (error) {
          dispatch(setApiStatus(apiName, FAILED));
        } else {
          const { mediaMetadata, influencerId } = response;
          dispatch(setShoppablePost(response));
          if (fetchInfluencerDetails && influencerId) {
            dispatch(handleGetInfluencerDetailsByIdentifier(influencerId, IDENTIFIER_TYPE_CUSTOMER_ID));
          }
          const fetchOpts = {
            entireProduct: true,
            includeSizing: true,
            fetchOnlyListedProducts
          };
          dispatch(fetchProductMetadataForAllProducts(mediaMetadata, fetchOpts));
          return dispatch(setApiStatus(apiName, SUCCESS));
        }
      })
      .catch(e => {
        dispatch(setError(e.statusText, null, e.status));
      });
  };
}

export function performActionOnCurrentContent(contentAction) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      influencer: { shoppablePost }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const { postId } = shoppablePost;
    return performInfluencerContentAction(mafiaConfig, postId, {}, contentAction, cookies)
      .then(
        fetchErrorMiddlewareAllowedErrors([200, 400], {
          400: BAD_REQUEST_ERROR_MAPPER,
          200: NO_CONTENT_MAPPER
        })
      )
      .then(response => {
        const { error } = response;
        const apiName = CURRENT_CONTENT_ACTION;
        if (error) {
          return dispatch(setApiStatus(apiName, FAILED));
        } else {
          dispatch(setApiStatus(apiName, SUCCESS));
          let newShoppablePost;
          switch (contentAction) {
            case InfluencerContentAPIAction.SHARE:
              newShoppablePost = { ...shoppablePost, shared: true };
              break;
            case InfluencerContentAPIAction.SHOW:
              newShoppablePost = { ...shoppablePost, hidden: false };
              break;
            case InfluencerContentAPIAction.HIDE:
              newShoppablePost = { ...shoppablePost, hidden: true };
          }
          return dispatch(setShoppablePost(newShoppablePost));
        }
      });
  };
}

export function addSelectedHashtagsToShoppablePost(selectedHashtags) {
  return (dispatch, getState) => {
    const {
      influencer: { shoppablePost = {} }
    } = getState();
    const newShoppablePost = { ...shoppablePost };
    newShoppablePost.tags = selectedHashtags || [];
    return dispatch(updateShoppablePost(newShoppablePost));
  };
}

export function fetchAvailableHashtags() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);
    const apiName = FETCH_ALL_AVAILABLE_HASHTAGS;

    dispatch(setApiStatus(apiName, FETCHING));
    return getInfluencerHashtags(mafiaConfig, cookies)
      .then(
        fetchErrorMiddlewareAllowedErrors([400], {
          400: res => ({ error: true, res })
        })
      )
      .then(response => {
        const { error } = response;
        if (error) {
          return dispatch(setApiStatus(apiName, FAILED));
        } else {
          dispatch(setApiStatus(apiName, SUCCESS));
          const availableHashtags = response.hashtags;
          dispatch(setAvailableHashtags(availableHashtags));
        }
      })
      .catch(e => {
        trackError('NON-FATAL', 'Could not fetch available hashtags for influencer.', e);
      });
  };
}
