import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import appendQuery from 'append-query';
import { deepEqual } from 'fast-equals';

import { evRecommendationClick, evRecommendationImpressionWrapper } from 'events/recommendations';
import useMartyContext from 'hooks/useMartyContext';
import type { JanusSlot } from 'types/mafia';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import ColorSwatchWrapper, { getQueryString } from 'components/search/ColorSwatches';
import ProductCard from 'components/common/ProductCard';
import { track } from 'apis/amethyst';
import useFetchProductRelation from 'hooks/useFetchProductRelation';
import { evProductInteract } from 'events/search';
import { formattedProductsWithBadges, getBadgeId, getRowHasBadge } from 'helpers/BadgeUtils';
import { getProductWithRelatedStyles } from 'helpers/RecoUtils';
import { setHFSearchTerm } from 'actions/headerfooter';
import Carousel from 'components/common/Carousel/Carousel';
import { useInAssignment } from 'hooks/useHydra';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';

import css from 'styles/components/hf/zappos/footerRecos.scss';

interface Props {
  heartsData?: any; // TODO ts type this when `hearts` are typed
  recos: JanusSlot;
  isFullMaxWidth?: boolean;
}

const widgetType = 'YOUR_RECENTLY_VIEWED_WIDGET';
const recommendationSource = 'EP13N';
const recommendationType = 'PRODUCT_RECOMMENDATION';

const FooterRecosImpl = (props: Props) => {
  const {
    testId,
    marketplace: {
      search: { msaImageParams }
    }
  } = useMartyContext();

  const hydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);

  const { recos, heartsData = {} } = props;
  const { title, recs: inRecos = [] } = recos;
  // temp fix. We will do not want to supress a whole product
  const recs = inRecos.filter(rec => rec.styleId !== null);
  const styleIds = recs?.map(rec => rec.styleId) || [];
  const [productRelations] = useFetchProductRelation(styleIds);

  const dispatch = useDispatch();

  const formattedBadgeProducts = formattedProductsWithBadges(recs, productRelations);

  useEffect(() => {
    if (formattedBadgeProducts.length > 0) {
      const recommendationImpression = [
        {
          numberOfRecommendations: formattedBadgeProducts.length,
          recommendationType,
          recommendationSource,
          widgetType
        }
      ];
      track(() => [evRecommendationImpressionWrapper, { products: formattedBadgeProducts, recommendationImpression }]);
    }
  }, [formattedBadgeProducts.length]);

  if (!recos || recs.length === 0 || !productRelations) {
    return null;
  }

  const rowHasBadge = getRowHasBadge(recs, productRelations);

  const makeRecoCards = () =>
    recs.map((rec, index) => {
      const { styleId } = rec;

      let productWithRelatedStyles;
      if (productRelations) {
        productWithRelatedStyles = getProductWithRelatedStyles(styleId, productRelations);
      }

      if (!productWithRelatedStyles) {
        return null;
      }

      const { relatedStyles, badges, ...productInfo } = productWithRelatedStyles;

      if (!productInfo.productId) {
        return null;
      }

      const { productId, colorId } = productInfo;

      const recommendedProduct = {
        productId,
        styleId,
        colorId,
        supplementalData: {
          badgeId: getBadgeId(badges)
        }
      };

      const onProductClicked = () => {
        dispatch(setHFSearchTerm(''));
        track(() => [
          evRecommendationClick,
          {
            index,
            recommendationType,
            recommendedProduct,
            recommendationSource,
            widgetType
          }
        ]);
      };

      if (relatedStyles && relatedStyles.length > 0) {
        const onProductMediaHovered = (rec: JanusSlot, styleId: string) => {
          track(() => [
            evProductInteract,
            {
              mainStyleId: styleId,
              interactedProduct: rec,
              interactionType: 'HOVER'
            }
          ]);
        };

        return (
          <ColorSwatchWrapper
            {...productInfo}
            retainQueryString={recs[0] ? getQueryString(recs[0].link) : undefined}
            relatedStyles={relatedStyles}
            heartsInfo={heartsData}
            key={styleId + index}
            eventLabel="Recommender"
            msaImageParams={msaImageParams}
            onProductMediaHovered={onProductMediaHovered as any}
            onClick={onProductClicked}
            testId={testId('recommenderProduct')}
            imageBadgeClassName={rowHasBadge ? css.rowHasBadge : undefined}
            badges={badges}
          />
        );
      }

      return (
        <ProductCard
          {...productInfo}
          productUrl={`${recs[0] ? appendQuery(productInfo.productUrl, getQueryString(recs[0].link).toString()) : productInfo.productUrl}`}
          msaImageParams={msaImageParams}
          eventLabel="Recommender"
          testId={testId('recommenderProduct')}
          key={styleId + index}
          shouldLazyLoad={true}
          hearts={heartsData}
          onClick={onProductClicked}
          imageBadgeClassName={rowHasBadge && css.rowHasBadge}
          badges={badges}
        />
      );
    });

  return (
    <aside
      className={hydraCefi ? css.cefiCarouselContainer : css.carouselContainer}
      id="martyFooterRecentlyViewed"
      aria-label={title}
      data-test-id={testId('recentlyViewedCarousel')}
    >
      <Carousel
        slideWidths={{ mobile: '33.3%', tablet: '25%', desktop: '20%' }}
        slides={makeRecoCards().filter(slide => slide !== null) as JSX.Element[]}
        header={{ title: title }}
      />
    </aside>
  );
};

export const FooterRecos = React.memo(FooterRecosImpl, deepEqual);
export default withErrorBoundary('FooterRecos', FooterRecos);
