import cn from 'clsx';
import SocialFacebookMediumIcon from '@mweb/zappos-ui/Icons/SocialFacebookMediumIcon';
import SocialInstagramMediumIcon from '@mweb/zappos-ui/Icons/SocialInstagramMediumIcon';
import SocialPinterestMediumIcon from '@mweb/zappos-ui/Icons/SocialPinterestMediumIcon';
import SocialTwitterMediumIcon from '@mweb/zappos-ui/Icons/SocialTwitterMediumIcon';
import SocialYouTubeMediumIcon from '@mweb/zappos-ui/Icons/SocialYouTubeMediumIcon';

import type { FooterSocialIconsProps } from 'components/hf/zappos/FooterSocialIcons/FooterSocialIcons.types';
import { containerClasses, containerNonMobileClasses } from 'components/hf/zappos/FooterSocialIcons/FooterSocialIcons.classes';
import useMartyContext from 'hooks/useMartyContext';
import { stripSpecialCharsDashReplace as strip } from 'helpers/index';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import IconButton from 'components/common/IconButton';

const ICON_SIZE = 24;

const SocialIconMap: Record<string, React.ReactElement> = {
  facebook: <SocialFacebookMediumIcon size={ICON_SIZE} />,
  instagram: <SocialInstagramMediumIcon size={ICON_SIZE} />,
  twitter: <SocialTwitterMediumIcon size={ICON_SIZE} />,
  pinterest: <SocialPinterestMediumIcon size={ICON_SIZE} />,
  youtube: <SocialYouTubeMediumIcon size={ICON_SIZE} />
};

const FooterSocialIcons = (props: FooterSocialIconsProps) => {
  const { className, links, ...forwardProps } = props;
  const { marketplace: { shortName } = {}, testId } = useMartyContext();

  return (
    <div className={cn(containerClasses, containerNonMobileClasses, className)} {...forwardProps} data-slot-id="social-links">
      {links?.footerMenu.map(({ link, text, gae }) => (
        <IconButton
          key={text}
          label={text}
          href={link}
          target="_blank"
          isExternalLink={true}
          rel="noopener noreferrer"
          size="base"
          variant={shortName === 'vrsnl-zappos' ? 'outlinedLight' : 'outlined'}
          data-test-id={testId(text)}
          onClick={() => {
            trackEvent('TE_FOOTER_SOCIAL', strip(gae || text));
            trackLegacyEvent('Footer', 'Connect-with-us', strip(gae || text));
          }}
        >
          {SocialIconMap[text.toLowerCase()]}
        </IconButton>
      ))}
    </div>
  );
};

FooterSocialIcons.displayName = 'FooterSocialIcons';

export default FooterSocialIcons;
