/**
 * Order of routes is important! When creating routes the most specific route needs to occur before its counterparts, this is because
 *  React Router does partial matching
 * - Example:
 *     '/c/:pageName',
 *     '/c/'
 */

import loadable from '@loadable/component';

import type { RouteConfigUtil } from 'helpers/RouteUtils';
import { shouldAccountIgnoreScrollToTop, shouldCheckoutIgnoreScrollToTop, shouldSearchIgnoreScrollToTop } from 'helpers/RouteUtils';
import marketplace from 'cfg/marketplace.json';
import App from 'containers/App';

export const AppNode = App;

export enum RouteType {
  ROUTE,
  REDIRECT
}

export interface RouteConfig {
  path: string;
  routeType: RouteType;
  exact?: boolean;
  component?: any;
  to?: string;
  suppressScrollOnRouteChange?: (prevRouterProps: RouteConfigUtil, newRouterProps: RouteConfigUtil) => boolean;
}

const {
  pdp: { egcUrl },
  account: { vipDashboardUrl },
  returns: { handlesReturnShipping },
  hasReleaseCalendar
} = marketplace;

// See https://webpack.js.org/api/module-methods/#magic-comments
// These webpack chunk names must either match the component class name, or the class itself must define `static webpackChunkName`
// for CSS splitting to work without users seeing a FOUC.  These chunksNames are then referenced via the produced webpackStats object.
const LazyAccount = loadable(() => import(/* webpackChunkName: "Account" */ 'containers/account/Account'));
const LazyInfluencerHub = loadable(() => import(/* webpackChunkName: "InfluencerHub" */ 'containers/influencer/hub/InfluencerHub'));
const LazyAddInfluencerSocialProfile = loadable(
  () => import(/* webpackChunkName: "AddProfileCallBackHandler" */ 'containers/influencer/AddInfluencerSocialProfile')
);
const LazyInfluencerLanding = loadable(() => import(/* webpackChunkName: "InfluencerLanding" */ 'containers/influencer/InfluencerLanding'));
const LazyNewInfluencerCollection = loadable(
  () => import(/* webpackChunkName: "NewInfluencerCollection" */ 'containers/influencer/NewInfluencerCollection')
);
const LazyInfluencerEnroll = loadable(
  () => import(/* webpackChunkName: "InfluencerProgramEnroll" */ 'containers/influencer/InfluencerProgramEnroll')
);
const LazyStorefrontCustomerView = loadable(
  () => import(/* webpackChunkName: "StorefrontCustomerView" */ 'containers/influencer/storefront/StorefrontCustomerView')
);
const LazyAddPayment = loadable(() => import(/* webpackChunkName: "AddPayment" */ 'containers/account/AddPayment'));
const LazyAddReview = loadable(() => import(/* webpackChunkName: "AddReview" */ 'containers/AddReview'));
const LazyAddShipping = loadable(() => import(/* webpackChunkName: "AddShipping" */ 'containers/account/AddShipping'));
const LazyAsinStockIdProductDetail = loadable(
  () => import(/* webpackChunkName: "AsinStockIdProductDetail" */ 'containers/AsinStockIdProductDetail')
);
const LazyAsinAddReview = loadable(() => import(/* webpackChunkName: "AsinAddReview" */ 'containers/AsinAddReview'));
const LazyBrokenPage = loadable(() => import(/* webpackChunkName: "BrokenPage" */ 'components/error/BrokenPage'));
const LazyCostlyCustomerPage = loadable(() => import(/* webpackChunkName: "CostlyCustomerPage" */ 'components/error/CostlyCustomerPage'));
const LazyCart = loadable(() => import(/* webpackChunkName: "Cart", webpackPrefetch: 30  */ 'containers/Cart'));
const LazyCheckout = loadable(() => import(/* webpackChunkName: "Checkout" , webpackPrefetch: 20 */ 'containers/checkout/Checkout'));
const LazyCreateLabel = loadable(() => import(/* webpackChunkName: "CreateLabel" */ 'containers/CreateLabel'));
const LazyEditPayment = loadable(() => import(/* webpackChunkName: "EditPayment" */ 'containers/account/EditPayment'));
const LazyEditShipping = loadable(() => import(/* webpackChunkName: "EditShipping" */ 'containers/account/EditShipping'));
const LazyFederatedLogin = loadable(() => import(/* webpackChunkName: "FederatedLoginPage" */ 'containers/account/FederatedLoginPage'));
const LazyHmdSurvey = loadable(() => import(/* webpackChunkName: "HmdSurvey" */ 'containers/HmdSurvey'));
const LazySubscriptions = loadable(() => import(/* webpackChunkName: "Subscriptions" */ 'containers/Subscriptions'));
const LazyCollections = loadable(() => import(/* webpackChunkName: "Collections" */ 'containers/account/Collections'));
const LazyDeleteAccount = loadable(() => import(/* webpackChunkName: "DeleteAccount" */ 'containers/account/DeleteAccount'));
const LazyCollection = loadable(() => import(/* webpackChunkName: "Collection" */ 'containers/account/Collection'));
const LazyInfluencerShoppablePost = loadable(
  () => import(/* webpackChunkName: "InfluencerShoppablePost" */ 'containers/influencer/InfluencerShoppablePost')
);
const LazyShoppablePostDetail = loadable(() => import(/* webpackChunkName: "ShoppablePostDetail" */ 'containers/influencer/ShoppablePostDetail'));
const LazyLanding = loadable(() => import(/* webpackChunkName: "Landing", webpackPrefetch: 50 */ 'containers/Landing'));
const LazyLaunchCalendar = loadable(() => import(/* webpackChunkName: "LaunchCalendar" */ 'containers/LaunchCalendar'));
const LazyOrder = loadable(() => import(/* webpackChunkName: "Order" */ 'containers/account/Order'));
const LazyCancelOrder = loadable(() => import(/* webpackChunkName: "CancelOrder" */ 'containers/account/CancelOrder'));
const LazyOrderHistory = loadable(() => import(/* webpackChunkName: "OrderHistory" */ 'containers/account/OrderHistory'));
const LazyOrderConfirmation = loadable(() => import(/* webpackChunkName: "OrderConfirmation" */ 'containers/checkout/OrderConfirmation'));
const LazyPayments = loadable(() => import(/* webpackChunkName: "Payments" */ 'containers/account/Payments'));
const LazyProductDetail = loadable(() => import(/* webpackChunkName: "ProductDetail", webpackPrefetch: 40 */ 'containers/ProductDetail'));
const LazyProductReviews = loadable(() => import(/* webpackChunkName: "ProductReviews" */ 'containers/ProductReviews'));
const LazyReturns = loadable(() => import(/* webpackChunkName: "Returns" */ 'containers/account/Returns'));
const LazyCancelReturn = loadable(() => import(/* webpackChunkName: "CancelReturn" */ 'components/account/Returns/CancelReturn/CancelReturn'));
const LazyReturnAll = loadable(() => import(/* webpackChunkName: "ReturnAll" */ 'containers/account/ReturnAll'));
const LazyReturnLabel = loadable(() => import(/* webpackChunkName: "ReturnLabel" */ 'containers/account/ReturnLabel'));
const LazyVipOptin = loadable(() => import(/* webpackChunkName: "VipProgramOptin" */ 'containers/account/VipProgramOptin'));
const LazyVipOptinAck = loadable(() => import(/* webpackChunkName: "VipJustEnrolledFromToken" */ 'containers/account/VipJustEnrolledFromToken'));
const LazyStyleFeed = loadable(() => import(/* webpackChunkName: "StyleFeed" */ 'containers/influencer/StyleFeed'));
const LazyExchange = loadable(() => import(/* webpackChunkName: "Exchanges" */ 'components/exchanges/Exchanges'));
const LazyRouteNotFound = loadable(() => import(/* webpackChunkName: "RouteNotFound" */ 'containers/RouteNotFound'));
const LazySearch = loadable(() => import(/* webpackChunkName: "StandardSearch" */ 'containers/StandardSearch'));
const LazyShipping = loadable(() => import(/* webpackChunkName: "Shipping" */ 'containers/account/Shipping'));
const LazyShipmentTracking = loadable(() => import(/* webpackChunkName: "ShipmentTracking" */ 'containers/account/ShipmentTracking'));
const LazySymphonyPreview = loadable(() => import(/* webpackChunkName: "SymphonyPreview" */ 'containers/SymphonyPreview'));
const LazyTaxonomyBrand = loadable(() => import(/* webpackChunkName: "TaxonomyBrandPage" */ 'containers/TaxonomyBrandPage'));
const LazySearchByImage = loadable(
  () => import(/* webpackChunkName: "VisualSearchResults" */ 'components/VisualSearch/VisualSearchResults/VisualSearchResults')
);
const LazyWildCard = loadable(() => import(/* webpackChunkName: "WildCard", webpackPrefetch: 60 */ 'containers/WildCard'));

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    component: LazyLanding,
    routeType: RouteType.ROUTE,
    exact: true
  },
  /* Search By Image */
  {
    path: '/searchByImage',
    component: LazySearchByImage,
    routeType: RouteType.ROUTE
  },
  /* Search */
  {
    path: '/filters/search*',
    component: LazySearch,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: shouldSearchIgnoreScrollToTop
  },
  {
    path: '/search*',
    component: LazySearch,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: shouldSearchIgnoreScrollToTop
  },
  {
    path: '/filters/*/*.zso',
    component: LazySearch,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: shouldSearchIgnoreScrollToTop
  },
  {
    path: '/filters/*.zso',
    component: LazyRouteNotFound,
    routeType: RouteType.ROUTE
  },
  {
    path: '/*/*.zso',
    component: LazySearch,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: shouldSearchIgnoreScrollToTop
  },
  {
    path: '/cart',
    component: LazyCart,
    routeType: RouteType.ROUTE
  },
  {
    path: '/checkout',
    component: LazyCheckout,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: shouldCheckoutIgnoreScrollToTop
  },
  /* Order Confirmation */
  {
    path: '/confirmation/:purchaseId',
    component: LazyOrderConfirmation,
    routeType: RouteType.ROUTE
  },
  {
    path: '/p/asin/:asin',
    component: LazyAsinStockIdProductDetail,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: () => true
  },
  {
    path: '/product/stockId/:stockId',
    component: LazyAsinStockIdProductDetail,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: () => true
  },
  {
    path: '/p/:seoName/product/:productId/color/:colorId',
    component: LazyProductDetail,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: () => true
  },
  {
    path: '/p/:seoName/product/:productId',
    component: LazyProductDetail,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: () => true
  },
  {
    path: '/p/product/:productId/color/:colorId',
    component: LazyProductDetail,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: () => true
  },
  {
    path: '/p/product/:productId',
    component: LazyProductDetail,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: () => true
  },
  {
    path: '/product/review/add/media/:productId/color/:colorId',
    component: LazyAddReview,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/add/media/:productId',
    component: LazyAddReview,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/p/add/:productId/color/:colorId',
    component: LazyAddReview,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/p/add/:productId',
    component: LazyAddReview,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/add/:productId/color/:colorId',
    component: LazyAddReview,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/add/:productId',
    component: LazyAddReview,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/:productId/page/:reviewsPage/start/:reviewsStart/orderBy/:orderBy/review/:reviewId',
    component: LazyProductReviews,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/:productId/page/:reviewsPage/start/:reviewsStart/orderBy/:orderBy',
    component: LazyProductReviews,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/:productId/page/:reviewsPage/start/:reviewsStart',
    component: LazyProductReviews,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/:productId/page/:reviewsPage/orderBy/:orderBy',
    component: LazyProductReviews,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/:productId/page/:reviewsPage',
    component: LazyProductReviews,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/review/:productId',
    component: LazyProductReviews,
    routeType: RouteType.ROUTE
  },
  {
    path: '/product/:productId/color/:colorId',
    component: LazyProductDetail,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: () => true
  },
  {
    path: '/product/:productId',
    component: LazyProductDetail,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: () => true
  },
  {
    path: '/createReview/asin/:asin',
    component: LazyAsinAddReview,
    routeType: RouteType.ROUTE
  },
  /* Influencer */
  {
    path: '/influencer/hub/new/collection',
    component: LazyNewInfluencerCollection,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/hub/collection/:id',
    component: LazyCollection,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/hub/shoppablePost/:id/:state',
    component: LazyInfluencerShoppablePost,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/hub',
    component: LazyInfluencerHub,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/callback',
    component: LazyAddInfluencerSocialProfile,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer-home',
    component: LazyInfluencerLanding,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/enroll/pending',
    component: LazyInfluencerLanding,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/enroll/decline',
    component: LazyInfluencerLanding,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/enroll/success',
    component: LazyInfluencerLanding,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/signup',
    component: LazyInfluencerLanding,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/enroll',
    component: LazyInfluencerEnroll,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/eligibility',
    component: LazyInfluencerLanding,
    routeType: RouteType.ROUTE
  },
  {
    path: '/influencer/profile/:profileHandle',
    component: LazyStorefrontCustomerView,
    routeType: RouteType.ROUTE
  },
  {
    path: '/shoppablePost/:id',
    component: LazyShoppablePostDetail,
    routeType: RouteType.ROUTE
  },
  {
    path: '/style-feed',
    component: LazyStyleFeed,
    routeType: RouteType.ROUTE
  },
  /* My Account Start */
  {
    path: '/account/favorites/:id',
    component: LazyCollection,
    routeType: RouteType.ROUTE
  },
  {
    path: '/account/favorites',
    component: LazyCollections,
    routeType: RouteType.ROUTE
  },
  {
    path: '/account',
    component: LazyAccount,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: shouldAccountIgnoreScrollToTop
  },
  {
    path: '/favorites/:id',
    component: LazyCollection,
    routeType: RouteType.ROUTE
  },
  {
    path: '/collection/:id',
    component: LazyCollection,
    routeType: RouteType.ROUTE
  },
  {
    path: '/vip/dashboard',
    to: vipDashboardUrl,
    routeType: RouteType.REDIRECT
  },
  {
    path: '/rewards/dashboard',
    to: vipDashboardUrl,
    routeType: RouteType.REDIRECT
  },
  {
    path: '/login',
    component: LazyFederatedLogin,
    routeType: RouteType.ROUTE
  },
  {
    path: '/federated-login',
    component: LazyFederatedLogin,
    routeType: RouteType.ROUTE
  },
  {
    path: '/rewards/optin/emailId/:emailId',
    component: LazyVipOptin,
    routeType: RouteType.ROUTE
  },
  {
    path: '/rewards/confirmed',
    component: LazyVipOptinAck,
    routeType: RouteType.ROUTE
  },
  {
    path: '/vip/optin/emailId/:emailId',
    component: LazyVipOptin,
    routeType: RouteType.ROUTE
  },
  {
    path: '/vip/confirmed',
    component: LazyVipOptinAck,
    routeType: RouteType.ROUTE
  },
  {
    path: '/vip/alreadyEnrolled',
    component: LazyVipOptinAck,
    routeType: RouteType.ROUTE
  },
  {
    path: '/orders/:orderId/cancel',
    component: LazyCancelOrder,
    routeType: RouteType.ROUTE
  },
  {
    path: '/orders/:orderId',
    component: LazyOrder,
    routeType: RouteType.ROUTE
  },
  {
    path: '/orders',
    component: LazyOrderHistory,
    routeType: RouteType.ROUTE,
    suppressScrollOnRouteChange: shouldAccountIgnoreScrollToTop
  },
  {
    path: '/shipments/:orderId/:shipmentId',
    component: LazyShipmentTracking,
    routeType: RouteType.ROUTE
  },
  {
    path: '/payments/:paymentId/edit',
    component: LazyEditPayment,
    routeType: RouteType.ROUTE
  },
  {
    path: '/payments/new',
    component: LazyAddPayment,
    routeType: RouteType.ROUTE
  },
  {
    path: '/payments',
    component: LazyPayments,
    routeType: RouteType.ROUTE
  },
  {
    path: '/addresses/:addressId/edit',
    component: LazyEditShipping,
    routeType: RouteType.ROUTE
  },
  {
    path: '/addresses/new',
    component: LazyAddShipping,
    routeType: RouteType.ROUTE
  },
  {
    path: '/addresses',
    component: LazyShipping,
    routeType: RouteType.ROUTE
  },
  {
    path: '/returns',
    component: LazyReturnAll,
    routeType: RouteType.ROUTE
  },
  {
    path: '/return',
    exact: true,
    component: LazyReturns,
    routeType: RouteType.ROUTE
  },
  {
    path: '/return/cancel',
    exact: true,
    component: LazyCancelReturn,
    routeType: RouteType.ROUTE
  },
  {
    path: '/returnInitiate',
    component: LazyReturns,
    routeType: RouteType.ROUTE
  },
  {
    path: '/returnLabel',
    component: LazyReturnLabel,
    routeType: RouteType.ROUTE
  },
  {
    path: '/subscriptions/:tokenTypeOrAction/:token',
    component: LazySubscriptions,
    routeType: RouteType.ROUTE
  },
  {
    path: '/subscriptions/:tokenTypeOrAction',
    component: LazySubscriptions,
    routeType: RouteType.ROUTE
  },
  {
    path: '/subscriptions/:token',
    component: LazySubscriptions,
    routeType: RouteType.ROUTE
  },
  {
    path: '/subscriptions',
    component: LazySubscriptions,
    routeType: RouteType.ROUTE
  },
  {
    path: '/create-label',
    component: LazyCreateLabel,
    routeType: RouteType.ROUTE
  },
  {
    path: '/exchange',
    component: LazyExchange,
    routeType: RouteType.ROUTE
  },
  {
    path: '/c/survey',
    component: LazyHmdSurvey,
    routeType: RouteType.ROUTE
  },
  {
    path: '/c/account-deletion-request',
    component: LazyDeleteAccount,
    routeType: RouteType.ROUTE
  },
  {
    path: '/survey',
    component: LazyHmdSurvey,
    routeType: RouteType.ROUTE
  },
  {
    path: '/c/:pageName',
    component: LazyLanding,
    routeType: RouteType.ROUTE
  },
  {
    path: '/c/*',
    component: LazyRouteNotFound,
    routeType: RouteType.ROUTE
  },
  {
    path: '/b/:brandName/brand/:brandId',
    component: LazyTaxonomyBrand,
    routeType: RouteType.ROUTE
  },
  {
    path: '/:brandName/brand/:brandId',
    component: LazyTaxonomyBrand,
    routeType: RouteType.ROUTE
  },
  {
    path: '/brand/:brandId',
    component: LazyTaxonomyBrand,
    routeType: RouteType.ROUTE
  },
  {
    path: '/egiftcard',
    to: egcUrl,
    routeType: RouteType.REDIRECT
  },
  {
    path: '/gift-certificate',
    to: egcUrl,
    routeType: RouteType.REDIRECT
  },
  {
    path: '/product',
    component: LazyRouteNotFound,
    routeType: RouteType.ROUTE
  },
  {
    path: '/p',
    component: LazyRouteNotFound,
    routeType: RouteType.ROUTE
  },
  {
    path: '/a/the-style-room/p/:seoName/product/:productId/color/:colorId',
    component: LazyProductDetail,
    suppressScrollOnRouteChange: () => true,
    routeType: RouteType.ROUTE
  },
  {
    path: '/a/the-style-room/p/:seoName/product/:productId',
    component: LazyProductDetail,
    suppressScrollOnRouteChange: () => true,
    routeType: RouteType.ROUTE
  },
  {
    path: '/error/ccBlocked',
    component: LazyCostlyCustomerPage,
    routeType: RouteType.ROUTE
  },
  {
    path: '/error/:statusToSend',
    component: LazyRouteNotFound,
    routeType: RouteType.ROUTE
  },
  {
    path: '/s/e/c/r/e/t/r/o/u/t/e',
    component: LazyBrokenPage,
    routeType: RouteType.ROUTE
  }
];

if (hasReleaseCalendar) {
  routesConfig.push({
    path: '/release-calendar',
    component: LazyLaunchCalendar,
    routeType: RouteType.ROUTE
  });
}

routesConfig.push(
  /* Prevent these requests from hitting the wildcard container. These should be 404ing since they are not valid seo term urls */
  {
    path: '/*_*',
    component: LazyRouteNotFound,
    routeType: RouteType.ROUTE
  },
  {
    path: '/*.*',
    component: LazyRouteNotFound,
    routeType: RouteType.ROUTE
  },
  {
    path: '/:seoName/',
    component: LazyWildCard,
    routeType: RouteType.ROUTE,
    exact: true,
    suppressScrollOnRouteChange: shouldSearchIgnoreScrollToTop
  },
  /* If none of our known routes match, show a nice 404 page.
       This must be the last route under the App component or else it'll match valid routes.
  */
  {
    path: '*',
    component: LazyRouteNotFound,
    routeType: RouteType.ROUTE
  },
  /* No APP Routes */
  {
    path: '/i/:productId/color/:colorId/style/:styleId/:index',
    to: '/p/product/:productId/color/:colorId',
    routeType: RouteType.REDIRECT
  },
  {
    path: '/i/:productId/style/:styleId/:index',
    to: '/p/product/:productId',
    routeType: RouteType.REDIRECT
  },
  {
    path: '/c/symphonypreview',
    component: LazySymphonyPreview,
    routeType: RouteType.ROUTE
  },
  {
    path: '/contentPreview',
    component: LazySymphonyPreview,
    routeType: RouteType.ROUTE
  }
);

if (!handlesReturnShipping) {
  routesConfig.push({
    path: '/uspsLabelDisplay',
    component: LazyReturnLabel,
    routeType: RouteType.ROUTE
  });
}

export default routesConfig;
