import { LAST_7_DAYS } from 'constants/influencerReporting';
import {
  ADD_PRODUCT_STYLE_DETAILS,
  CHANGE_FOLLOW_COUNT,
  CHANGE_FOLLOW_STATUS,
  CHANGE_LIKE_COUNT,
  CLEAR_INFLUENCER_REPORTS,
  CLEAR_PRODUCT_STYLE_DETAILS,
  DISCARD_SHOPPABLE_POST_DETAILS,
  REQUEST_INFLUENCER_DETAILS,
  SET_ALL_SHOPPABLE_POSTS,
  SET_ALL_SHOPPABLE_POSTS_COUNT,
  SET_API_STATUS,
  SET_AVAILABLE_HASHTAGS,
  SET_CUSTOMER_FOLLOW_STATUS,
  SET_INFLUENCER_APP_CONFIG,
  SET_INFLUENCER_BIO,
  SET_INFLUENCER_COLLECTION_BANNER,
  SET_INFLUENCER_COLLECTION_REPORT,
  SET_INFLUENCER_DETAILS,
  SET_INFLUENCER_DRAFT,
  SET_INFLUENCER_FOLLOWERS_COUNT,
  SET_INFLUENCER_NAME,
  SET_INFLUENCER_PRODUCT_REPORT,
  SET_INFLUENCER_PROFILE_HANDLE,
  SET_INFLUENCER_REPORT_PERIOD_SELECTION,
  SET_INFLUENCER_REPORT_VIEW,
  SET_INFLUENCER_STYLES,
  SET_INFLUENCER_SUMMARY_REPORT,
  SET_INFLUENCER_SYMPHONY_SLOTS,
  SET_INFLUENCER_TOKEN,
  SET_IS_INFLUENCER_REPORT_LOADING,
  SET_IS_INFLUENCER_STATUS,
  SET_IS_PRODUCT_SEARCH_LOADING,
  SET_NEW_COLLECTION_CREATED,
  SET_POST_STATE,
  SET_PRODUCT_SEARCH_DETAILS,
  SET_SCRUTINIZER_SCAN_STATUS,
  SET_SHOPPABLE_POST,
  SET_SHOPPABLE_POST_CREATION_SOURCE,
  SET_SHOW_INFLUENCER_HUB,
  TOGGLE_INFLUENCER_SAVE_DRAFT
} from 'constants/reduxActions';
import { InfluencerStatus, ScrutinizerScanState } from 'types/influencer';

const initialState = {
  status: InfluencerStatus.UNKNOWN,
  socialMediaProfiles: {},
  name: '',
  profileBio: '',
  stylesList: [],
  profileHandle: '',
  isLoading: false,
  isInfluencer: false,
  showInfluencerHub: false,
  influencerToken: '',
  applyWithOtherAccount: false,
  creationDate: 0,
  influencerCreationDays: 0,
  summaryReport: {},
  collectionReport: {},
  productReport: {},
  displayCollectionReport: false,
  displayProductReport: false,
  displaySummaryReport: false,
  currentCollectionReportPage: 1,
  currentProductReportPage: 1,
  totalCollectionReportPages: 0,
  totalProductReportPages: 0,
  reportView: 'Products',
  showCollectionBanner: true,
  selectedReportDuration: LAST_7_DAYS,
  isReportLoading: false,
  isProfileComplete: true,
  draft: { data: null, save: false },
  influencerId: '',
  isNewCollectionCreated: false,
  allShoppablePosts: [],
  apiStatus: '',
  scrutinizerScanStatus: ScrutinizerScanState.UNKNOWN,
  productSearchResults: {},
  isSearchLoading: false,
  productStyleDetails: {},
  followContent: {},
  availableHashtags: [],
  availableHashtagsLoaded: false
};

export default function influencer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_INFLUENCER_DETAILS:
      return { ...state, isLoading: true };
    case SET_INFLUENCER_APP_CONFIG:
      const { appConfig } = action;
      return { ...state, appConfig };
    case SET_IS_INFLUENCER_STATUS:
      let { status } = action;
      if (status === null) {
        status = InfluencerStatus.NULL;
      }
      const isInfluencer = status === InfluencerStatus.ACTIVE;
      return { ...state, status, isInfluencer };
    case TOGGLE_INFLUENCER_SAVE_DRAFT: {
      const { canSave } = action;

      return {
        ...state,
        draft: {
          data: {},
          save: canSave
        }
      };
    }
    case SET_INFLUENCER_DRAFT: {
      const {
        draft: { influencerName: name, influencerBio: profileBio, influencerStyleList: stylesList }
      } = action;

      if (!state.draft.save) {
        return state;
      }

      return {
        ...state,
        draft: {
          data: {
            name,
            profileBio,
            stylesList
          },
          save: state.draft.save
        }
      };
    }
    case SET_INFLUENCER_DETAILS:
      const { influencerDetails } = action;
      const socialMediaProfileMap = {};
      if (influencerDetails && influencerDetails.details) {
        influencerDetails.details.socialMediaProfiles.forEach(profile => {
          socialMediaProfileMap[profile.profileType] = profile;
        });
      }
      const isProfileComplete = !!influencerDetails.details.name && !!influencerDetails.details.stylesList.length;
      return {
        ...state,
        socialMediaProfiles: socialMediaProfileMap,
        name: influencerDetails.details.name || initialState.name,
        stylesList: influencerDetails.details.stylesList || initialState.stylesList,
        profileBio: influencerDetails.details.profileBio || initialState.profileBio,
        profileHandle: influencerDetails.details.profileHandle || initialState.profileHandle,
        status: influencerDetails.details.status,
        creationDate: influencerDetails.details.creationDate,
        influencerCreationDays: influencerDetails.details.influencerCreationDays,
        influencerId: influencerDetails.details.customerId,
        isProfileComplete,
        isLoading: false
      };
    case SET_INFLUENCER_BIO:
      const { profileBio } = action;
      return {
        ...state,
        profileBio
      };
    case SET_INFLUENCER_STYLES:
      const { stylesList } = action;
      return {
        ...state,
        stylesList
      };
    case SET_INFLUENCER_NAME:
      const { name } = action;
      return {
        ...state,
        name
      };
    case SET_INFLUENCER_PROFILE_HANDLE:
      const { profileHandle } = action;
      return {
        ...state,
        profileHandle
      };
    case SET_INFLUENCER_TOKEN:
      const { influencerToken } = action;
      return { ...state, influencerToken };
    case SET_SHOW_INFLUENCER_HUB:
      const { status: influencerStatus } = action;
      const showInfluencerHub = influencerStatus === InfluencerStatus.PENDING || influencerStatus === InfluencerStatus.ACTIVE;
      return { ...state, status: influencerStatus, showInfluencerHub };
    case SET_INFLUENCER_COLLECTION_REPORT:
      const { collectionReport, currentCollectionReportPage, totalCollectionReportPages, displayCollectionReport } = action;
      return {
        ...state,
        collectionReport,
        currentCollectionReportPage,
        totalCollectionReportPages,
        displayCollectionReport
      };
    case SET_INFLUENCER_PRODUCT_REPORT:
      const { productReport, currentProductReportPage, totalProductReportPages, displayProductReport } = action;
      return {
        ...state,
        productReport,
        currentProductReportPage,
        totalProductReportPages,
        displayProductReport
      };
    case SET_INFLUENCER_SUMMARY_REPORT:
      const { summaryReport, displaySummaryReport } = action;
      return { ...state, summaryReport, displaySummaryReport };
    case SET_INFLUENCER_SYMPHONY_SLOTS:
      const {
        data: { slotData, slotNames }
      } = action;
      return { ...state, collectionCreatives: { slotData, slotNames } };
    case SET_INFLUENCER_COLLECTION_BANNER:
      const { showCollectionBanner } = action;
      return { ...state, showCollectionBanner };
    case SET_INFLUENCER_REPORT_PERIOD_SELECTION:
      const { duration } = action;
      return { ...state, selectedReportDuration: duration };
    case SET_IS_INFLUENCER_REPORT_LOADING:
      const { isReportLoading } = action;
      return { ...state, isReportLoading };
    case CLEAR_INFLUENCER_REPORTS:
      return { ...state, summaryReport: {}, collectionReport: {}, productReport: {} };
    case SET_INFLUENCER_REPORT_VIEW:
      const { reportView } = action;
      return { ...state, reportView };
    case SET_SHOPPABLE_POST:
      const { shoppablePost } = action;
      return { ...state, shoppablePost };
    case CHANGE_LIKE_COUNT: {
      const updatedLikedCountData = (state.shoppablePost.likes || 0) + (action.increase ? 1 : -1);
      return { ...state, shoppablePost: { ...state.shoppablePost, likes: updatedLikedCountData } };
    }
    case SET_ALL_SHOPPABLE_POSTS_COUNT: {
      const { postId, increase } = action;
      const updatedShoppablePosts = state.allShoppablePosts.map(post => {
        if (post.postId === postId) {
          return { ...post, likes: (post.likes || 0) + (increase ? 1 : -1) };
        }
        return post;
      });
      return { ...state, allShoppablePosts: updatedShoppablePosts };
    }
    case DISCARD_SHOPPABLE_POST_DETAILS:
      return { ...state, shoppablePost: {} };
    case ADD_PRODUCT_STYLE_DETAILS:
      const { productStyleDetails: newProductStyleDetails } = action;
      return {
        ...state,
        productStyleDetails: {
          ...state.productStyleDetails,
          ...newProductStyleDetails
        }
      };
    case CLEAR_PRODUCT_STYLE_DETAILS:
      return { ...state, productStyleDetails: {} };
    case SET_POST_STATE:
      const { postState } = action;
      return {
        ...state,
        shoppablePost: {
          ...state.shoppablePost,
          postState
        }
      };
    case SET_SHOPPABLE_POST_CREATION_SOURCE:
      const { postCreationSource } = action;
      return { ...state, postCreationSource };
    case SET_NEW_COLLECTION_CREATED:
      const { isNewCollectionCreated } = action;
      return { ...state, isNewCollectionCreated };
    case SET_ALL_SHOPPABLE_POSTS:
      const { allShoppablePosts } = action;
      return { ...state, allShoppablePosts };
    case SET_API_STATUS:
      const { apiName, apiStatus } = action;
      return { ...state, apiName, apiStatus };
    case SET_SCRUTINIZER_SCAN_STATUS:
      const { scrutinizerScanStatus } = action;
      return { ...state, scrutinizerScanStatus };
    case SET_PRODUCT_SEARCH_DETAILS:
      const { productSearchResults } = action;
      return {
        ...state,
        productSearchResults
      };
    case SET_IS_PRODUCT_SEARCH_LOADING:
      const { isSearchLoading } = action;
      return { ...state, isSearchLoading };
    case SET_CUSTOMER_FOLLOW_STATUS: {
      const { status } = action;
      return { ...state, followContent: { ...state.followContent, customerFollowedStatus: status } };
    }
    case SET_INFLUENCER_FOLLOWERS_COUNT: {
      const { count } = action;
      return { ...state, followContent: { ...state.followContent, influencerFollowersCount: count } };
    }
    case CHANGE_FOLLOW_STATUS: {
      const { status } = action;
      return {
        ...state,
        followContent: {
          ...state.followContent,
          customerFollowedStatus: status
        }
      };
    }
    case CHANGE_FOLLOW_COUNT: {
      const updatedFollowedCountData = state.followContent.influencerFollowersCount + (action.status ? 1 : -1);
      return { ...state, followContent: { ...state.followContent, influencerFollowersCount: updatedFollowedCountData } };
    }
    case SET_AVAILABLE_HASHTAGS:
      return {
        ...state,
        availableHashtags: action.availableHashtags,
        availableHashtagsLoaded: true
      };
    default:
      return state;
  }
}
