// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeErrorFilledSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path
      d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM9.16683 10.3333C9.16683 9.689 8.64449 9.16667 8.00016 9.16667C7.35583 9.16667 6.8335 9.689 6.8335 10.3333C6.8335 10.9777 7.35583 11.5 8.00016 11.5C8.6445 11.5 9.16683 10.9777 9.16683 10.3333ZM8 8.6665C8.55229 8.6665 9 8.21879 9 7.6665V4.6665C9 4.11422 8.55229 3.6665 8 3.6665C7.44771 3.6665 7 4.11422 7 4.6665V7.6665C7 8.21879 7.44771 8.6665 8 8.6665Z"
      fill="currentcolor"
      stroke="currentcolor"
    />
  </Icon>
);

export default UtilityStrokeErrorFilledSmallIcon;
