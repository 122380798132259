import React from 'react';
import { useSelector } from 'react-redux';

import Link from 'components/hf/HFLink';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { evNavigationClick } from 'events/headerFooter';
import { track } from 'apis/amethyst';
import HtmlToReact from 'components/common/HtmlToReact';
import { stripSpecialCharsDashReplace as strip } from 'helpers';
import { cn } from 'helpers/classnames';
import { selectIsVip } from 'selectors/rewards';

import css from 'styles/components/hf/zappos/footerBottom.scss';

const CURRENT_YEAR = new Date().getFullYear();

const CopyRight = ({ copyright }) => {
  const {
    marketplace: { shortName },
    testId
  } = useMartyContext();

  return (
    <div data-slot-id="copyright">
      <HtmlToReact
        className={cn(shortName === 'vrsnl-zappos' ? 'text-sm text-reversed' : 'text-xs text-primary')}
        data-test-id={testId('footerLegalCopy')}
      >
        {copyright?.pageContent?.body.replace(/{{currentYear}}/g, CURRENT_YEAR)}
      </HtmlToReact>
    </div>
  );
};

const LegalLinks = ({ legalLinks }) => {
  const {
    marketplace: { shortName, phoneNumber, phoneNumberVip },
    testId
  } = useMartyContext();

  const isVip = useSelector(selectIsVip);
  const number = isVip ? phoneNumberVip : phoneNumber;

  return (
    <ul className={css.links} data-slot-id="legal-links">
      {legalLinks.footerMenu.map(({ link, text, gae }, index) => {
        const displayText = text.replace(/{{phoneNumber}}/g, number);
        return (
          <li key={text}>
            <Link
              data-test-id={testId(text)}
              to={link}
              onClick={() => {
                trackEvent('TE_FOOTER_POLICIES', strip(gae || text));
                trackLegacyEvent('Footer', 'Legal-Policies', strip(gae || text));
                track(() => [
                  evNavigationClick,
                  {
                    valueClicked: displayText,
                    parentDropdown: 'Footer Bottom',
                    isFooter: true
                  }
                ]);
              }}
            >
              {displayText}
            </Link>
            {index < legalLinks.footerMenu.length - 1 && (
              <span className={cn('ml-2 mr-2', shortName === 'vrsnl-zappos' ? 'text-reversed' : 'text-primary')}>/</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export const FooterBottom = ({ copyright, bottomLinks }) => (
  <div className={css.container}>
    <CopyRight copyright={copyright} />
    {bottomLinks?.componentName === 'footerMenu' && <LegalLinks legalLinks={bottomLinks} />}
  </div>
);

export default withErrorBoundary('FooterBottom', FooterBottom);
