import { shallowEqual } from 'fast-equals';

import {
  ADD_OOS_ITEM,
  ADD_TO_HEART_ID_LIST,
  CLEAR_HEART_LIST,
  CLEAR_HEARTS,
  DELETE_IMAGE,
  DELETE_INFLUENCER_COLLECTION,
  NEW_COLLECTION_PRODUCT,
  RECEIVE_ALL_INFLUENCER_COLLECTIONS,
  RECEIVE_ALL_LISTS,
  RECEIVE_COLLECTION_PRODUCT_DETAIL,
  RECEIVE_HEARTS,
  RECEIVE_HEARTS_IDS,
  RECEIVE_LIST_HEARTS,
  RECEIVE_LIST_HEARTS_PUBLISHED,
  RECEIVE_LIST_INFO,
  RECEIVE_SPECIFIC_ITEMID_LISTS,
  REMOVE_FROM_HEART_ID_LIST,
  REMOVE_HEARTS,
  REMOVE_NEW_COLLECTION_PRODUCT,
  REQUEST_ALL_INFLUENCER_COLLECTIONS,
  REQUEST_ALL_INFLUENCER_COLLECTIONS_FAILED,
  SET_NEW_COLLECTION_NAME,
  SHARE_COLLECTION,
  TOGGLE_COLLECTION_PUBLISHED_STATUS,
  TOGGLE_HEARTING_LOGIN_MODAL,
  TOGGLE_INFLUENCER_COLLECTION_VISIBILITY,
  UPDATE_LIST
} from 'constants/reduxActions';
import { getAsinFromProductUrl } from 'helpers/MyAccountUtils';

export const initialState = {
  hearts: null,
  oosHearts: [],
  heartsStyleIds: [],
  collections: [],
  heartLoginPrompt: { isOpen: false, id: null },
  newCollectionName: null,
  isLoading: false,
  newCollectionProduct: []
};

export default function hearts(state = initialState, action) {
  const {
    type,
    hearts,
    heartsStyleIds,
    nextPageToken,
    collectionNextPageToken,
    concat,
    styleId,
    itemId,
    open,
    id,
    list,
    lists,
    itemIdLists,
    listId,
    colorId,
    product,
    toPublic,
    itemIdsToRemove,
    shareToken,
    setPublishedStatus,
    listName,
    overrideCollections
  } = action;

  switch (type) {
    case RECEIVE_HEARTS:
      const newHearts = concat ? [...state.hearts, ...hearts] : hearts;
      const heartsFilteredOOS = newHearts.reduce((acc, product) => {
        if (!product.asin) {
          product.asin = getAsinFromProductUrl(product.productUrl);
        }
        if (product.quantity) {
          acc.push(product);
        }
        return acc;
      }, []);
      return {
        ...state,
        hearts: newHearts,
        heartsStyleIds: newHearts.map(product => product.styleId),
        heartsFilteredOOS, // filtered out "Out of Stock" items
        heartsFilteredOOSAndAsin: heartsFilteredOOS.filter(({ asin }) => asin), // filtered out "Out of Stock" and "No Asin"
        nextPageToken
      };
    case RECEIVE_LIST_HEARTS:
      return {
        ...state,
        collections: state.collections.concat({
          listId,
          hearts,
          nextPageToken
        })
      };
    case RECEIVE_LIST_HEARTS_PUBLISHED:
      return {
        ...state,
        collections: state.collections.concat({
          hearts,
          nextPageToken,
          shareToken
        })
      };
    case NEW_COLLECTION_PRODUCT:
      return {
        ...state,
        newCollectionProduct: [product]
      };
    case REMOVE_NEW_COLLECTION_PRODUCT:
      return {
        ...state,
        newCollectionProduct: []
      };
    case CLEAR_HEART_LIST:
      return {
        ...state,
        lists: [],
        collections: []
      };
    case RECEIVE_HEARTS_IDS:
      /**
       * This seems to happen a couple of times, so don't update if the lists are equivalent.
       * Eventually, we should figure out where the double request comes from.
       */
      const newIds = heartsStyleIds.map(product => product.itemId).sort();
      if (shallowEqual(newIds, state.heartsStyleIds)) {
        return state;
      }
      return {
        ...state,
        heartsStyleIds: heartsStyleIds.map(product => product.itemId)
      };
    case REMOVE_FROM_HEART_ID_LIST:
      return {
        ...state,
        heartsStyleIds: state.heartsStyleIds.filter(item => item !== styleId)
      };
    case ADD_TO_HEART_ID_LIST:
      return {
        ...state,
        heartsStyleIds: [styleId, ...state.heartsStyleIds]
      };
    case ADD_OOS_ITEM:
      return {
        ...state,
        oosHearts: state.oosHearts.concat(itemId)
      };
    case CLEAR_HEARTS:
      return {
        ...state,
        hearts: null,
        heartsStyleIds: null,
        nextPageToken: null,
        list: {}
      };
    case TOGGLE_HEARTING_LOGIN_MODAL:
      return {
        ...state,
        heartLoginPrompt: {
          isOpen: open,
          // Maintain the old ID even when closed. That way we can avoid a pesky race condition.
          // https://github01.zappos.net/mweb/marty/issues/11608
          id: id || state.heartLoginPrompt.id
        }
      };
    case RECEIVE_LIST_INFO:
      const { metadata = {} } = list;
      const { headerLayout } = metadata;
      return {
        ...state,
        list: {
          ...list,
          metadata: {
            ...metadata,
            headerLayout: headerLayout !== 0 ? headerLayout : 1
          }
        }
      };
    case RECEIVE_COLLECTION_PRODUCT_DETAIL:
      const newState = Object.assign({}, state, product, {
        colorId,
        selectedSizing: {},
        validation: { dimensions: {} }
      });

      for (const dim of product.detail.sizing.dimensions) {
        if (dim.units[0].values.length === 1) {
          newState.selectedSizing[`d${dim.id}`] = dim.units[0].values[0].id;
        }
      }

      return newState;
    case RECEIVE_ALL_LISTS:
      const newLists = concat ? { lists: [...state.lists, ...lists], collectionNextPageToken } : { lists, collectionNextPageToken };
      return { ...state, ...newLists, isLoading: false };
    case RECEIVE_SPECIFIC_ITEMID_LISTS:
      return { ...state, itemIdLists: [...itemIdLists.ids] };
    case TOGGLE_INFLUENCER_COLLECTION_VISIBILITY:
      let updatedState = { ...state };
      const { list: currentList, lists: currentLists } = updatedState;
      if (currentList !== undefined && currentList.listId === listId) {
        // Action from Collection Page
        updatedState = {
          ...updatedState,
          list: {
            ...currentList,
            metadata: {
              ...currentList.metadata,
              public: toPublic
            }
          }
        };
      }

      if (Array.isArray(currentLists) && currentLists.length) {
        // Action from Collections Page
        const index = currentLists.findIndex(list => list.listId === listId);

        if (index !== -1) {
          const updatedListItem = {
            ...currentLists[index],
            metadata: {
              ...currentLists[index].metadata,
              public: toPublic
            }
          };

          const updatedLists = [...currentLists.slice(0, index), updatedListItem, ...currentLists.slice(index + 1)];

          updatedState = {
            ...updatedState,
            lists: updatedLists
          };
        }
      }
      return updatedState;

    case UPDATE_LIST:
      let revisedState = { ...state };
      revisedState = {
        ...revisedState,
        list: {
          ...state.list,
          ...list,
          metadata: {
            ...state.list.metadata,
            ...list.metadata
          }
        }
      };

      // Updating 'lists' field as well for consistency
      const { lists: collectionLists } = revisedState;
      if (Array.isArray(collectionLists) && collectionLists.length) {
        const index = collectionLists.findIndex(collection => collection.listId === list.listId);

        if (index !== -1) {
          const updatedListItem = {
            ...collectionLists[index],
            ...list,
            metadata: {
              ...collectionLists[index].metadata,
              ...list.metadata
            }
          };

          const updatedLists = [...collectionLists.slice(0, index), updatedListItem, ...collectionLists.slice(index + 1)];

          revisedState = {
            ...revisedState,
            lists: updatedLists
          };
        }
      }
      return revisedState;
    case DELETE_IMAGE:
      return {
        ...state,
        list: {
          ...state.list,
          metadata: {
            ...state.list.metadata,
            images: []
          }
        }
      };
    case REMOVE_HEARTS:
      const updatedHearts = state.hearts.filter(({ itemId }) => !itemIdsToRemove.includes(itemId));
      return {
        ...state,
        hearts: updatedHearts
      };
    case REQUEST_ALL_INFLUENCER_COLLECTIONS:
      return {
        ...state,
        isLoading: true
      };
    case REQUEST_ALL_INFLUENCER_COLLECTIONS_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case RECEIVE_ALL_INFLUENCER_COLLECTIONS: {
      return overrideCollections
        ? {
            ...state,
            collections: lists,
            isLoading: false
          }
        : {
            ...state,
            lists,
            isLoading: false
          };
    }
    case TOGGLE_COLLECTION_PUBLISHED_STATUS: {
      let newState = state;
      const { lists: allLists, list: thisList } = state;
      if (allLists) {
        allLists.find(list => list.listId === listId).metadata.published = setPublishedStatus;
        newState = {
          ...newState,
          lists: allLists
        };
      }
      if (thisList && thisList.listId === listId && thisList.metadata) {
        thisList.metadata.published = setPublishedStatus;
        newState = {
          ...newState,
          list: thisList
        };
      }
      return newState;
    }
    case SET_NEW_COLLECTION_NAME:
      return {
        ...state,
        newCollectionName: listName
      };
    case DELETE_INFLUENCER_COLLECTION:
      const remainingLists = state.lists.filter(list => list.listId !== action.listId);
      const remainingCollections = state.collections.filter(collection => collection.listId !== action.listId);
      return {
        ...state,
        lists: remainingLists,
        collections: remainingCollections
      };
    case SHARE_COLLECTION:
      const updatedLists = state.lists?.map(list => {
        if (list.listId === action.listId && list.metadata) {
          list.metadata.sharedByOwner = true;
        }
        return list;
      });
      return { ...state, lists: updatedLists };
    default:
      return state;
  }
}
