/* eslint import/namespace: [2, { allowComputed: true }] import/no-namespace: 0 */
import { lazyExecute } from '.';

// this is for line `eventConstants[event]` which cannot be statically analyzed
import * as eventConstants from 'constants/analytics';
import { logDebug } from 'middleware/logger';
import AppEnvironment from 'helpers/AppEnvironment';
import { onEvent } from 'helpers/EventHelpers';

const COMPLETED_REQUEST_SESSION_STORAGE_KEY = '_nightWatchCompletedRequests';
const REQUEST_SESSION_STORAGE_KEY = '_nightWatchRequests';
const MAX_LOCAL_STORAGE_VALUES = 50;

type AnalyticsEvent = { type: string; payload: unknown };
type AddEventCb = (e: AnalyticsEvent) => void;

const k2Stubs = {
  addEvent: (e: { type: string; payload: unknown }) => {
    logDebug('event.cgi', e.type, e.payload); // debug this out for development help, track.cgi is already printed elsewhere in dev.
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  track: (_bundle: unknown) => {}
};
// If/when we are ready to replace karakoram with k2, we just need to re-implement the isBrowser check, configureK2, and re-add the library
const { addEvent: addK2Event, track: trackK2PageView } = k2Stubs;

const starValue = (value?: string | null): string => (typeof value !== 'undefined' && value !== null ? `*${value}` : '');

export const trackPage = (bundle: unknown) => {
  trackK2PageView(bundle);
};

export const e2eEventStorage = (request: unknown) => {
  if (typeof window !== 'undefined' && window.renderTestLocators) {
    storeRequest({ url: 'zfcEvent', request }, REQUEST_SESSION_STORAGE_KEY);
  }
};

export const trackLegacyEvent = (action: string, label: string | null, value: unknown, addEvent: AddEventCb = addK2Event): void => {
  if (!AppEnvironment.hasZfc || window.mk2) {
    addEvent({
      type: [action, label].filter(v => typeof v !== 'undefined').join('*'),
      payload: value
    });
  } else {
    lazyExecute(() => {
      // form the payload for the zfc sendEvent
      const zfcEventData = ['sendEvent', `${action}${starValue(label)}`, (value as string) || null];

      window.zfc.push(zfcEventData);
    });
  }
  e2eEventStorage({ type: `${action}${starValue(label)}`, payload: value });
};

export const trackEvent = (
  event: string,
  category?: string | null,
  { l3: alternativeL3, addEvent = addK2Event }: { l3?: string; addEvent?: AddEventCb } = {}
) => {
  const eventConstant = (eventConstants as Record<string, { [x: string]: string }>)[event];
  if (!eventConstant) {
    logDebug(`No analytics event ${event}`);
    return;
  }

  const {
    l1: level1, // Action, Page View
    l2, // PDP, Cart, Search
    l3: level3, // Modify Quantity, Typed Search
    l4: level4 // sku, asin, quantity
  } = eventConstant;

  const l1 = level1 || 'Action'; // if l1 is not defined, default to 'Action'
  const l3 = level3 || alternativeL3 || '-';
  const l4 = level4 || category; // if level4/l4 is defined, use that, otherwise use the 'category' argument

  if (!AppEnvironment.hasZfc || window.mk2) {
    addEvent({
      type: [l1, l2, l4].filter(v => typeof v !== 'undefined').join('*'),
      payload: l3
    });
  } else {
    lazyExecute(() => {
      // form the payload for the zfc sendEvent
      const zfcEventData = ['sendEvent', `${l1}${starValue(l2)}${starValue(l4)}`, l3 || null];

      window.zfc.push(zfcEventData);
    });
  }
  e2eEventStorage({
    type: `${l1}${starValue(l2)}${starValue(l4)}`,
    payload: l3
  });
};

export const trackOrderConfirmationEvent = (zfcEventData: AnalyticsEvent, addEvent: AddEventCb = addK2Event) => {
  if (!AppEnvironment.hasZfc || window.mk2) {
    addEvent(zfcEventData);
  } else {
    lazyExecute(() => {
      /**
       * @todo global type zfc.push is different from what we're sending here. Check if
       * this is just a type issue of if we're actually sending wrong-typed data.
       * */
      window.zfc.push(['sendFullEvent', zfcEventData as any]);
    });
  }
};

function shouldTrackElement(element: Element) {
  const trackEvent = element.getAttribute('data-te');
  const trackAction = element.getAttribute('data-track-action');
  const trackLabel = element.getAttribute('data-track-label');
  const trackValue = element.getAttribute('data-track-value');
  return (trackAction && trackLabel && trackValue) || trackEvent;
}

/*
  Event handler that checks whether a clicked element has analytics information as data-te attribute
  and sends data to analytics if necessary.
*/
function handleClick(event: Event) {
  const element = findElementWithAttributes(event.target as Element);

  if (element) {
    const eventName = element.getAttribute('data-te');
    if (eventName) {
      // if the element is using the new constants file
      const eventData = element.getAttribute('data-ted') || '';
      trackEvent(eventName, eventData);
    } else {
      // the older data-track-* approach
      const trackAction = element.getAttribute('data-track-action') || '';
      const trackLabel = element.getAttribute('data-track-label') || '';
      const trackValue = element.getAttribute('data-track-value');
      trackLegacyEvent(trackAction, trackLabel, trackValue);
    }
  }
}

function findElementWithAttributes(srcElement: Element) {
  if (!srcElement) {
    return;
  }

  if (shouldTrackElement(srcElement)) {
    return srcElement;
  }

  if (srcElement.parentElement) {
    return findElementWithAttributes(srcElement.parentElement);
  }

  return;
}

export function setupDataAttributeAnalytics() {
  const setupBodyClick = () => onEvent(document.body, 'click', handleClick);
  onEvent(document, 'DOMContentLoaded', setupBodyClick);
}

interface ProductIdentifiersProto {
  asin: string;
  productId: string;
  styleId: string;
  stockId: string;
  colorId: string;
}

/**
 * Given a list of items, return array in the ProductIdentifiers amethyst format below
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ProductIdentifiers.proto
 */
export function createProductIdentifiersProto(
  items: (ProductIdentifiersProto | { product?: ProductIdentifiersProto })[]
): ProductIdentifiersProto[] {
  return items.map(item => {
    let productItem = item as ProductIdentifiersProto;

    const { product } = item as { product?: ProductIdentifiersProto };
    if (product) {
      productItem = product;
    }

    const { asin, productId, styleId, stockId, colorId } = productItem;
    return {
      asin,
      productId,
      styleId,
      stockId,
      colorId
    };
  });
}

/*
  Get the Amethyst pageType from the marty/pixel pageType
  Taken from `enum PageType`
  https://code.amazon.com/packages/AmethystEvents/blobs/550dbac47f4d540a123099fce86197b501df93e9/--/configuration/include/com/zappos/amethyst/website/WebsiteEnums.proto#L85
*/
export const PAGE_TYPE_MAP = {
  brand: 'BRAND_PAGE',
  homepage: 'HOMEPAGE',
  pdp: 'PRODUCT_PAGE',
  product: 'PRODUCT_PAGE',
  search: 'SEARCH_PAGE',
  cart: 'CART_PAGE',
  cartModal: 'CART_PAGE_MODAL',
  landing: 'LANDING_PAGE',
  checkout: 'CHECKOUT_PAGE',
  account: 'MY_ACCOUNT_PAGE',
  confirmation: 'ORDER_CONFIRMATION_PAGE',
  orders: 'ORDER_HISTORY_PAGE',
  orderInformation: 'ORDER_DETAIL_PAGE',
  favorites: 'FAVORITES_PAGE',
  recommended: 'INTERSTITIAL_ADD_TO_CART_PAGE',
  influencercollection: 'INFLUENCER_COLLECTION_PAGE',
  influencerShoppablePost: 'INFLUENCER_SHOPPABLE_POSTS_PAGE',
  influencerhub: 'INFLUENCER_PAGE',
  searchByPhoto: 'SEARCH_BY_PHOTO_PAGE'
};

export function getAmethystPageType(pageType: string): string {
  return PAGE_TYPE_MAP[pageType as keyof typeof PAGE_TYPE_MAP] || 'UNKNOWN_PAGE_TYPE';
}

export const storeRequest = (requestInfo: unknown, storageKey: string = COMPLETED_REQUEST_SESSION_STORAGE_KEY): void => {
  let savedRequests = (jsonTryParse(window.sessionStorage.getItem(storageKey)) as unknown[]) || [];

  if (savedRequests.length > MAX_LOCAL_STORAGE_VALUES) {
    savedRequests = savedRequests.slice(savedRequests.length - MAX_LOCAL_STORAGE_VALUES);
  }

  savedRequests.push(requestInfo);

  window.sessionStorage.setItem(storageKey, JSON.stringify(savedRequests));
};

const jsonTryParse = (jsonString: string): unknown => {
  let parsedJson: unknown;
  try {
    parsedJson = JSON.parse(jsonString);
  } catch (err) {
    /* Don't blow up if we're not getting actual JSON */
  }
  return parsedJson;
};
