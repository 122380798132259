import { INFLUENCER_MSA_FOLDER_PATH } from 'constants/influencerPages';

// Report Period options
export const LAST_7_DAYS = { label: 'Last 7 days', value: '7' };
export const LAST_30_DAYS = { label: 'Last 30 days', value: '30' };
export const YEAR_TO_DATE = { label: 'Year to date', value: 'ytd' };

// Reporting error types
export const CLIENT_SIDE_ERRORS = '400';
export const SERVER_SIDE_ERRORS = '500';

// Reporting Server side Error message
export const REPORT_TOGGLE_OFF_ERROR = 'No active reporting config found for environment';

// Reporting Error page data
export const REPORTING_ERROR_PAGE_DATA = {
  [CLIENT_SIDE_ERRORS]: {
    heading: 'We are sorry, we can’t find the data at the moment',
    description: 'We didn’t anticipate this taking so long. Things are a little unstable here.\nPlease refresh the page and try again.',
    buttonText: 'Try again',
    iconUrl: `${INFLUENCER_MSA_FOLDER_PATH}Cat.png`
  },
  [SERVER_SIDE_ERRORS]: {
    heading: 'Something went wrong. Our team is working on it.',
    description: 'In the meawhile, you can wait a few minutes and refresh the page. We will be back soon.',
    buttonText: 'Refresh the page',
    iconUrl: `${INFLUENCER_MSA_FOLDER_PATH}Lightning.png`
  }
};

// Summary Metric Names
export const SUMMARY_METRIC_NAMES = {
  totalClicksName: 'Total Clicks',
  totalPDPClicksName: 'Total Product Clicks',
  totalCollectionClicksName: 'Total Collection Clicks'
};

// Summary Metric Definitions
export const SUMMARY_METRIC_DEFINITIONS = {
  totalClicksDefinition: 'Measured as unique clicks from links you shared with your followers',
  totalPDPClicksDefinition: 'Measured as unique clicks to your products based either by direct product links or from shared collections',
  totalCollectionClicksDefinition: 'Measured as unique clicks to your collections that you shared with your audience'
};

// Sample Summary Report
export const SAMPLE_SUMMARY_REPORT = {
  totalClicks: 4685,
  totalPDPClicks: 3451,
  totalCollectionClicks: 1234
};

// Sample List Count
export const SAMPLE_LIST_COUNT = {
  listCount: 5,
  isListCountUpdated: true
};

// Sample Collections Report
export const SAMPLE_COLLECTIONS_REPORT = {
  page: 1,
  pageLength: 10,
  totalRecords: 15,
  collections: [
    {
      id: 'h.a6acb0af-b783-428a-9c85-619df3818a12',
      name: "Winter Collection with 6'",
      creationTime: '1635915286',
      products: 2,
      soldOutProducts: 0,
      clicks: 1037
    },
    {
      id: 'h.c774f360-6637-4b8d-8bb9-574ca1d0a93a',
      name: 'Summer Collection',
      creationTime: '1636260886',
      products: 7,
      soldOutProducts: 4,
      clicks: 1182
    },
    {
      id: 'h.c774f360-aaaa-4b8d-8bb9-574ca1d0a93a',
      name: 'Summer Collection Redux',
      creationTime: '1626324886',
      products: 7,
      soldOutProducts: 7,
      clicks: 2134
    },
    {
      id: 'h.a6aafs0af-baf3-428a-fs85-619dafsdj324e',
      name: 'Winter Collection Redux',
      collectionStatus: 'DELETED',
      creationTime: '1626324823',
      products: 3,
      soldOutProducts: 0,
      clicks: 1423
    }
  ]
};

// Sample Products Report
export const SAMPLE_PRODUCTS_REPORT = {
  page: 1,
  pageLength: 10,
  totalRecords: 15,
  products: [
    {
      productName: '574D Summer Theory',
      productId: '9523743',
      brandId: '409',
      brandName: 'New Balance Classics',
      styleId: '5368929',
      colorId: '916524',
      link: '/product/9523743/color/916524',
      clicks: 0,
      quantity: 6,
      rating: 5.0,
      price: '$63.90',
      reviewCount: 2,
      merchantId: 'AMWRKCC3EWKYM'
    },
    {
      productName: 'Vans Old Skool',
      productId: '103789',
      styleId: '3',
      link: '/product/103789/color/3',
      clicks: 1000,
      quantity: 3,
      merchantId: 'A30HUN4C7FA8PG'
    },
    {
      productName: 'Hoka One One',
      productId: '9463717',
      styleId: '893776',
      link: '/product/9463717/color/893776',
      clicks: 1200,
      quantity: 0,
      merchantId: 'AMWRKCC3EWKYM'
    }
  ]
};
