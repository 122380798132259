import type { AnyAction } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { stringify } from 'query-string';

import type { AppState } from 'types/app';
import type { InfluencerContent, InfluencerContentSlotData } from 'types/influencer';
import { InfluencerContentType } from 'types/influencer';
import { uploadInfluencerImage } from 'actions/influencer/influencer';
import { getContentImage } from 'reducers/influencer/influencerContent';
import { fetchErrorMiddlewareMaybeJson } from 'middleware/fetchErrorMiddleware';
import { getRequestGuid } from 'helpers/environment/context';
import { trackError } from 'helpers/ErrorUtils';
import {
  ADD_MORE_INFLUENCER_CONTENT,
  CHANGE_LIKE_COUNT,
  CHANGE_LIKE_STATUS,
  REQUEST_ALL_INFLUENCER_CONTENT,
  SET_ALL_INFLUENCER_CONTENT,
  SET_BRAND_INFLUENCER_CONTENT_SLOT_DATA,
  SET_CUSTOMER_LIKE_CONTENT,
  SET_INFLUENCER_COLLECTION_CONTENT_EMPTY,
  SET_INFLUENCER_CONTENT_CREATION_FAILED,
  SET_INFLUENCER_CONTENT_CREATION_SUCCESS,
  SET_INFLUENCER_SHOPPABLE_CONTENT_EMPTY,
  START_INFLUENCER_CONTENT_CREATION
} from 'constants/reduxActions';
import { IMAGE } from 'constants/shoppablePosts';
import { setAllShoppablePostsCount } from 'actions/influencer/shoppablePost';
import { selectMafiaConfig } from 'selectors/environment';
import {
  createAndSubmitInfluencerContent,
  getAllContentWithPagination,
  getAllInfluencerContent,
  getPostsLikeStatus,
  searchInfluencerContent,
  updatePostLikeStatus
} from 'apis/mafia/influencer';

const requestAllInfluencerContent = () => ({
  type: REQUEST_ALL_INFLUENCER_CONTENT
});

const setAllInfluencerContent = (contents: InfluencerContent[], nextPageToken: string | undefined) => ({
  type: SET_ALL_INFLUENCER_CONTENT,
  contents,
  nextPageToken
});

const addMoreInfluencerContent = (contents: InfluencerContent[], nextPageToken: string | undefined) => ({
  type: ADD_MORE_INFLUENCER_CONTENT,
  contents,
  nextPageToken
});
const startInfluencerContentCreation = (content: string) => ({
  type: START_INFLUENCER_CONTENT_CREATION,
  content
});

const setInfluencerContentCreationSuccess = (content: string) => ({
  type: SET_INFLUENCER_CONTENT_CREATION_SUCCESS,
  content
});

const setInfluencerContentCreationFailed = (content: string) => ({
  type: SET_INFLUENCER_CONTENT_CREATION_FAILED,
  content
});

export const setInfluencerCollectionContentEmpty = (isContentEmpty: boolean) => ({
  type: SET_INFLUENCER_COLLECTION_CONTENT_EMPTY,
  isCollectionContentEmpty: isContentEmpty
});

export const setInfluencerShoppableContentEmpty = (isContentEmpty: boolean) => ({
  type: SET_INFLUENCER_SHOPPABLE_CONTENT_EMPTY,
  isShoppableContentEmpty: isContentEmpty
});

export const setBrandInfluencerContentSlotData = (brandInfluencerContentSlotData: InfluencerContentSlotData) => ({
  type: SET_BRAND_INFLUENCER_CONTENT_SLOT_DATA,
  brandInfluencerContentSlotData
});

export const changeLikeStatus = (postId: string, status: boolean) => ({
  type: CHANGE_LIKE_STATUS,
  postId,
  status
});

export const changeLikeCount = (increase: boolean) => ({
  type: CHANGE_LIKE_COUNT,
  increase
});

export const setCustomerLikeContent = (content: { [key: string]: boolean }) => ({
  type: SET_CUSTOMER_LIKE_CONTENT,
  content
});

export function fetchAllInfluencerContent(
  influencerId: string,
  authRequired: boolean = true,
  contentTypes: string[] = [InfluencerContentType.PROFILE_IMAGE, InfluencerContentType.COVER_IMAGE]
): ThunkAction<void, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    dispatch(requestAllInfluencerContent());

    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    let queryString = '';
    contentTypes.forEach(ct => (queryString = `contentTypes=${ct}&${queryString}`));
    return getAllInfluencerContent(mafiaConfig, influencerId, queryString, authRequired, cookies)
      .then(fetchErrorMiddlewareMaybeJson)
      .then((resp: { influencerContents: InfluencerContent[] }) => {
        dispatch(setAllInfluencerContent(resp?.influencerContents || [], undefined));
      })
      .catch((e: Error) => {
        trackError('NON-FATAL', 'Could not fetch content for influencer.', e);
        dispatch(setAllInfluencerContent([], undefined));
      });
  };
}

export function fetchAllInfluencerContentWithPagination(
  limit: number,
  contentTypes: string[],
  loadMore: boolean,
  nextPageToken?: string,
  selectedHashTags?: string[]
): ThunkAction<void, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    if (!loadMore) {
      dispatch(requestAllInfluencerContent());
    }

    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    let queryString = '';
    contentTypes.forEach(ct => (queryString = `contentTypes=${ct}&${queryString}`));
    queryString = `${queryString}limit=${limit}`;
    if (nextPageToken) {
      queryString = `${queryString}&nextPageToken=${nextPageToken}`;
    }
    if (selectedHashTags) {
      selectedHashTags.forEach(ht => (queryString = `${queryString}&hashTags=${ht}`));
    }
    const setContent = loadMore ? addMoreInfluencerContent : setAllInfluencerContent;
    return getAllContentWithPagination(mafiaConfig, queryString, cookies)
      .then(fetchErrorMiddlewareMaybeJson)
      .then((resp: { contents: InfluencerContent[]; nextPageToken: string }) => {
        dispatch(setContent(resp?.contents || [], resp?.nextPageToken));
      })
      .catch((e: Error) => {
        trackError('NON-FATAL', 'Could not fetch content for influencer.', e);
        dispatch(setContent([], undefined));
      });
  };
}
export function createInfluencerContent(
  influencerId: string,
  contentId: string,
  contentType: string,
  file: File,
  fileExtension: string
): ThunkAction<void, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const {
      influencerContent: { contents },
      cookies,
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    // Check if there is any available content for the content type
    if (!contentId) {
      const content = getContentImage(contents, contentType, true);
      contentId = content?.contentId || getRequestGuid(); // Generate a random UUID if contentId is not present
    }

    dispatch(startInfluencerContentCreation(contentId));

    return uploadInfluencerImage(file, fileExtension, contentId, contentType, 0, cookies, mafiaConfig, location, dispatch).then(
      (response: { physicalId?: string; scrutinizerFailed?: boolean }) => {
        const { physicalId, scrutinizerFailed } = response;
        if (!!scrutinizerFailed || !physicalId) {
          return dispatch(setInfluencerContentCreationFailed(contentId));
        }

        const data = {
          influencerId,
          contentId,
          contentType,
          mediaMetadata: [
            {
              mediaId: physicalId,
              mediaType: IMAGE,
              mediaExtension: fileExtension
            }
          ]
        };

        return createAndSubmitInfluencerContent(mafiaConfig, data, cookies)
          .then(fetchErrorMiddlewareMaybeJson)
          .then(() => {
            dispatch(fetchAllInfluencerContent(influencerId));
            dispatch(setInfluencerContentCreationSuccess(contentId));
          })
          .catch((e: Error) => {
            trackError('NON-FATAL', 'Could not create content for influencer.', e);
            dispatch(setInfluencerContentCreationFailed(contentId));
          });
      }
    );
  };
}

export function fetchInfluencerContentForSearch(
  query: string,
  contentTypes: string[],
  count: number,
  source: string
): ThunkAction<void, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    let queryString = stringify({ query, count, source });
    contentTypes.forEach(ct => (queryString = `contentTypes=${ct}&${queryString}`));

    return searchInfluencerContent(mafiaConfig, queryString, cookies)
      .then(fetchErrorMiddlewareMaybeJson)
      .then((resp: { contents: InfluencerContent[]; slotData: InfluencerContentSlotData }) => {
        dispatch(setAllInfluencerContent(resp?.contents || [], undefined));
        dispatch(setBrandInfluencerContentSlotData(resp?.slotData || {}));
      })
      .catch((e: Error) => {
        trackError('NON-FATAL', 'Could not fetch content for search.', e);
        dispatch(setAllInfluencerContent([], undefined));
      });
  };
}

export function fetchCustomerPostLikedStatus(postIdList: string[]): ThunkAction<void, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const {
      cookies,
      environmentConfig: {
        api: { mafia }
      }
    } = getState();
    let queryString = '';
    postIdList.forEach(postId => (queryString = `postIdList=${postId}&${queryString}`));
    return getPostsLikeStatus(mafia, queryString, cookies)
      .then(fetchErrorMiddlewareMaybeJson)
      .then((response: { postIdToStatus: { [key: string]: boolean } }) => {
        dispatch(setCustomerLikeContent(response.postIdToStatus));
      })
      .catch((e: Error) => {
        trackError('NON-FATAL', 'Could not fetch post Id List status for customer.', e);
      });
  };
}

export function updateCustomerPostLikedStatus(postId: string, increase: boolean): ThunkAction<void, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const {
      cookies,
      environmentConfig: {
        api: { mafia }
      }
    } = getState();
    const method = increase ? 'post' : 'delete';
    return updatePostLikeStatus(mafia, postId, method, cookies)
      .then(fetchErrorMiddlewareMaybeJson)
      .then((response: { status: boolean }) => {
        if (!response.status) {
          dispatch(changeLikeStatus(postId, !increase));
          dispatch(changeLikeCount(!increase));
          dispatch(setAllShoppablePostsCount(postId, !increase));
        }
      })
      .catch((e: Error) => {
        trackError('NON-FATAL', 'Could not update post Id status for customer.', e);
        dispatch(changeLikeStatus(postId, !increase));
        dispatch(changeLikeCount(!increase));
        dispatch(setAllShoppablePostsCount(postId, !increase));
      });
  };
}
