import queryString from 'query-string';

import { AlertBG, AlertIcon, AlertState, ModalType } from 'types/influencer';

// Shoppable Post Creation Stages
export const SHOPPABLE_POST_MEDIA_UPLOAD = 'SHOPPABLE_POST_MEDIA_UPLOAD';
export const SHOPPABLE_POST_PHOTO_PRODUCT_TAG = 'SHOPPABLE_POST_PHOTO_PRODUCT_TAG';
export const SHOPPABLE_POST_VIDEO_PRODUCT_TAG = 'SHOPPABLE_POST_VIDEO_PRODUCT_TAG';
export const SHOPPABLE_POST_UPLOAD_LOADING = 'SHOPPABLE_POST_UPLOAD_LOADING';
export const SHOPPABLE_POST_INVALID_MEDIA = 'SHOPPABLE_POST_INVALID_MEDIA';
export const SHOPPABLE_POST_CONFIRMATION = 'SHOPPABLE_POST_CONFIRMATION';
export const SHOPPABLE_POST_BROKEN_API = 'SHOPPABLE_POST_BROKEN_API';
export const SCRUTINIZER_SCAN_BROKEN_API = 'SCRUTINIZER_SCAN_BROKEN_API';
export const SHOPPABLE_POST_LOADING = 'SHOPPABLE_POST_LOADING';

// API names
export const SAVE_SHOPPABLE_POST = 'SAVE_SHOPPABLE_POST';
export const SUBMIT_SHOPPABLE_POST = 'SUBMIT_SHOPPABLE_POST';
export const UPLOAD_SHOPPABLE_PHOTO = 'UPLOAD_SHOPPABLE_PHOTO';
export const UPLOAD_SHOPPABLE_VIDEO = 'UPLOAD_SHOPPABLE_VIDEO';
export const FETCH_SHOPPABLE_POST = 'FETCH_SHOPPABLE_POST';
export const EDIT_SHOPPABLE_POST = 'EDIT_SHOPPABLE_POST';
export const FETCH_ALL_SHOPPABLE_POSTS = 'FETCH_ALL_SHOPPABLE_POSTS';
export const DELETE_SHOPPABLE_POST = 'DELETE_SHOPPABLE_POST';
export const SHOPPABLE_POST_CONTENT_ACTION = 'SHOPPABLE_POST_CONTENT_ACTION';
export const CURRENT_CONTENT_ACTION = 'CURRENT_CONTENT_ACTION';
export const FETCH_ALL_AVAILABLE_HASHTAGS = 'FETCH_ALL_AVAILABLE_HASHTAGS';

// API queries
export const APPROVED_SHOPPABLE_POST_QUERY = queryString.stringify({ postState: 'approved', hidden: false });

// Post Action
export const POST_ACTION_DRAFT = 'draft';
export const POST_ACTION_SUBMIT = 'submit';

// API status
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const FETCHING = 'FETCHING';
export const UPLOADED = 'UPLOADED';

// File Upload Stages
export const UPLOADING = 'Uploading';
export const PROCESSING = 'Processing';

// Post States
export const APPROVED_STATE = 'approved';
export const UNAPPROVED_STATE = 'unapproved';

// Uploaded media properties
export const ACCEPTED_INPUT_TYPES_PHOTO = '.jpg,.jpeg,.png';
export const ACCEPTED_INPUT_TYPES_VIDEO = '.mp4,.mov,.m4v';
export const ALLOWED_PHOTO_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
export const ALLOWED_VIDEO_TYPES = ['video/m4v', 'video/mp4', 'video/quicktime'];
export const MAX_ALLOWED_PHOTO_SIZE_BYTES = 10485760; // 10 * 1024 * 1024
export const MAX_ALLOWED_VIDEO_SIZE_BYTES = 2097152000; // 2000 * 1024 * 1024
export const PORTRAIT_ASPECT_RATIO_MAX = 1.8; // 16 / 9
export const PORTRAIT_ASPECT_RATIO_MIN = 1;
export const VIDEO_MIN_DURATION = 15;
export const VIDEO_MAX_DURATION = 120;

// Shoppable Post types
export const SHOPPABLE_PHOTO = 'shoppablePhoto';
export const SHOPPABLE_VIDEO = 'shoppableVideo';

// Shoppable Media types
export const IMAGE = 'image';
export const VIDEO = 'video';

export const SHOPPABLE_POSTS_MSA_FOLDER_PATH = 'https://m.media-amazon.com/images/G/01/zappos/zip/shoppableposts/';
export const MSA_IMAGE_DIMENSIONS = { width: 400, height: 400 };
export const MSA_SETTINGS_JPG_85 = '_FMjpg_QL85_';
export const getMSAImageDimension = (mediaExtension: string) => ({ height: 650, extension: mediaExtension, customSettings: MSA_SETTINGS_JPG_85 });

// Product selection tabs
export const MY_ORDERS_TAB = 'FROM_ORDERS_TAB';
export const MY_COLLECTIONS_TAB = 'FROM_COLLECTIONS_TAB';
export const RECENTLY_VIEWED_TAB = 'RECENTLY_VIEWED_TAB';

// Animations
export const HEADER_IMAGE_TO_RIGHT = 'HEADER_IMAGE_TO_RIGHT';
export const HEADER_IMAGE_TO_LEFT = 'HEADER_IMAGE_TO_LEFT';

// Title/Caption
export const TITLE_ID = 'postTitle';
export const TITLE_LENGTH = 30;
export const CAPTION_LENGTH = 300;
export const CAPTION_ROWS = 3;
export const CAPTION_COLUMNS = 100;
export const CAPTION_PLACEHOLDER = 'Type here to add a caption for this post.' + ' Your followers would love to hear about this look.';

export const MAX_ORDERS_TO_FETCH = 25;
export const CONTENT_FILE_EXTENSION_MAP = {
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'video/m4v': 'm4v',
  'video/mp4': 'mp4',
  'video/quicktime': 'mov'
};

// Video Status
// Todo: Create a enum for video statuses.
export const VIDEO_PLAY = 'VIDEO_PLAY';
export const VIDEO_PAUSED = 'VIDEO_PAUSED';
export const VIDEO_SEEK = 'VIDEO_SEEK';
export const VIDEO_PLAYER_CONTROLS = 'VIDEO_PLAYER_CONTROLS';
export const VIDEO_END = 'VIDEO_END';

// Video Timeout
export const MODAL_VIDEO_TIMEOUT = 750;

// Modal Props
export const MODAL_PROPS = {
  [ModalType.DELETE_POST]: {
    modalTitle: 'You are about to delete this shoppable post',
    modalText1: 'It will be permanently deleted.',
    modalText2: 'Are you sure?',
    primaryCTA: 'No, Just Hide It On Profile Page',
    destructiveCTA: 'Yes, Permanently Delete It'
  },
  [ModalType.DISCARD_POST]: {
    modalTitle: 'Discard this post?',
    modalText1: 'You will lose all of your edits.',
    modalText2: 'You have the option to save it as a draft and continue your edit later.',
    primaryCTA: 'Save as Draft',
    destructiveCTA: 'Discard and Go Back'
  }
};

export const NOTIFICATION_ATTRIBUTES = {
  [AlertState.DRAFT]: {
    colorBorderBg: AlertBG.BLUE,
    alertIcon: AlertIcon.BOX,
    alertTitle: 'Draft',
    upperText: 'The content edit has not been published.',
    cta: {
      ctaIcon: 'View.png',
      ctaText: 'Publish This Post'
    }
  },
  [AlertState.APPROVED]: {
    colorBorderBg: AlertBG.GREEN,
    alertIcon: AlertIcon.SUCCESS,
    alertTitle: 'Approved',
    upperText: 'Congrats! The content change is now approved!' /* TODO check later, applies to all banners */,
    closeIcon: true
  },
  [AlertState.SUBMIT]: {
    colorBorderBg: AlertBG.YELLOW,
    alertIcon: AlertIcon.QUESTION,
    alertTitle: 'Pending Review',
    upperText: 'The photo/video is under review.'
  },
  [AlertState.SUBMIT_PHOTO_ONLY]: {
    colorBorderBg: AlertBG.YELLOW,
    alertIcon: AlertIcon.QUESTION,
    alertTitle: 'Pending Review',
    upperText: 'The photo is under review.'
  },
  [AlertState.PENDING]: {
    colorBorderBg: AlertBG.YELLOW,
    alertIcon: AlertIcon.QUESTION,
    alertTitle: 'Pending Review',
    upperText: 'The photo/video is under review.',
    bottomText: 'Content Creation Date: Apr 22, 2022'
  },
  [AlertState.PENDING_PHOTO_ONLY]: {
    colorBorderBg: AlertBG.YELLOW,
    alertIcon: AlertIcon.QUESTION,
    alertTitle: 'Pending Review',
    upperText: 'The photo is under review.',
    bottomText: 'Content Creation Date: Apr 22, 2022'
  },
  [AlertState.DECLINED]: {
    colorBorderBg: AlertBG.RED,
    alertIcon: AlertIcon.DECLINED,
    alertTitle: 'Declined',
    upperText: 'The content change has been declined due to inappropriate content. Please change the photo/video and try again. '
  },
  [AlertState.NOT_ACTIVE]: {
    colorBorderBg: AlertBG.RED,
    alertIcon: AlertIcon.DECLINED,
    alertTitle: 'Not Active',
    upperText:
      'You need to have at least one piece of shoppable content or one collection to make your profile page active. ' +
      'After your profile is active, your followers will be able to find you on Zappos and you will be able to share your page on social media.'
  },
  [AlertState.HIDDEN]: {
    colorBorderBg: AlertBG.RED,
    alertIcon: AlertIcon.HIDE,
    alertTitle: 'Hidden',
    upperText: 'The post is previously appproved, publish it on your profile page so your followers can see it.',
    bottomText: 'Content Creation Date: Apr 22, 2022',
    cta: {
      ctaIcon: 'View.png',
      ctaText: 'Publish This On Profile'
    }
  },
  [AlertState.PREVIEW]: {
    colorBorderBg: AlertBG.BLUE,
    alertIcon: AlertIcon.LAYOUT,
    alertTitle: 'Preview',
    upperText: 'The preview shows how the content will be seen by your followers.',
    closeIcon: true
  },
  [AlertState.PRODUCT_REMOVED]: {
    colorBorderBg: AlertBG.BLUE_WHITE,
    alertIcon: AlertIcon.DISABLED,
    alertTitle: 'Product Removed',
    upperText: '2 products have been removed from this shoppable photo/video because it’s out of stock/discontinued.',
    bottomText: 'View Details',
    closeIcon: true
  }
};

export const VIDEO_UPLOAD_LOADING_PROMPTS = [
  'Keep calm and hold on. Almost there!',
  "You can't hurry awesomeness.",
  'Wake us up when September ends.',
  "Please don't go! We're almost done.",
  'Just keep swimming, just keep swimming...',
  'One Mississippi, two Mississippi...',
  'Do you need a coffee break? This might take a minute.',
  'This will be done before you know it.',
  "“Three Hours Later.” Just kidding! We're almost done.",
  'Uploading! (You can twerk, while we work.)',
  "Stick around, we're almost there!"
];
export const VIDEO_PROMPT_DURATION = 7000;

// Video Play event trigger interval
export const VIDEO_PLAY_EVENT_TRIGGER = 3000;
// Brand Shop The Look
export const BRAND_INFLUENCER_ID = 'AI0QVF7LW5Z1R';
export const SHOP_THE_LOOK_HEADING = 'Shop The Look';
export const MIN_STL_THRESHOLD = 3;
export const MAX_STL_THRESHOLD = 10;

export enum ShopTheLookSource {
  SEARCH = 'search',
  PDP = 'pdp'
}

export const PAGE_CONTENT_LIMIT = 12;
export const THRESHOLD_VALUE = 1;
export const ROOT_MARGIN_VALUE = '500px';
export const PLACEHOLDER_HASHTAG_STYLE_FEED = 'Search for hashtags';
export const HASHTAGS_MAX_SELECTION = 5;
export const STYLES_MAX_SELECTION = 3;
export const HASHTAGS_SUBHEADING = 'Hashtag';
export const STYLES_SUBHEADING = 'Style';

// Media upload
export const ALLOWED_VIDEO_DURATION = 'For videos, we allow minimum duration of 15 seconds up to 120 seconds (2 minutes).';
export const SUPPORTED_IMAGE_VIDEO = 'We only support square and portrait images and videos upto 9:16 aspect ratio.';
export const VIDEO_NOTE = 'Note: Videos could take some time to process. Please do not exit while your video file is uploading.';
